import React from 'react'

function ErrorListSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g id="Group_152" data-name="Group 152" transform="translate(-771 -812)">
        <g id="Ellipse_27" data-name="Ellipse 27" transform="translate(771 812)" fill="rgba(239,129,118,0.58)" stroke="#dc4535" strokeWidth="1">
          <circle cx="8" cy="8" r="8" stroke="none" />
          <circle cx="8" cy="8" r="7.5" fill="none" />
        </g>
        <line id="Line_67" data-name="Line 67" x2="9" transform="translate(774.5 820.5)" fill="none" stroke="#dc4535" strokeWidth="1" />
      </g>
    </svg>
  )
}

export default ErrorListSvg
