import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

function PrivateRoute({ component: Component, ...rest }) {
  const user = useSelector(st => st.user.data)

  return (
    <Route
      {...rest}
      render={props => (user
        ? <Component {...props} />
        : <Redirect to={rest.redirectTo ? rest.redirectTo : '/login'} />)}
    />
  )
}

export default PrivateRoute
