import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    flex: 1,
  },
  inner: {
    padding: '50px 20px 60px',
    maxWidth: 1340,

    margin: '0 auto',
    color: theme.palette.secondary.main,
    '& h1': {
      fontSize: 24,
      lineHeight: '32px',
      margin: '0 0 40px',
      fontFamily: 'FuturaStd-Bold',
      textTransform: 'uppercase',
    },
    '& p': {
      fontSize: 14,
      lineHeight: '20px',
      margin: 0,
    },
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
    },
    '& strong': {
      fontFamily: 'FuturaStd-Bold',
    },
    '& em': {
      fontStyle: 'normal',
      textDecoration: 'underline',
    },
  },
}))
function StaticPage({ title, children }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <h1>{title}</h1>
        {children}
      </div>
    </div>
  )
}

export default StaticPage
