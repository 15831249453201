import address from './address'
import area from './area'
import common from './common'
import discount from './discount'
import order from './order'
import product from './product'
import user from './user'
import banner from './banner'

export default {
  address,
  area,
  common,
  discount,
  order,
  product,
  user,
  banner,
}
