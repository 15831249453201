import axios from './axios'

const userApi = {
  login: (email, password) => axios.post('/api/login', { email, password }),
  logout: () => axios.get('/api/logout'),
  forgotPassword: email => axios.post('/api/password/email', { email }),
  resetPassword: params => axios.post('/api/password/reset', params),
  checkEmail: email => axios.get(`/api/email/${email}`),
  create: params => axios.post('/api/user', params),
  update: params => axios.put('/api/user', params),
  getInfo: params => axios.get('/api/user', params),
}

export default userApi
