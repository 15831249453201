import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import Drawer from '../Drawer'
import Dialog from '../Dialog'
import Button from '../Buttons/Button'
import ProductBundleList from '../Products/ProductBundleList'
import ProductCard from '../Products/ProductCard'
import useIsDesktop from '../../utils/reactHooks/useIsDesktop'
import { PRODUCT_TYPE } from '../../constant'

const useStyles = makeStyles(theme => ({
  root: {},
  cartDrawer: {},
  title: {
    margin: 0,
    background: '#EFEFEF',
    height: 67,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    letterSpacing: 2,
    borderBottom: '1px solid #CCCCCC',
    fontSize: 14,
  },
  updateButton: {
    position: 'sticky',
    bottom: 0,
    marginTop: 'auto',
    height: 60,
    flex: '0 0 60px',
  },
}))

function BundlePicker({
  open, defaultValue, onClose, product, onPickBundle = () => {},
}) {
  const { t, i18n } = useTranslation()

  const [current, setCurrent] = useState()
  const [pickedData, setPickedData] = useState({})
  const [showProduct, setShowProduct] = useState(false)
  const classes = useStyles()
  const isDesktop = useIsDesktop()

  useEffect(() => {
    if (open) {
      document.getElementById('root').classList.add('is-blur')
    } else {
      document.getElementById('root').classList.remove('is-blur')
    }
    return () => {
      document.getElementById('root').classList.remove('is-blur')
    }
  }, [open])

  useEffect(() => {
    if (open && defaultValue) {
      if (defaultValue) {
        setPickedData(defaultValue)
      }
    } else {
      setPickedData({})
    }
  }, [open, defaultValue])

  const handleView = (ev, item) => {
    ev.preventDefault()
    setCurrent(item)
    setShowProduct(true)
  }

  const handlePlus = (ev, item) => {
    ev.preventDefault()
    if (item.quantity && (pickedData?.[item.code]?.picked || 0) < item.pivot.quantityMax) {
      setPickedData(st => ({
        ...st,
        [item.code]: ({
          picked: st[item.code]?.picked ? st[item.code].picked + 1 : 1,
          data: item,
        }),
      }))
    }
  }

  const handleMinus = (ev, item) => {
    ev.preventDefault()
    setPickedData(st => ({
      ...st,
      [item.code]: ({
        picked: st[item.code].picked - 1,
        data: item,
      }),
    }))
  }

  const handleUpdate = () => {
    onPickBundle(pickedData)
  }
  const { language } = i18n

  const total = Object.values(pickedData).reduce((acc, cur) => acc + cur.picked, 0)
  const disabled = total !== product?.comboSize

  return (
    <>
      <Drawer
        className={classes.cartDrawer}
        anchor="right"
        title={t('Customize item')}
        width={isDesktop ? 430 : '100%'}
        open={open}
        onClose={onClose}
      >
        <div>
          <h3 className={classes.title}>
            {t('Select any {num} below', { num: product?.comboSize || 0 })}
          </h3>
          {product?.comboItems.map(item => (
            <ProductBundleList
              key={item.id}
              product={item}
              type={PRODUCT_TYPE.BUNDLE_ITEM}
              picked={pickedData[item.code]?.picked}
              onView={ev => handleView(ev, item)}
              onPlus={ev => handlePlus(ev, item)}
              onMinus={ev => handleMinus(ev, item)}
              disabled={total === product?.comboSize}
            />
          ))}
        </div>
        <Button
          className={classes.updateButton}
          variant="contained"
          color="primary"
          disableElevation
          disabled={disabled}
          onClick={handleUpdate}
          fullWidth
        >
          {t('Update')}
        </Button>
      </Drawer>
      <Dialog
        className={classes.dialog}
        open={showProduct}
        maxWidth={754}
        fullWidth
        onClose={() => setShowProduct(false)}
      >
        <div>
          {!!current && (
            <ProductCard
              {...current}
              name={(language === 'tw' && current.nameTw) || current.name}
              desc={
                (language === 'tw' && current.descriptionTw)
                || current.description
              }
              image={current.imageFullurl}
              areaActive
              price={current.pivot.addPrice}
              type={PRODUCT_TYPE.BUNDLE_ITEM}
              disabled
              onlyView
            />
          )}
        </div>
      </Dialog>
    </>
  )
}

export default BundlePicker
