import bannerTypes from '../actionTypes/bannerTypes'

const initialState = {
  data: [],
  isFetching: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
  case bannerTypes.FETCH_REQUEST: {
    return {
      ...state,
      isFetching: true,
    }
  }
  case bannerTypes.FETCH_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      data: action.banners,
    }
  }
  case bannerTypes.FETCH_FAILURE: {
    return {
      ...state,
      isFetching: false,
      error: action.error,
    }
  }
  default:
    return state
  }
}

export { initialState as areaInitState }
