import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import { Link, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import LogoSvg from '../../../svg/LogoSvg'
import CircleLogoSvg from '../../../svg/CircleLogoSvg'

const useStyles = makeStyles(theme => ({
  root: props => ({
    padding: '33px 20px 60px',
    flex: 1,
    color: theme.palette.secondary.main,
    background: 'white',
    '& h1': {
      textTransform: 'uppercase',
      color: theme.palette[props.headerType].main,
      fontSize: 24,
      lineHeight: '32px',
      fontFamily: 'FuturaStd-Bold',
      margin: 0,
      marginBottom: 50,
      paddingBottom: 33,
      textAlign: 'center',
      borderBottom: '1px solid #ccc',
    },
    [theme.breakpoints.up('sm')]: {
      background: 'unset',
    },
  }),
  showBack: {
    '& h1': {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  },
  container: {
    maxWidth: 830,
    margin: '0 auto',
    padding: '30px 0 30px',
    minHeight: 350,
    [theme.breakpoints.up('sm')]: {
      background: 'white',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.16)',
      padding: '30px 65px 80px',
      width: '90%',
    },
  },
  bottomComp: {
    marginTop: 30,
    [theme.breakpoints.up('sm')]: {
      marginTop: 60,
    },
  },
  topNav: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: 70,
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #ccc',
    zIndex: 1000,
    transitionDuration: '0.3s',
    '&.is-hide': {
      opacity: 0,
      transform: 'translateY(-20px)',
    },
    '& a': { display: 'flex' },
    '& strong': {
      color: theme.palette.secondary.main,
      fontSize: 24,
    },
    [theme.breakpoints.up('sm')]: {
      height: 100,
      '& strong': { display: 'none' },
    },
    [theme.breakpoints.down('xs')]: {
      '& a': { display: 'none' },
    },
  },
  btn: {
    position: 'absolute',
    right: 0,
    height: '100%',
    top: 0,
    [theme.breakpoints.up('sm')]: {
      width: 100,
    },
  },
  logo: {
    textAlign: 'center',
    marginBottom: 30,
  },
  '@global .header': ({ showBack }) => ({
    display: showBack ? 'none' : 'flex',
  }),
}))
function CardPage({
  title, children, bottomComp, showBack, showLogo = false, headerType = 'secondary',
}) {
  const classes = useStyles({ headerType, showBack })
  const history = useHistory()
  return (
    <>
      {
        showBack && (
          <div id="nav-header" className={classes.topNav}>
            <Link to="/">
              <LogoSvg />
            </Link>
            <strong>{title}</strong>
            <Button className={classes.btn} onClick={() => history.goBack()}>
              <ArrowBackIosRoundedIcon style={{ fontSize: 30 }} />
            </Button>
          </div>
        )
      }
      <div className={clsx(classes.root, { [classes.showBack]: showBack })}>
        <div className={classes.wrap}>
          <div className={classes.container}>
            { showLogo && <div className={classes.logo}><CircleLogoSvg /></div> }
            <h1>{title}</h1>
            {children}
          </div>
          {
            !!bottomComp && (
              <div className={classes.bottomComp}>
                {bottomComp}
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

export default CardPage
