/* eslint-disable max-len */
import React from 'react'
import { useTranslation } from 'react-i18next'
import StaticPage from '../../../components/Pages/StaticPage'

function Terms() {
  const { t } = useTranslation()

  return (
    <StaticPage title={t('Terms of use')}>
      <p>
        <strong>1. Acceptance of Terms</strong>
        <br />
        This Terms of Use set forth below (the “Agreement”) governs your use of this site (the “Site”) of Boiling Point Group, Inc., a California Corporation (“Boiling Point”), and the products and services available on the Site (the “Services”).
        <br />
        <br />
        This Site is for your personal use. By visiting or using the Site and/or the Services, you are indicating your acceptance to be bound without modification by each and every term of the Agreement. Please read the Agreement carefully. If you do not accept all the terms and conditions of the Agreement, you may not use the Site and/or the Services.
        <br />
        <br />
        Boiling Point may revise the Agreement from time to time without notice to you. You should visit this page periodically to review the most current version of the Agreement, because its terms and conditions are binding on you. Boiling Point may revise the Agreement from time to time without notice to you. Your continued use of the Site and/or the Services shall constitute your consent to such changes.
        <br />
        <br />
        <strong>2. Use of the Site</strong>
        <br />
        Except as expressly stated herein, you acknowledge that you have no right, title or interest in or to the information, data, text, photographs, images, graphics, messages, tags or any other materials (collectively, the “Contents”) contained within the Site on any legal basis.
        <br />
        <br />
        Boiling Point grants you the limited right to use the Site and/or the Services of Boiling Point for your personal, non-commercial use only pursuant to the terms of the Agreement. A written consent by Boiling Point, at its sole and absolute discretion,&nbsp;
        <strong><em>MUST</em></strong>
        &nbsp;be obtained prior to using the Site and/or the Services for a commercial purpose. You agree not to make any unauthorized commercial use of this Site and/or the Services. This limited license terminates automatically, without notice to you, if you breach any terms or conditions of the Agreement. Upon any termination of this limited license, you agree to immediately destroy any electronic or printed copies of the Contents and cease using the Site and/or the Services.
        <br />
        <br />
        <strong>3. General Use Restriction</strong>
        <br />
        You are prohibited from:
        <br />
        (a) Using the Site and/or the Services to upload, post, transmit, store or destroy any of the Contents (i) in violation of any applicable law or regulation, (ii) in a manner that will infringe the copyright, trademark, trade secret or other intellectual property rights of others or violate the privacy, publicity or other personal rights of others, or (iii) that is defamatory, libelous, obscene, threatening, abusive or hateful.
        <br />
        <br />
        (b) Taking any action to circumvent or attempt to circumvent the security and access control provisions of the Site and/or the Services, including, without limitation the following activities:
        <br />
        <br />
        (1) Testing the security measures on the Site and/or attempting to identify system vulnerabilities without proper authorization;
        <br />
        (2) Forging headers or otherwise manipulating identifiers in order to disguise the origin of any of the Contents transmitted through the Site and/or the Services;
        <br />
        (3) Impersonating any person or entity, or falsely stating or otherwise misrepresenting your affiliation with a person or entity; or
        <br />
        (4) Attempting to interfere with service to any user, host or network, including, without limitation, via means of submitting software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment.
        <br />
        <br />
        Boiling Point may investigate occurrences which may involve such violations and may respond as appropriate to enforce the Agreement and compliance with any applicable law or regulation. Boiling Point may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations.
        <br />
        <br />
        Boiling Point reserves the right to terminate our relationship with you under the terms herein and prohibit your access to the Site and/or the Services or to edit, remove or close any submission for any reason, including, without limitation, any action that Boiling Point, in its sole and absolute discretion, determines to be inappropriate or disruptive to the Site, the Services or to any user.
        <br />
        <br />
        <strong>4. Specific Prohibitions</strong>
        <br />
        The Site and/or the Services of Boiling Point may be used only for lawful purposes. Boiling Point specifically prohibits without limiting the generality of foregoing, and you agree not to do any of the following:
        <br />
        <br />
        (1) Post any incomplete, false or inaccurate information;
        <br />
        (2) Send unsolicited mail or e-mail, make unsolicited phone calls or send unsolicited faxes regarding promotions and/or advertising of products or services to a user of the Site;
        <br />
        (3) Delete or revise any material posted by Boiling Point or any other person or entity without express written authorization of Boiling Point;
        <br />
        (4) Attempt to decipher, decompile, disassemble or reverse engineer any of the software comprising or in any way making up a part of the Site;
        <br />
        (5) Aggregate, copy or duplicate in any manner any of the Contents or information available from the Site for any purpose other than expressly permitted herein; or
        <br />
        (6) Retrieve or index any portion of the Site, including, without limitation, via means of any robot, spider or other program or device.
        <br />
        <br />
        <strong>5. Contents – Submission, Risk, Limited Liability</strong>
        <br />
        We appreciate your submittals of Contents expressing your feedbacks and opinions. We do not claim ownership of the Contents submitted by you or any other third parties. However, by posting any of the Contents to any area of the Site by electronic mail, postings on this Site or otherwise, including any reviews, questions, comments, ideas, suggestions or the like contained in any submissions, you automatically grant, and you represent and warrant that you have the right to grant, to Boiling Point and its designees an irrevocable, perpetual, royalty free, non-exclusive, fully sub-licensable, worldwide license to use, distribute, reproduce, modify, publish, perform and display such Contents and to incorporate such Contents into other works in any format or medium now known or later developed. Furthermore, you grant Boiling Point the right to pursue at law any person or entity that violates yours or Boiling Point’s rights in the submission by a breach of this Agreement.
        <br />
        <br />
        You understand that all the Contents, whether publicly posted or privately transmitted, are the sole and express responsibility of the person from whom such Contents originated. Your access to the Site and the Services is undertaken at your own risk and Boiling Point shall not be liable for any use or disclosure of any of the Contents.
        <br />
        <br />
        Boiling Point has no obligation to post the Contents submitted by you and Boiling Point reserves the right, in its sole and absolute discretion, to determine which Contents submitted by you are published on the Site. Furthermore, Boiling Point has no obligation to screen communications or information in advance and is not responsible for screening or monitoring the Contents posted by its users. However, Boiling Point may review and delete any of the Contents that, in its sole and absolute judgment. Boiling Point reserves the right to expel user and prevent their further access to the Site and the right to remove any of the Contents.
        <br />
        <br />
        <strong>6. Privacy Policy</strong>
        <br />
        The Terms of Use includes Boiling Point’s Privacy Policy, which is incorporated herein by reference and made a part hereof.
        <br />
        <br />
        Information you submit shall be deemed non-confidential and non-proprietary. Boiling Point agrees to use your information in accordance with Boiling Point’s Privacy Policy applicable to personally identifiable information.
        <br />
        <br />
        <strong>7. Disclaimer</strong>
        <br />
        You acknowledge and agree that:
        <br />
        <br />
        THE MATERIAL IN THIS SITE COULD INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. FURTHERMORE, THE MATERIALS SHOWN IN THIS SITE MAY NOT BE EXACTLY AS SHOWN. BOILING POINT MAY MAKE CHANGES OR IMPROVEMENTS AT ANY TIME. THE MATERIALS IN THIS SITE ARE PROVIDED &#34;AS IS&#34; AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESSED OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, BOILING POINT DISCLAIMS ALL WARRANTIES OR MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. BOILING POINT DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE MATERIAL WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, THAT ANY DATA OR INFORMATION YOU SUBMIT WOULD BE SECURE OR FREE FROM UNAUTHORIZED ACCESS OR ACQUISITION, OR THAT THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE SECURE, FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. BOILING POINT DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OF OR THE RESULT OF THE USE OF THE MATERIAL IN THIS SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, SECURITY, OR OTHERWISE. YOU (AND NOT BOILING POINT) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION. THE ABOVE EXCLUSION MAY NOT APPLY TO YOU, TO THE EXTENT THAT APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES.
        <br />
        <br />
        <strong>8. Limitation of Liability</strong>
        <br />
        NEITHER BOILING POINT NOR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, AFFILIATES, LICENSORS, OR THIRD PARTY PROVIDERS SHALL BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATED TO THE USE OR ATTEMPTED USE OF THE SITE OR THE SERVICES, INCLUDING BUT NOT LIMITED TO, THE RESULTS OF YOUR USE OF THE SITE AND/OR THE SERVICES, OR ANY EXTERNAL WEBSITES LINKED TO THIS SITE, OR THE CONTENTS ON THE SITE, HOWEVER ARISING, WHETHER IN BREACH, TORT, STRICT LIABILITY OR OTHERWISE EVEN IF BOILING POINT WAS INFORMED OF THE POSSIBILITY OF SUCH DAMAGES.
        <br />
        <br />
        IF, DESPITE THE LIMITATION SET FORTH IN THIS PARAGRAPH 8, BOILING POINT, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, AFFILIATES, LICENSORS, OR THIRD PARTY PROVIDERS ARE FOUND LIABLE FOR ANY LOSS OR DAMAGE WHICH ARISES OUT OF, DIRECTLY OR INDIRECTLY, FROM ANY OF OCCURENCES DESCRIBED IN THIS PARAGRAPH 8, THEN BOILING POINT, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, AFFILIATES, LICENSOR OR THIRD PARTY PROVIDERS’ LIABILITIES WILL IN NO EVENT EXCEED, IN THE AGGREGATE, SEVENTY-FIVE DOLLARS ($75.00 USD).
        <br />
        <br />
        <strong>9. Indemnification</strong>
        <br />
        You agree to defend, indemnify and hold harmless Boiling Point, its affiliates, and their respective officers, directors, employees and agents, from and against any and all liabilities, claims, costs and expenses, including without limitation reasonable legal and accounting fees, that arise, directly or indirectly, out of or in connection with your use of the Site and/or the Services or breach of the terms herein.
        <br />
        <br />
        <strong>10. Trademarks and Copyrights</strong>
        <br />
        Boiling Point and bpgroupusa.com are trademarks or registered trademarks of Boiling Point Group, Inc. The trademarks, service marks, brands, names, logos and designs (&#34;Trademarks&#34;) of Boiling Point or others used on this Site and in the Services are the property of Boiling Point or their respective owners. You may not display or use any Trademarks without the express prior written permission of Boiling Point or their respective owners. If you believe that your work has been copied to the Site and is accessible in a way that constitutes copyright infringement, or your intellectual property rights have been otherwise violated, please notify us by providing the following information:
        <br />
        <br />
        (1) The physical or electronic signature of either the copyright owner or of a person authorized to act on the owner&#39;s behalf;
        <br />
        (2) A description of the copyrighted work you claim has been infringed;
        <br />
        (3) Identification of the URL or other specific location on the Site where the material or activity you claim to be infringing is located or is occurring;
        <br />
        (4) Your name, address, telephone number and e-mail address;
        <br />
        (5) A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, any agent of the copyright owner, or the law; and
        <br />
        (6) A statement by you, made under penalty of perjury, that the information you have provided in your notice is accurate and that you are either the copyright owner or are authorized to act on behalf of the copyright owner.
        <br />
        <br />
        <br />
        Notices must meet the then-current statutory requirements imposed by the Digital Millennium Copyright Act of 1998; see&nbsp;
        <a href="http://www.loc.gov/copyright/" target="_blank" rel="noopener noreferrer">http://www.loc.gov/copyright/</a>
        &nbsp;for details.
        <br />
        <br />
        Notices should be sent to:
        <br />
        <br />
        Boiling Point Group, Inc.
        14278 Valley Blvd. #A
        La Puente, CA 91746
        <br />
        <br />
        Or by email: cs@bpgroupusa.com
        <br />
        <br />
        <strong>11. General</strong>
        <br />
        <em>Entire Agreement.</em>
        &nbsp;The Agreement constitutes the entire agreement between you and Boiling Point and supersedes all prior or contemporaneous understanding, discussions or agreements, written or oral, between you and Boiling Point with respect to the subject matter contained herein.
        <br />
        <br />
        <em>Waiver and Severability of Terms.</em>
        &nbsp;The failure of Boiling Point to exercise or enforce any right or provision of the Agreement shall not constitute a waiver of such right or provision unless acknowledged and consented to in writing by Boiling Point. If any provision of the Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should construe such provision to give effect to the parties&#39; intentions as reflected in the provision and the other provisions of the Agreement remain in full force and effect.
        <br />
        <br />
        <em>International Use.</em>
        &nbsp;Boiling Point makes no representation that the Site and/or the Services may be lawfully viewed or accessed outside of the United States. If you access the Site or the Services from outside the United States, you do so at your own risk and are entirely responsible for compliance with the applicable laws of your jurisdiction.
        <br />
        <br />
        <em>Choice of Law and Forum.</em>
        &nbsp;The Agreement and the relationship between you and Boiling Point shall be governed by the laws of the State of California without respect to its conflict of law provisions. Jurisdiction for any claims arising under the Agreement or in connection with the Site and/or the Services shall lie exclusively with the courts located within Los Angeles County, California.
        <br />
        <br />
        <em>Statute of Limitations.</em>
        &nbsp;You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Site and/or the Services or the Agreement must be filed within one (1) year after such claim or cause of action arose or be forever barred.
        <br />
        <br />
        <em>Headings.</em>
        &nbsp;The section headings in the Agreement are for convenience only and have no legal or contractual effect.
        <br />
        <br />
        <em>No Agency, Joint Venture or Partnership.</em>
        &nbsp;Nothing in the Agreement shall be deemed to constitute either you or Boiling Point as agent or representative of the other party, or both parties as partners or joint venturers for any purpose.
        <br />
        <br />
        By your use of the Site you acknowledge that you have read and understood the terms of the Agreement, and that the Agreement has the same force and effect as a signed agreement.
        <br />
        <br />
        If you have questions or suggestions, please write to: cs@bpgroupusa.com
      </p>
    </StaticPage>
  )
}

export default Terms
