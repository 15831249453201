import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCommon } from '../redux/action/commonAction'
import { fetchProducts, checkProducts } from '../redux/action/productAction'
import {
  addToCart as handleAddToCart,
  duplicateCart as handleDuplicateCart,
  removeCart as handleRemoveCart,
  removeAllCart as handleRemoveAllCart,
  checkCart as handleCheckCart,
} from '../redux/action/orderAction'

export default function useCart() {
  const { products, isFetching } = useSelector(st => st.product)
  const dp = useDispatch()

  const checkCart = useCallback(() => {
    dp(handleCheckCart())
  }, [dp])

  const fetchAndCheck = useCallback(async () => {
    await Promise.all([
      dp(fetchProducts()),
      dp(fetchCommon()),
    ])
    dp(checkProducts())
    checkCart()
  }, [dp, checkCart])

  const addToCart = useCallback((items, name, isOverride) => {
    dp(handleAddToCart(items, name, isOverride))
  }, [dp])

  const duplicateCart = useCallback((code, name) => {
    dp(handleDuplicateCart(code, name))
  }, [dp])

  const removeCart = useCallback((code, name) => {
    dp(handleRemoveCart(code, name))
  }, [dp])

  const removeAllCart = useCallback(name => {
    dp(handleRemoveAllCart(name))
  }, [dp])

  return {
    addToCart,
    duplicateCart,
    checkCart,
    removeCart,
    removeAllCart,
    fetchAndCheck,
    availableProducts: products,
    isFetching,
  }
}
