export const NODE_MODE = process.env.NODE_ENV
export const PRODUCT_TYPE = {
  COMBO_BROTH: 'combo broth',
  ADDONS: 'add-ons',
  FOOD_MARKET: 'food market',
  BUNDLE: 'bundle',
  BUNDLE_ITEM: 'bundleItem',
  MEAT: 'meat',
  BROTH: 'broth',
}

export const SYMBOL_REGEX = /^(?=.*[0-9!@#$%^&*]).+$/
export const LOWER_REGET = /^(?=.*[a-z]).+$/
export const UPPER_REGEX = /^(?=.*[A-Z]).+$/
export const PHONE_NO_REGEX = /^([2-9]\d{2}-\d{3}-\d{4})+$/
