import commonTypes from '../actionTypes/commonTypes'

const initialState = {
  wechatFullurl: '',
  includes: [],
  meats: [],
  taxes: [],
  home: {},
  about: {},
  promotions: [],
  isFetching: false,
  error: null,
  announcement: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
  case commonTypes.FETCH_COMMON_REQUEST:
  case commonTypes.FETCH_CONFIG_REQUEST: {
    return {
      ...state,
      isFetching: true,
    }
  }
  case commonTypes.FETCH_COMMON_FAILURE:
  case commonTypes.FETCH_CONFIG_FAILURE: {
    return {
      ...state,
      isFetching: false,
      error: action.error,
    }
  }
  case commonTypes.FETCH_COMMON_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      ...action.data,
    }
  }
  case commonTypes.FETCH_CONFIG_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      ...action.data,
    }
  }
  default:
    return state
  }
}

export { initialState as commonInitState }
