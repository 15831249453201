import productTypes from '../actionTypes/productTypes'

const initialState = {
  data: [],
  isFetching: false,
  error: null,
  products: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
  case productTypes.FETCH_REQUEST: {
    return {
      ...state,
      isFetching: true,
    }
  }
  case productTypes.FETCH_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      data: action.products,
    }
  }
  case productTypes.FETCH_FAILURE: {
    return {
      ...state,
      isFetching: false,
      error: action.error,
    }
  }
  case productTypes.CHECK_PRODUCTS: {
    const products = state.data
      .filter(
        d =>
          d.type !== 'bundle'
            || (d.type === 'bundle' && d.comboItems?.length > 0),
      )
      .filter(d => d.variants.length)
      .map(d => {
        const priceVariants = d.variants.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.areaId]: cur,
          }),
          {},
        )

        const areaId = action.areaId || Object.values(priceVariants)[0]?.areaId || null

        const fixedBundleCount = d.type === 'bundle'
          ? d.comboItems
            .filter(
              item =>
                !!item.pivot
                      && item.pivot.quantityMax === item.pivot.quantityMin,
            )
            .reduce((acc, cur) => acc + cur.pivot.quantityMin || 0, 0)
          : 0

        if (areaId) {
          const released = !priceVariants[areaId]?.releaseAt
              || new Date().getTime()
                >= new Date(priceVariants[areaId]?.releaseAt).getTime()
          const expired = priceVariants[areaId]?.expiredAt
            ? new Date().getTime()
                >= new Date(priceVariants[areaId]?.expiredAt).getTime()
            : false

          return {
            ...d,
            isFixedBundle:
                d.type === 'bundle'
                && fixedBundleCount === d.comboSize,
            price: priceVariants[areaId]?.price || 0,
            specialPrice: priceVariants[areaId]?.specialPrice || 0,
            priceVariants,
            areaActive: priceVariants[areaId]?.isActive || false,
            taxable: priceVariants[areaId]?.taxable || false,
            releaseAt: priceVariants[areaId]?.releaseAt || null,
            restockAt: priceVariants[areaId]?.restockAt || null,
            expiredAt: priceVariants[areaId]?.expiredAt || null,
            released,
            expired,
            available:
                (released && !expired)
                || (released
                  && expired
                  && !!priceVariants[areaId]?.releaseAt
                  && new Date(priceVariants[areaId].releaseAt).getTime()
                    >= new Date(priceVariants[areaId]?.expiredAt).getTime()),
          }
        }
        return {
          ...d,
          isFixedBundle:
            d.type === 'bundle'
            && fixedBundleCount === d.comboSize,
          price: d.variants[0]?.price || 0,
          specialPrice: d.variants[0]?.specialPrice || 0,
          priceVariants,
          areaActive: d.variants[0]?.isActive ?? true,
          taxable: false,
          released: true,
          available: true,
          expired: false,
        }
      })

    return {
      ...state,
      products,
    }
  }
  default:
    return state
  }
}

export { initialState as productInitState }
