import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core'
import Input from '../../../../components/Inputs/Input'
import Button from '../../../../components/Buttons/Button'
import TextButton from '../../../../components/Buttons/TextButton'
import Loading from '../../../../components/Loading'
import apis from '../../../../apis'
import Dialog from '../../../../components/Dialog'
import { setUser } from '../../../../redux/action/userAction'
import '../../../Account/Account.scss'

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: '0 20px',
    maxWidth: 542,
    margin: '0 auto',
    '& .account-bottom-btn': {
      margin: '0 auto',
      marginTop: 40,
    },
    '& p': {
      textAlign: 'center',
    },
  },
  error: {
    color: theme.palette.error.main,
  },
}))

function ResetContent({
  setLoading, setPage,
}) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [data, setData] = useState({})
  const [error, setError] = useState(null)

  const handleChange = ev => {
    setData(st => ({
      ...st,
      [ev.target.name]: ev.target.value,
    }))
    setError(null)
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await apis.user.forgotPassword(data.email)
      setPage('success')
    } catch (err) {
      if (err.response) {
        setError(err.response.data.error || err.response.data.message)
      }
    }
    setLoading(false)
  }

  return (
    <div className={classes.dialog}>
      <p>
        {t('We will send you an email to reset your password')}
        <br />
        <span className={classes.error}>{!!error && error}</span>
      </p>
      <div className="account-inputs account-container">
        <Input
          label={t('Email')}
          variant="filled"
          name="email"
          error={!!error}
          onChange={handleChange}
        />
        <Button
          className="account-bottom-btn"
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleSubmit}
          disabled={!data.email}
        >
          {t('Submit')}
        </Button>
        <TextButton style={{ marginTop: 20 }} onClick={() => setPage('login')}>{t('Cancel')}</TextButton>
      </div>
    </div>
  )
}

function LoginContent({
  setOpen, setLoading, onSuccess, setPage,
}) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [data, setData] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(null)
  const dp = useDispatch()

  const handleChange = ev => {
    setData(st => ({
      ...st,
      [ev.target.name]: ev.target.value,
    }))
    setError(null)
  }

  const handleSignIn = async () => {
    setLoading(true)
    try {
      const res = await apis.user.login(data.email, data.password)
      localStorage.setItem('bpmarket_token', res.data.token)
      onSuccess(res.data.user)
      dp(setUser(res.data.user))
      setOpen(false)
    } catch (err) {
      if (err.response && err.response.data?.code === 401) {
        setError(err.response.data.errors)
      } else {
        setError(t('Internal server error'))
      }
    }
    setLoading(false)
  }
  return (
    <div className={classes.dialog}>
      {!!error && (
        <p className={classes.error}>
          {`${t('Please adjust the following')}:`}
          <br />
          {error.message}
        </p>
      )}
      <div className="account-inputs account-container">
        <Input
          label={t('Email')}
          variant="filled"
          name="email"
          error={error?.type === 'email'}
          onChange={handleChange}
        />
        <Input
          label={t('Password')}
          variant="filled"
          name="password"
          type={showPassword ? 'text' : 'password'}
          error={error?.type === 'password'}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(s => !s)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextButton style={{ marginTop: 20 }} onClick={() => setPage('reset')}>{t('Forgot your password')}</TextButton>
        <Button
          className="account-bottom-btn"
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleSignIn}
          disabled={!data.email || !data.password}
        >
          {t('Sign in')}
        </Button>
      </div>
    </div>
  )
}

function Login({ open, setOpen, onSuccess }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState('login')

  const handleClose = () => {
    setOpen(false)
  }

  let title = 'Log in'
  if (page === 'reset') title = 'Reset your Password'
  else if (page === 'success') title = 'Success'
  return (
    <Dialog
      open={open}
      maxWidth={page === 'success' ? 588 : 754}
      fullWidth
      title={title}
      onClose={handleClose}
    >
      {
        page === 'login' && (
          <LoginContent
            classes={classes}
            setOpen={setOpen}
            setLoading={setLoading}
            onSuccess={onSuccess}
            setPage={setPage}
          />
        )
      }
      {
        page === 'reset' && (
          <ResetContent
            classes={classes}
            setLoading={setLoading}
            setPage={setPage}
          />
        )
      }
      {
        page === 'success' && (
          <div className={classes.dialog}>
            <p>We will send you an email to reset your password.</p>
          </div>
        )
      }
      { loading && <Loading />}
    </Dialog>
  )
}

export default Login
