import areaTypes from '../actionTypes/areaTypes'

const initialState = {
  data: [],
  isFetching: false,
  error: null,
  area: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
  case areaTypes.SELECT_AREA: {
    return {
      ...state,
      area: action.area,
    }
  }
  case areaTypes.FETCH_REQUEST: {
    return {
      ...state,
      isFetching: true,
    }
  }
  case areaTypes.FETCH_SUCCESS: {
    const currentArea = state.area ? action.areas.find(area => area.id === state.area.id) : null
    const area = currentArea ? {
      id: currentArea.id,
      code: currentArea.code,
      name: currentArea.name,
      nameTw: currentArea.nameTw,
      minSubtotal: currentArea.minSubtotal,
      shippings: currentArea.shippings,
      warehouse: currentArea.warehouse,
      paypal: currentArea.paypal,
    } : null
    return {
      ...state,
      isFetching: false,
      data: action.areas,
      area,
    }
  }
  case areaTypes.FETCH_FAILURE: {
    return {
      ...state,
      isFetching: false,
      error: action.error,
    }
  }
  default:
    return state
  }
}

export { initialState as areaInitState }
