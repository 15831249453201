import React from 'react'

function FilterIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.672" height="24" viewBox="0 0 18.672 24" {...props}>
      <path id="Icon_metro-spoon-fork" data-name="Icon metro-spoon-fork" d="M7.684,1.928c-2.292,0-4.149,2.351-4.149,5.25,0,2.483,1.362,4.562,3.192,5.109L6.04,24.431a1.354,1.354,0,0,0,1.3,1.5H8.03a1.354,1.354,0,0,0,1.3-1.5L8.641,12.287c1.83-.547,3.192-2.627,3.192-5.109C11.833,4.279,9.976,1.928,7.684,1.928Zm13.946,0-1.153,7.5h-.864l-.576-7.5h-.576l-.576,7.5H17.02l-1.153-7.5h-.576v9.75a.723.723,0,0,0,.692.75h1.8l-.679,12a1.354,1.354,0,0,0,1.3,1.5h.692a1.354,1.354,0,0,0,1.3-1.5l-.679-12h1.8a.723.723,0,0,0,.692-.75V1.928Z" transform="translate(-3.535 -1.928)" fill="#533a29" />
    </svg>

  )
}

export default FilterIcon
