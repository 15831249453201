import React from 'react'

function CartSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.775" height="30.999" viewBox="0 0 22.775 30.999" {...props}>
      <path id="Path_21" data-name="Path 21" d="M89.352,9.644l-3.431,0V4.878a4.879,4.879,0,0,0-9.757,0V9.642H72.774a.534.534,0,0,0-.507.453c-.018.177-2.065,19.164-2.088,19.4a.532.532,0,0,0,.536.505H91.42a.535.535,0,0,0,.534-.535c-.022-.205-2.067-19.192-2.088-19.384A.53.53,0,0,0,89.352,9.644ZM77.231,4.878a3.811,3.811,0,0,1,7.622,0V9.642H77.231ZM71.312,28.93l.04-.37c.295-2.757,1.121-10.411,1.721-15.986l.2-1.862h2.889v2.073a.534.534,0,0,0,1.067,0V10.711h7.622v2.073a.534.534,0,0,0,1.068,0V10.711H88.86L90.823,28.93H71.312Z" transform="translate(-69.679 0.5)" fill="currentColor" stroke="currentColor" strokeWidth="1" />
    </svg>

  )
}

export default CartSvg
