import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import DiscountDialog from '../Dialog/DiscountDialog'
import { setUser } from '../../redux/action/userAction'
import apis from '../../apis'

function LayoutState() {
  const [checked, setChecked] = useState(false)
  const dp = useDispatch()

  useEffect(() => {
    const load = async () => {
      if (localStorage.getItem('bpmarket_token')) {
        try {
          const res = await apis.user.getInfo()
          if (res && res.data.user) {
            dp(setUser(res.data.user))
          } else {
            localStorage.removeItem('bpmarket_token')
            dp(setUser(null))
          }
        } catch (err) {
          localStorage.removeItem('bpmarket_token')
          dp(setUser(null))
        }
      }
      setChecked(true)
    }

    load()
  }, [dp])

  return (
    <>
      {checked && <DiscountDialog />}
    </>
  )
}

export default LayoutState
