import React from 'react'

function DeliverySvg(props) {
  return (
    <svg id="Delivery_City" data-name="Delivery City" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" {...props}>
      <circle id="Ellipse_15" data-name="Ellipse 15" cx="22" cy="22" r="22" fill="#333" />
      <g id="Group_79" data-name="Group 79" transform="translate(9.5 7)">
        <path id="Path_35" data-name="Path 35" d="M46.087,31.667H42.155l-1.562,3.125h7.056Z" transform="translate(-31.62 -23.541)" fill="#df4535" />
        <circle id="Ellipse_13" data-name="Ellipse 13" cx="0.938" cy="0.938" r="0.938" transform="translate(14.377 12.814)" fill="#df4535" />
        <circle id="Ellipse_14" data-name="Ellipse 14" cx="0.938" cy="0.938" r="0.938" transform="translate(8.751 12.814)" fill="#df4535" />
        <path id="Path_36" data-name="Path 36" d="M29.169,10A12.5,12.5,0,0,0,20.33,31.341L29.169,40l8.841-8.666A12.5,12.5,0,0,0,29.169,10Zm6.876,11.252-1.25.625V27.5a.626.626,0,0,1-.625.625h-1.25a.626.626,0,0,1-.625-.625v-1.25H26.043V27.5a.626.626,0,0,1-.625.625h-1.25a.626.626,0,0,1-.625-.625V21.877l-1.25-.625V20h1.875l1.6-3.192a1.11,1.11,0,0,1,.9-.559h5a1.11,1.11,0,0,1,.9.559L34.169,20h1.875Z" transform="translate(-16.667 -10)" fill="#df4535" />
      </g>
    </svg>
  )
}

export default DeliverySvg
