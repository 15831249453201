import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#EFEFEF',
    padding: '12px 20px',
    borderBottom: '1px solid #ccc',
    [theme.breakpoints.up('lg')]: {
      background: 'transparent',
      paddingTop: 30,
      border: 0,
    },
    '& p': {
      margin: 0,
      marginTop: 10,
      fontSize: 14,
      lineHeight: '20px',
      [theme.breakpoints.up('lg')]: {
        textAlign: 'center',
      },
    },
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
    },
    '& h2': {
      margin: 0,
      fontFamily: 'Wisdom-Script',
      fontSize: 36,
      lineHeight: '40px',
      marginRight: 15,
      color: '#DF4535',
      textTransform: 'capitalize',
      [theme.breakpoints.up('lg')]: {
        fontSize: 80,
        lineHeight: '96px',
        marginBottom: 10,
      },
    },
    '& h5': {
      fontFamily: 'FuturaStd-Medium',
      textTransform: 'uppercase',
      margin: 0,
      fontSize: 14,
      lineHeight: '20px',
      flex: 1,
      letterSpacing: 2,
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
        letterSpacing: 4,
      },
    },
  },
}))

function ProductTitle({ title, subTitle, desc }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <h2>{title}</h2>
        <h5>{subTitle}</h5>
      </div>
      {
        !!desc && <p>{desc}</p>
      }
    </div>
  )
}

export default ProductTitle
