import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Dialog from '../Dialog'
import { clearError } from '../../../redux/action/pageAction'

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: 0,
    maxWidth: 500,
    margin: '0 auto',
    color: theme.palette.error.main,
    textAlign: 'center',
    '& p': {
      color: theme.palette.secondary.main,
    },
  },
}))

function ErrorMessageDialog() {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [open, setOpen] = useState(false)
  const { error } = useSelector(st => st.page)
  const classes = useStyles()
  const dp = useDispatch()
  useEffect(() => {
    if (error) {
      setOpen(true)
    }
  }, [error])

  const handleClose = () => {
    setOpen(false)
    dp(clearError())
  }

  return (
    <Dialog
      open={open}
      maxWidth={588}
      fullWidth
      title={error?.title || `${t('Sorry')}!`}
      onClose={handleClose}
    >
      <div className={classes.dialog}>
        <div>
          {
            error?.type ? (
              <>
                {
                  error?.type === 'validation' && Object.values(error.errors).map((message, i) => (
                    <div key={i}>{message.join(', ')}</div>
                  ))
                }
                {
                  error?.type === 'products' && Object.values(error.errors).map((product, i) => (
                    <div key={i}>
                      <p>
                        {t('Some items in your order cart are not currently available')}
                        <br />
                        {t('To continue, unavailable item will be removed')}
                      </p>
                      {(language === 'tw' && product.nameTw) || product.name}
                    </div>
                  ))
                }
                {
                  error?.type === 'message' && error.error
                }
              </>
            ) : (error?.message || '')
          }
        </div>
      </div>
    </Dialog>
  )
}

export default ErrorMessageDialog
