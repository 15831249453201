import React, { useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useInSize from '../../utils/reactHooks/useInSize'
import ProductCard from './ProductCard'
import ProductList from './ProductList'
import Dialog from '../Dialog'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > div': {
        width: '33.3333%',
        padding: '30px 37px',
      },
    },
  },
  dialog: {
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
    },
    '& .MuiPaper-root': {
      padding: 20,
      paddingTop: 70,
      margin: 7,
      width: 'calc(100% - 14px)',
      maxHeight: 'calc(100% - 14px)',
    },
  },
}))

function Products({
  products = [],
  picked,
  className = '',
  onPlus = () => {},
  onMinus = () => {},
  onUpdate = () => {},
  ...rest
}) {
  const isMobile = useInSize([0, 1200])
  const [current, setCurrent] = useState(null)
  const [open, setOpen] = useState(false)
  const { i18n } = useTranslation()
  const Comp = isMobile ? ProductList : ProductCard
  const classes = useStyles()

  const handlePlus = (ev, product) => {
    ev.preventDefault()
    ev.stopPropagation()
    onPlus(product)
  }

  const handleMinus = (ev, product) => {
    ev.preventDefault()
    ev.stopPropagation()
    onMinus(product)
  }

  const handleView = (ev, product) => {
    ev.preventDefault()
    ev.stopPropagation()
    setCurrent(product)
    setOpen(true)
  }

  const { language } = i18n
  return (
    <div className={clsx(classes.root, className)}>
      { products.filter(p => p.areaActive).map(product => (
        <Comp
          key={product.id}
          {...product}
          name={(language === 'tw' && product.nameTw) || product.name}
          desc={(language === 'tw' && product.descriptionTw) || product.description}
          image={product.imageFullurl}
          picked={picked?.[product.code]?.picked || 0}
          additional={picked?.[product.code]?.additional}
          price={product.price}
          specialPrice={product.specialPrice}
          areaActive={product?.areaActive || false}
          isFixedBundle={product?.isFixedBundle || false}
          onPlus={ev => handlePlus(ev, product)}
          onMinus={ev => handleMinus(ev, product)}
          onView={ev => handleView(ev, product)}
          onUpdate={() => onUpdate(product)}
          {...rest}
        />
      )) }
      <Dialog
        className={classes.dialog}
        open={open}
        maxWidth={754}
        fullWidth
        onClose={() => setOpen(false)}
      >
        <div className={classes.dialogProduct}>
          {
            !!current && (
              <ProductCard
                {...current}
                name={(language === 'tw' && current.nameTw) || current.name}
                desc={(language === 'tw' && current.descriptionTw) || current.description}
                image={current.imageFullurl}
                disabled
                onlyView
              />
            )
          }
        </div>
      </Dialog>
    </div>
  )
}

export default Products
