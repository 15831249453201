import React, {
  useState, useEffect, useRef, useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import CartSvg from '../../svg/CartSvg'
import Drawer from '../Drawer'
import useIsDesktop from '../../utils/reactHooks/useIsDesktop'
import useOrder from '../../hooks/useOrder'
import CartList from './CartList'
import CartEmpty from './CartEmpty'

const useStyles = makeStyles(() => ({
  root: {

  },
  cartDrawer: {
    '& .drawer-container': {
      background: '#efefef',
    },
  },
}))

function Cart() {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const inited = useRef(false)
  const {
    comboItems, addonsItems, foodmartItems, subtotal, shipping, tax, total,
  } = useOrder()

  const { promotions } = useSelector(st => st.common)
  const { area } = useSelector(st => st.area)
  const classes = useStyles()
  const isDesktop = useIsDesktop()

  useEffect(() => {
    if (open) {
      document.getElementById('root').classList.add('is-blur')
    } else {
      document.getElementById('root').classList.remove('is-blur')
    }
    return () => {
      document.getElementById('root').classList.remove('is-blur')
    }
  }, [open])

  useEffect(() => {
    if (inited.current) {
      setOpen(true)
    }
    inited.current = true
  }, [subtotal])

  const gifts = useMemo(() => {
    if (promotions.length && subtotal) {
      const availablePromotions = promotions.filter(
        promotion => promotion.areas.length === 0 || promotion.areas.includes(area?.id),
      )
      const giftItems = []
      const carts = [
        ...Object.values(comboItems),
        ...Object.values(addonsItems),
        ...Object.values(foodmartItems),
      ]
      availablePromotions.forEach(promotion => {
        const gift = promotion.gift || promotion.product
        const inCartItem = gift ? carts.find(cart => cart.code === gift.code) : null
        const minus = inCartItem ? inCartItem.picked : 0
        let count = 0
        if (gift) {
          if (promotion.type === 'subtotal') {
            if (subtotal >= +promotion.subtotal) {
              count = Math.floor(subtotal / +promotion.subtotal)
            }
          } else {
            const cartProduct = carts.find(
              cart => cart.code === promotion.productCode && cart.picked >= promotion.productCount,
            )
            count = cartProduct ? Math.floor(cartProduct.picked / promotion.productCount) : 0
          }
          if (promotion.amountLimits) count = Math.min(count, promotion.amountLimits)
          const given = count * promotion.quantity
          if (given && gift.quantity - minus > 0) {
            giftItems.push({
              code: gift.code,
              name: gift.name,
              nameTw: gift.nameTw,
              picked: Math.min(given, (gift.quantity - minus)),
            })
          }
        }
      })

      return giftItems
    }
    return []
  }, [subtotal, promotions, comboItems, addonsItems, foodmartItems, area])

  return (
    <>
      <button className="header-cart" type="button" onClick={() => setOpen(true)}>
        {!!subtotal && <span className="cart-alert" />}
        <CartSvg />
      </button>
      <Drawer
        className={classes.cartDrawer}
        anchor="right"
        title={t('Order cart')}
        width={isDesktop ? 430 : '100%'}
        open={open}
        onClose={() => setOpen(false)}
      >
        { subtotal
          ? (
            <CartList
              combo={comboItems}
              addons={addonsItems}
              foodmart={foodmartItems}
              gifts={gifts}
              subtotal={subtotal}
              shipping={shipping}
              tax={tax}
              total={total}
              onPageChange={() => setOpen(false)}
            />
          )
          : <CartEmpty onPageChange={() => setOpen(false)} /> }
      </Drawer>
    </>
  )
}

export default Cart
