import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import { Controller } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import GreenTitle from '../../../components/Titles/GreenTitle'
import { updateShipMethod } from '../../../redux/action/orderAction'

const useStyles = makeStyles({
  deliveryDate: {
    fontSize: 13,
    '&:not(:last-child)': {
      marginBottom: 20,
    },
  },
})

function ShippingMethod({
  control, setValue, shippingId, errors,
}) {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const dp = useDispatch()
  const loadedRef = useRef(false)
  const { area } = useSelector(st => st.area)
  const { shipping } = useSelector(st => st.order)
  const classes = useStyles()

  useEffect(() => {
    if (loadedRef.current && area && shippingId) {
      const currentShipping = area.shippings.find(areaShipping => areaShipping.id === shippingId)
      if (currentShipping) {
        dp(updateShipMethod(currentShipping))
      } else {
        if (area.shippings[0]) {
          dp(updateShipMethod(area.shippings[0]))
        }
        setValue('shippingId', 0)
      }
    }
    loadedRef.current = true
  }, [area, dp, setValue, shippingId])

  return (
    <section>
      <GreenTitle>{t('Arrival date')}</GreenTitle>
      <div className="checkout-content">
        <div>
          <Controller
            name="shippingId"
            control={control}
            defaultValue={+shipping?.id || 0}
            render={({ onChange, value }) => (
              <FormControl component="fieldset" error={!!errors.shippingId}>
                <RadioGroup onChange={ev => onChange(+ev.target.value)} value={value}>
                  {
                    !!area && !!area.shippings.length && area.shippings.map(areaShipping => (
                      <React.Fragment key={areaShipping.id}>
                        <FormControlLabel
                          value={areaShipping.id}
                          control={<Radio />}
                          label={(language === 'tw' && areaShipping.nameTw) || areaShipping.name}
                        />
                        <span className={classes.deliveryDate}>
                          {`Delivery Date: ${format(new Date(areaShipping.arrivalStartDateTime), 'h:mm a')} - ${format(new Date(areaShipping.arrivalEndDateTime), 'h:mm a')} ${format(new Date(areaShipping.arrivalStartDateTime), 'MM/dd/yy')}`}
                        </span>
                      </React.Fragment>
                    ))
                  }
                </RadioGroup>
              </FormControl>
            )}
          />
          { !!errors.shippingId && (
            <FormHelperText error>
              {t(errors.shippingId.message)}
            </FormHelperText>
          )}
        </div>
      </div>
    </section>
  )
}

export default ShippingMethod
