import React from 'react'
import { makeStyles } from '@material-ui/core'
import CircleLogoSvg from '../../svg/CircleLogoSvg'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingTop: 34,
    '& h2': {
      fontSize: 24,
      lineHeight: '32px',
      margin: '34px auto',
      fontFamily: 'FuturaStd-bold',
      color: theme.palette.secondary.main,
      textAlign: 'center',
      maxWidth: 238,
      [theme.breakpoints.up('md')]: {
        maxWidth: 'unset',
      },
    },
  },
}))

function LogoText({ children }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircleLogoSvg />
      <h2>{children}</h2>
    </div>
  )
}

export default LogoText
