import React from 'react'
import clsx from 'clsx'
import { FormattedNumber } from 'react-intl'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { PRODUCT_TYPE } from '../../../constant'

function ProductPrice({
  classes,
  type,
  price,
  comboPrice,
  specialPrice,
  addPrices = 0,
  restockAt,
  areaActive,
  quantity,
}) {
  const { t } = useTranslation()

  return areaActive ? (
    <>
      {quantity ? (
        <div
          className={clsx(classes.price, {
            [classes.addPrice]:
              type === PRODUCT_TYPE.COMBO_BROTH || type === PRODUCT_TYPE.BUNDLE_ITEM,
          })}
        >
          {!!+specialPrice && type !== PRODUCT_TYPE.COMBO_BROTH && (
            <span>
              <FormattedNumber
                style="currency"
                value={+specialPrice + addPrices}
                currency="USD"
              />
            </span>
          )}

          <span>
            {type === PRODUCT_TYPE.COMBO_BROTH || type === PRODUCT_TYPE.BUNDLE_ITEM ? '+' : ''}
            <FormattedNumber
              style="currency"
              value={type === PRODUCT_TYPE.COMBO_BROTH
                ? +comboPrice + addPrices : +price + addPrices}
              currency="USD"
            />
          </span>
        </div>
      ) : (
        <>
          {restockAt ? (
            <div className={classes.restock}>
              <span>{`${t('Restock')}: `}</span>
              <span>{format(new Date(restockAt), 'MM/dd/yyyy')}</span>
            </div>
          ) : (
            <div className={classes.soldout}>{t('Sold out')}</div>
          )}
        </>
      )}
    </>
  ) : (
    <strong className={clsx(classes.soldout, classes.notAvailable)}>
      {t('Currently unavailable')}
    </strong>
  )
}

export default ProductPrice
