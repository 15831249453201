import React from 'react'
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import MenuItem from '@material-ui/core/MenuItem'

function AddressForm({
  addressBook = {}, register, errors, control,
}) {
  const { t } = useTranslation()
  const { stateOptions } = useSelector(st => st.common)

  return (
    <>
      <TextField
        label={`${t('First name')}*`}
        placeholder={t('First name')}
        name="firstName"
        fullWidth
        defaultValue={addressBook.firstName || ''}
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
        inputRef={register}
      />
      <TextField
        label={`${t('Last name')}*`}
        placeholder={t('Last name')}
        name="lastName"
        fullWidth
        defaultValue={addressBook.lastName || ''}
        error={!!errors.lastName}
        helperText={t(errors.lastName?.message)}
        inputRef={register}
      />
      <TextField
        label={`${t('Email')}*`}
        placeholder={t('Email')}
        name="email"
        type="email"
        fullWidth
        defaultValue={addressBook.email || ''}
        error={!!errors.email}
        helperText={t(errors.email?.message)}
        inputRef={register}
      />
      <TextField
        label={`${t('Address')}*`}
        placeholder={t('Address')}
        name="address"
        fullWidth
        defaultValue={addressBook.address || ''}
        error={!!errors.address}
        helperText={t(errors.address?.message)}
        inputRef={register}
      />
      <TextField
        label={t('Apartment, suite, etc (optional)')}
        placeholder={t('Apartment, suite, etc (optional)')}
        name="apartment"
        fullWidth
        defaultValue={addressBook.apartment || ''}
        error={!!errors.apartment}
        helperText={t(errors.apartment?.message)}
        inputRef={register}
      />
      <TextField
        label={`${t('City')}*`}
        placeholder={t('City')}
        name="city"
        fullWidth
        defaultValue={addressBook.city || ''}
        error={!!errors.city}
        helperText={t(errors.city?.message)}
        inputRef={register}
      />
      <Controller
        name="state"
        control={control}
        defaultValue="CA"
        rules={{ required: true }}
        render={props => (
          <TextField
            label={`${t('State')}*`}
            placeholder={t('State')}
            name="state"
            fullWidth
            error={!!errors.state}
            helperText={errors.state?.message}
            inputRef={props.ref}
            select
            onChange={ev => props.onChange(ev.target.value)}
            value={props.value}
          >
            {stateOptions?.map(option => (
              <MenuItem key={option.state} value={option.state}>
                {option.state}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <TextField
        label={`${t('Zip code')}*`}
        placeholder={t('Zip code')}
        name="zipCode"
        fullWidth
        defaultValue={addressBook.zipCode || ''}
        error={!!errors.zipCode}
        helperText={t(errors.zipCode?.message)}
        inputRef={register}
      />
      <Controller
        name="phone"
        control={control}
        defaultValue={addressBook.phone || ''}
        rules={{ required: true }}
        render={props => (
          <TextField
            label={t('Phone number')}
            placeholder={t('Phone Number 999-999-9999')}
            name="phone"
            fullWidth
            error={!!errors.phone}
            helperText={t(errors.phone?.message)}
            inputRef={register}
            onChange={e => {
              const { value } = e.target
              if (value) {
                const v = value.replace(/[^0-9]/g, '')
                if (v.length < 4) {
                  props.onChange(value)
                } else if (v.length < 7) {
                  props.onChange(v.replace(/(\d{3})(\d{0,3})/, '$1-$2'))
                } else {
                  props.onChange(v.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3'))
                }
              } else {
                props.onChange(value)
              }
            }}
            value={props.value}
          />
        )}
      />
      <TextField
        label={t('Company (optional)')}
        placeholder={t('Company (optional)')}
        name="company"
        fullWidth
        defaultValue={addressBook.company || ''}
        error={!!errors.company}
        helperText={t(errors.company?.message)}
        inputRef={register}
      />
    </>
  )
}

export default AddressForm
