import types from '../actionTypes/commonTypes'
import apis from '../../apis'

const fetchRequest = type => ({
  type: types[`FETCH_${type}_REQUEST`],
})

const fetchFailure = (type, error) => ({
  type: types[`FETCH_${type}_FAILURE`],
  error,
})

const fetchSuccess = (type, data) => ({
  type: types[`FETCH_${type}_SUCCESS`],
  data,
})

export const fetchConfig = () => async dispatch => {
  dispatch(fetchRequest('CONFIG'))
  try {
    const res = await apis.common.getConfig()
    dispatch(fetchSuccess('CONFIG', res.data.data))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    dispatch(fetchFailure('CONFIG', err))
  }
}

export const fetchCommon = () => async dispatch => {
  dispatch(fetchRequest('COMMON'))
  try {
    const res = await apis.common.getCommon()
    dispatch(fetchSuccess('COMMON', res.data.data))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    dispatch(fetchFailure('COMMON', err))
  }
}
