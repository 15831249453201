import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import GreenTitle from '../../../components/Titles/GreenTitle'
import TextButton from '../../../components/Buttons/TextButton'
import CustomInput from '../../../components/Inputs/Input/Input'
import Login from '../Dialogs/Login'
import PasswordTips from '../../../components/PasswordTips'
import apis from '../../../apis'
import { setUser } from '../../../redux/action/userAction'

const useStyles = makeStyles(theme => ({
  passwordNote: {
    color: theme.palette.error.main,
    fontSize: 16,
    marginTop: 14,
    '& h6': {
      margin: 0,
      marginBottom: 10,
      fontFamily: 'FuturaStd-book',
      lineHeight: 1,
      fontSize: 16,
      fontWeight: 400,
    },
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      '& li': {
        display: 'flex',
      },
      '& svg': {
        marginRight: 6,
        marginTop: 5,
      },
    },
  },
  hide: {
    display: 'none',
  },
}))

function CustomInfo({
  register,
  errors,
  createAccount,
  password,
  setValue,
  firstName,
  lastName,
  email,
  clearErrors,
  user,
}) {
  const { t } = useTranslation()
  const dp = useDispatch()
  const classes = useStyles()
  const [open, setOpen] = useState({
    login: false,
  })
  const [show, setShow] = useState({
    password: false,
    confirmPassword: false,
  })

  const toggleOpen = (name, ev = true) => {
    setOpen(st => ({ ...st, [name]: ev }))
  }

  const handleSuccess = ev => {
    setValue('firstName', ev.firstName)
    setValue('lastName', ev.lastName)
  }

  const handleCreateAccountChange = ev => {
    if (!ev.target.checked) {
      clearErrors(['email', 'password', 'passwordConfirm'])
    }
  }

  useEffect(() => {
    if (user) {
      setValue('emailCheck', true)
      setValue('email', user.email)
      clearErrors(['email', 'emailCheck'])
    }
  }, [clearErrors, setValue, user])

  const handleLogout = async () => {
    await apis.user.logout()
    localStorage.removeItem('bpmarket_token')
    setValue('discountCode', '')
    dp(setUser(null))
  }

  return (
    <section>
      {!!user && (
        <div className="checkout-logout">
          {`${t('You are log in')} (${user.email}) `}
          <TextButton onClick={handleLogout}>
            {t('Logout')}
          </TextButton>
        </div>
      )}
      <GreenTitle rightComp={!user ? (
        <div>
          {t('Already have an account')}
          &nbsp;
          <TextButton onClick={() => toggleOpen('login')}>
            {t('Log in')}
          </TextButton>
        </div>
      ) : null}
      >
        {t('Customer info')}
      </GreenTitle>
      <div className="checkout-content">
        <div>
          <TextField
            label={t('First name')}
            placeholder={t('First name')}
            name="firstName"
            fullWidth
            InputLabelProps={{ shrink: !!firstName }}
            defaultValue={user?.firstName || ''}
            error={!!errors.firstName}
            helperText={t(errors.firstName?.message)}
            inputRef={register}
          />
          <TextField
            label={t('Last name')}
            placeholder={t('Last name')}
            name="lastName"
            fullWidth
            InputLabelProps={{ shrink: !!lastName }}
            defaultValue={user?.lastName || ''}
            error={!!errors.lastName}
            helperText={t(errors.lastName?.message)}
            inputRef={register}
          />
          <TextField
            style={{ display: user ? 'none' : 'block' }}
            label={t('Email')}
            placeholder={t('Email')}
            name="email"
            fullWidth
            InputLabelProps={{ shrink: !!email }}
            error={!!errors.email}
            helperText={t(errors.email?.message)}
            defaultValue={user?.email || ''}
            inputRef={register}
            inputProps={{
              autoComplete: 'new-email',
            }}
          />
          <FormControlLabel
            style={{ marginTop: 20, display: user ? 'none' : 'block' }}
            control={<Checkbox name="emailCheck" inputRef={register} />}
            label={t('Yes, this email is correct')}
          />

          {!!errors.emailCheck && (
            <FormHelperText error style={{ marginTop: -5 }}>
              {errors.emailCheck.message}
            </FormHelperText>
          )}
          {
            !user && (
              <div>
                <FormControlLabel
                  control={<Checkbox name="createAccount" inputRef={register} onChange={handleCreateAccountChange} />}
                  label={t('Create an account')}
                />
                <CustomInput
                  className={clsx({ [classes.hide]: !createAccount })}
                  label={t('Password')}
                  name="password"
                  variant="filled"
                  fullWidth
                  error={!!errors.password}
                  inputRef={register}
                  size="md"
                  style={{ marginTop: 30 }}
                  inputProps={{
                    type: show.password ? 'text' : 'password',
                    autoComplete: 'new-password',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          aria-label="toggle password visibility"
                          onClick={() => setShow(s => ({ ...s, password: !s.password }))}
                        >
                          {show.password ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  className={clsx({ [classes.hide]: !createAccount })}
                  label={t('Confirm password')}
                  name="passwordConfirm"
                  variant="filled"
                  fullWidth
                  error={!!errors.passwordConfirm}
                  helperText={t(errors.passwordConfirm?.message)}
                  inputRef={register}
                  size="md"
                  style={{ marginTop: 15 }}
                  inputProps={{
                    type: show.confirmPassword ? 'text' : 'password',
                    autoComplete: 'new-password',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          aria-label="toggle password visibility"
                          onClick={
                            () => setShow(s => ({ ...s, confirmPassword: !s.confirmPassword }))
                          }
                        >
                          {show.confirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                { createAccount && <PasswordTips password={password} />}
              </div>
            )
          }
        </div>
      </div>
      <Login open={!!open.login} setOpen={ev => toggleOpen('login', ev)} onSuccess={handleSuccess} />
    </section>
  )
}

export default CustomInfo
