/* eslint-disable react/no-danger */
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Dialog from '../Dialog'
import Button from '../../Buttons/Button'

const useStyles = makeStyles(theme => ({
  dialog: props => ({
    padding: '0 20px',
    '& p': {
      maxWidth: 748,
      margin: '0 auto',
      textAlign: 'center',
      lineHeight: 1.4,
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
    [theme.breakpoints.up('md')]: {
      padding: `0 ${props.gap}px`,
    },
  }),
  btn: {
    width: '100%',
    marginBottom: 20,
    maxWidth: 325,
    margin: '0 auto',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 390,
    },
  },
  lists: props => ({
    marginTop: 10,
    marginBottom: 10,
    marginLeft: -props.gap / 2,
    marginRight: -props.gap / 2,
    display: 'flex',
    '& > div': {
      textAlign: 'center',
      flex: '0 0 33.33%',
      padding: `0 ${props.gap / 2}px`,
    },
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 50,
      marginBottom: 50,
    },
  }),
  btns: props => ({
    marginLeft: -props.gap / 2,
    marginRight: -props.gap / 2,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginBottom: 20,
      '& a': {
        textAlign: 'center',
      },
      '& div': {
        textAlign: 'center',
        flex: '0 0 33.33%',
        padding: `0 ${props.gap / 2}px`,
      },
    },
  }),
  checkoutBtn: {
    '&.MuiButton-root': {
      maxWidth: 325,
      margin: '0 auto',
      display: 'flex',
    },
  },
}))

function MoreDialog({
  open, onClose, title, onClick = () => { },
  hotPotText, addonsText, foodmartText,
  hideContent, content, maxWidth, gap,
}) {
  const { t } = useTranslation()
  const classes = useStyles({ gap })
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth || 1350}
      fullWidth
      title={title || t('Wait May we interest you in')}
      onClose={onClose}
    >
      <div className={classes.dialog}>
        {!hideContent && <p dangerouslySetInnerHTML={{ __html: content || t('Dont miss out on these items') }} /> }
        <div className={classes.lists}>
          <div>
            <img src="/more-1.png" alt="more" />
          </div>
          <div>
            <img src="/more-2.png" alt="more" />
          </div>
          <div>
            <img src="/more-3.png" alt="more" />
          </div>
        </div>
        <div className={classes.btns}>
          <div>
            <Button
              className={classes.btn}
              variant="outlined"
              color="secondary"
              disableElevation
              onClick={onClick}
              component={Link}
              to="/hot-pot"
            >
              {hotPotText || t('Add another hot pot kit')}
            </Button>
          </div>
          <div>
            <Button
              className={classes.btn}
              variant="outlined"
              color="secondary"
              disableElevation
              onClick={onClick}
              component={Link}
              to="/add-ons"
            >
              {addonsText || t('More addons')}
            </Button>
          </div>
          <div>
            <Button
              className={classes.btn}
              variant="outlined"
              color="secondary"
              disableElevation
              onClick={onClick}
              component={Link}
              to="/food-mart"
            >
              {foodmartText || t('Shop food mart')}
            </Button>
          </div>
        </div>
        {!hideContent && (
          <Button
            className={classes.checkoutBtn}
            variant="contained"
            color="primary"
            disableElevation
            onClick={onClick}
            fullWidth
            component={Link}
            to="/checkout"
          >
            {t('Continue to checkout')}
          </Button>
        )}
      </div>
    </Dialog>
  )
}
export default MoreDialog
