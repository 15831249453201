import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import LogoColorSvg from '../../svg/LogoColorSvg'
import Button from '../Buttons/Button'

const useStyles = makeStyles(theme => ({
  emptyContainer: {
    background: 'white',
    paddingBottom: 70,
    '& h6': {
      fontSize: 36,
      lineHeight: '43px',
      fontFamily: 'FuturaStd-CondensedBold',
      textAlign: 'center',
      color: theme.palette.secondary.main,
    },
  },
  emptyText: {
    padding: '65px 0 71px',
    textTransform: 'uppercase',
  },
  emptyBtns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  btn: {
    width: 348,
    '& + &': {
      marginTop: 20,
    },
  },
}))

function CartEmpty({ onPageChange }) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <div className={classes.emptyContainer}>
        <div className={classes.emptyText}>
          <h6>{t('Your cart is empty')}</h6>
        </div>
        <div className={classes.emptyBtns}>
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            disableElevation
            onClick={onPageChange}
            component={Link}
            to="/hot-pot"
          >
            {t('Order hot pot kit')}
          </Button>
          <Button
            className={classes.btn}
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={onPageChange}
            component={Link}
            to="/add-ons"
          >
            {t('Shop addons')}
          </Button>
          <Button
            className={classes.btn}
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={onPageChange}
            component={Link}
            to="/food-mart"
          >
            {t('Shop food mart')}
          </Button>
        </div>
      </div>
      <LogoColorSvg className="header-color-logo" />
    </>
  )
}

export default CartEmpty
