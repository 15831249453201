import axios from './axios'

const discountApi = {
  my: () => axios.get('/api/discount/my'),
  check: (value, areaId, subTotal, items) => axios.get(`/api/discount/${value}/${items}`, {
    params: {
      areaId,
      subTotal,
    },
  }),
}

export default discountApi
