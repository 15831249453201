/* eslint-disable max-len */
import React from 'react'
import { useTranslation } from 'react-i18next'
import StaticPage from '../../../components/Pages/StaticPage'

function Policy() {
  const { t } = useTranslation()

  return (
    <StaticPage title={t('Privacy policy')}>
      <p>
        This Privacy Policy describes the policies governing the collection,
        use, and disclosure of information that Boiling Point Group, Inc., a
        California corporation, collects and receives from you, including
        information relating to your past use of the Boiling Point’s website and
        services.
        <br />
        <br />
        <strong>Updates to this Privacy Policy</strong>
        <br />
        Boiling Point may update this Privacy Policy at any time by posting the
        amended Privacy Policy on the Site and/or by sending a notice to the
        email address you provided. Your continued use of the Site shall
        constitute your consent to such changes and you will be subject to the
        then-current Privacy Policy.
        <br />
        <br />
        <strong>Collection and Use of Information</strong>
        <br />
        Boiling Point receives and may store any information you enter in on the
        Site or provide us in any other way. This may include personal
        information by which users are identified (user names, email address,
        first and last name, street address and telephone number).
        <br />
        <br />
        Personal information may be used for general purposes, including, but
        not limited to, sending you Boiling Point’s newsletters, notifying you
        of updates and other products or services that may be of interest to
        you, requesting your voluntary opinion/feedback on current products
        and/or services and communicating with you in general.
        <br />
        <br />
        Boiling Point also may receive and store information on our server logs
        about your browser and/or computer, including, but not limited to, IP
        address, pages viewed and requested, referring website and web browser
        software (e.g. Internet Explorer, Mozilla, etc.).
        <br />
        <br />
        When you visit the Site, Boiling Point may collect information from you
        automatically through “cookies” or similar technology. Web cookies are
        small-unique identifier text files that allow a web server to recognize
        that you are a user that accessed the server on previous occasions.
        <br />
        <br />
        Boiling Point does not sell, rent or share the information with other
        people or nonaffiliated companies except when we have your permission or
        under the following instances:
        <br />
        <br />
        (1) To send marketing and other communications to our visitors about
        Boiling Point products or services etc;
        <br />
        (2) To third party vendors that provide business services to Boiling
        Point, including customer service, marketing, etc.;
        <br />
        (3) In response to court order, subpoena, other legal process, or as
        otherwise required by law;
        <br />
        (4) To exercise or establish our legal rights and/or defend against
        claims;
        <br />
        (5) When Boiling Point believes it to be reasonably necessary to
        investigate, prevent, or take action regarding illegal activities,
        suspected fraud, violations of Boiling Point’s Terms of Use, or as
        otherwise required by law;
        <br />
        (6) In connection with a corporate transaction (e.g. consolidation,
        merger, sale of assets, etc.); or
        <br />
        (7) Providing anonymous or aggregate information (i.e. non-personal and
        non-billing information) to third parties (e.g. investors, advertisers,
        business partners and clients) to the extent necessary to allow them to
        understand the kinds and number of visitors to the Site and how those
        visitors use the Site.
        <br />
        <br />
        You have the right to access, correct, or request deletion of the
        personal information that we collect, subject to certain exceptions. To
        protect the privacy and the security of your personal information, we
        may request data from you to enable us to verify your identity and your
        right to access such data, as well as to search for and provide you with
        the personal information we maintain. If we cannot verify your identity
        based on the information we have on file, we may request additional
        information from you, which we will only use to verify your identity,
        and for security or fraud-prevention purposes. Please note that there
        may be instances where applicable laws or regulatory requirements allow
        or require us to refuse to provide or delete some or all of the personal
        information that we maintain. Below are the rights that every user is
        entitled to:
        <br />
        <br />
        <strong><u>The Right to Access</u></strong>
        &nbsp;– You have the right to request copies of your personal data held by
        Boiling Point. We may charge you a small fee for this service.
        <br />
        <strong><u>The Right to Rectification</u></strong>
        &nbsp;– You have the right to request that Boiling Point correct any
        information you believe is inaccurate. You also have the right to
        request Boiling Point to complete any information you believe is
        incomplete.
        <br />
        <strong><u>The Right to Erasure</u></strong>
        &nbsp;– You have the right to request that Boiling Point erase your personal
        data, under certain conditions.
        <br />
        <strong><u>The Right to Restrict Processing</u></strong>
        &nbsp;– You have the right to request that Boiling Point restrict the
        processing of your personal data, under certain conditions.
        <br />
        <strong><u>The Right to Object to Processing</u></strong>
        &nbsp;– You have the right to object to Boiling Point’s processing of your
        personal data, under certain conditions.
        <br />
        <strong><u>The Right to Data Portability</u></strong>
        &nbsp;– You have the right to request that Boiling Point transfer the data
        that we have collected to another organization, or directly to you,
        under certain conditions.
        <br />
        <br />
        To exercise any of the rights set forth above, please contact us by
        email at&nbsp;
        <a href="mailto:cs@bpgroupusa.com">cs@bpgroupusa.com</a>
        .
        <br />
        <br />
        <strong>
          Collection and Use of Information through Other Host Sites
        </strong>
        <br />
        Boiling Point may collaborate with other companies to make Boiling
        Point’s content and/or services available via their websites (“Host
        Sites”). Personal and/or demographic information you provided to Host
        Sites may be included in Boiling Point’s database, and we will handle
        such information received or gathered via Host Sites subject to this
        Privacy Policy.
        <br />
        On the other hand, information gathered via Host Sites or a website
        co-sponsored by Boiling Point and another company may become the
        property of the other company as well as of Boiling Point. The use of
        such information by the other company will be subject to the privacy
        policy of that company. Boiling Point is not responsible for that
        company&#39;s use of your personal or demographic information and suggests
        that you to examine their privacy policy and terms of use before
        deciding to provide any personal, billing and/or other information.
        <br />
        <br />
        <strong>Security Measures</strong>
        <br />
        Boiling Point has employed appropriate physical, procedural and
        technological measures to help protect your personal and/or other
        information from unauthorized access or disclosure. However, we CANNOT
        guarantee that unauthorized third parties will never be able to defeat
        those measures or use your information for improper purposes. We are not
        responsible for the retention, use or privacy of personal and/or other
        information saved to the Site.
        <br />
        <br />
        <strong>Feedback and Posting to Public Areas of the Site</strong>
        <br />
        We do not claim ownership of any feedback, reviews, questions, comments,
        ideas, suggestions, images and/or the like contained in any submission
        you submit via the Sites. However, by posting any of such contents to
        any area of the Site by electronic mail, postings on this Site or
        otherwise, you automatically grant, and you represent and warrant that
        you have the right to grant, to Boiling Point and its designees an
        irrevocable, perpetual, royalty free, non-exclusive, fully
        sub-licensable, worldwide license to use, distribute, reproduce, modify,
        publish, perform and display such contents and to incorporate such
        contents into other works in any format or medium now known or later
        developed. Furthermore, you grant Boiling Point an absolute right to
        use, distribute, publish or display such submissions for marketing
        purposes. Boiling Point may also contact you for further information
        concerning your submissions.
        <br />
        Any of your personal information posted in public areas may be accessed,
        collected and used by others over whom we exercise no control. Boiling
        Point is not responsible for use by third parties of information you
        post in public areas of the Site nor is Boiling Point responsible for
        any personal information posted in the public areas by any of its users.
        <br />
        <br />
        <strong>How to Contact Us</strong>
        <br />
        If you have any questions about our Privacy Policy, the data we hold on
        you, or if you would like to exercise one of your data protection
        rights, please do not hesitate to contact us by email at&nbsp;
        <a href="mailto:cs@bpgroupusa.com">cs@bpgroupusa.com</a>
        .
      </p>
    </StaticPage>
  )
}

export default Policy
