import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ButtonBase from '@material-ui/core/ButtonBase'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { makeStyles } from '@material-ui/core'
import Slider from 'react-slick'
import MoreDialog from '../../Dialog/MoreDialog'
import MainBanner from '../MainBanner'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    overflow: 'hidden',
  },
  container: {
    position: 'relative',
    width: `${(300 / 360) * 100}%`,
    margin: '0 -10px',
    '& .slick-list': {
      overflow: 'visible',
    },
    [theme.breakpoints.up('sm')]: {
      width: `${(622 / 768) * 100}%`,
    },
    [theme.breakpoints.up('md')]: {
      width: `${(1520 / 1960) * 100}%`,
    },
    [theme.breakpoints.up('lg')]: {
      height: 600,
    },
    [theme.breakpoints.up('xl')]: {
      height: 750,
    },
  },
  controller: {
    position: 'absolute',
    bottom: 10,
    right: 20,
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: 18,
      width: 18,
      height: 18,
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      },
    },
  },
  page: {
    backgroundColor: '#FFFFFF',
    borderRadius: 18,
    width: 60,
    height: 18,
    fontSize: 10,
    lineHeight: 1,
    fontFamily: 'FuturaStd-Bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
    paddingTop: 1,
  },
  total: {
    color: '#CCCCCC',
  },
  item: {
    margin: '0 10px',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  box: {
    position: 'relative',
    height: 250,
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      height: 500,
    },
    [theme.breakpoints.up('md')]: {
      height: 400,
    },
    [theme.breakpoints.up('lg')]: {
      height: 600,
      flex: 1,
    },
    [theme.breakpoints.up('xl')]: {
      height: 750,
    },
  },
  image: {
    position: 'absolute',
    maxHeight: '100%',
    width: 'auto',
    display: 'block',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  title: {
    margin: 0,
    fontSize: 36,
    lineHeight: 1,
    fontFamily: 'FuturaStd-CondensedBold',
    [theme.breakpoints.up('lg')]: {
      fontSize: 80,
    },
  },
  content: {
    margin: 0,
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'FuturaStd-Book',
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
      lineHeight: '30px',
    },
  },
  main: {
    backgroundColor: '#F4F4F4',
    padding: '35px 52px 42px',
    height: 280,
    [theme.breakpoints.up('sm')]: {
      height: 240,
    },
    [theme.breakpoints.up('md')]: {
      height: 200,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: 'transparent',
      padding: 0,
      paddingTop: 120,
      width: 400,
      height: 'auto',
      marginRight: 100,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 191,
      marginRight: 150,
    },
  },
  actionButton: {
    height: 52,
    minWidth: 150,
    padding: '0 5px',
    fontSize: 16,
    fontFamily: 'FuturaStd-Bold',
    textTransform: 'uppercase',
    marginTop: 12,
    [theme.breakpoints.up('lg')]: {
      minWidth: 200,
      marginTop: 40,
    },
  },
}))

const SlideBanner = ({ banners, onOpen }) => {
  const mediaMatch = window.matchMedia('(max-width: 1199px)')
  const [matches, setMatches] = useState(mediaMatch.matches)
  const classes = useStyles()
  const { i18n } = useTranslation()
  const { language } = i18n
  const slideRef = useRef()
  const [paused, setPaused] = useState(false)
  const [index, setIndex] = useState(0)
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    autoplay: true,
  }

  useEffect(() => {
    if (paused) {
      slideRef.current.slickPause()
    } else {
      slideRef.current.slickPlay()
    }
  }, [paused])

  useEffect(() => {
    const handler = e => setMatches(e.matches)
    mediaMatch.addListener(handler)
    return () => mediaMatch.removeListener(handler)
  }, [mediaMatch])

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Slider ref={slideRef} {...settings} afterChange={setIndex}>
          {banners.map(banner => (
            <div key={banner.id}>
              <div
                className={classes.item}
                style={banner.bgColor || (!banner.bgColor && matches)
                  ? { backgroundColor: banner.bgColor }
                  : {
                    backgroundImage: `url(${banner.imageFullurl})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                  }}
              >
                <div className={classes.box}>
                  { banner.bgColor || (!banner.bgColor && matches) ? (
                    <img
                      className={classes.image}
                      src={banner.imageFullurl}
                      alt="banner"
                      style={(!banner.bgColor && matches) ? { width: '100%', height: '100%', objectFit: 'cover' } : null}
                    />
                  ) : null}

                </div>
                <div className={classes.main}>
                  {!!banner.title && (
                    <h3
                      className={classes.title}
                      style={matches ? { color: banner.titleFontColorMobile || '#573926' }
                        : { color: banner.titleFontColor || '#573926' }}
                    >
                      {(language === 'tw' && banner.titleTw) || banner.title}
                    </h3>
                  )}
                  {!!banner.content && (
                    <p
                      className={classes.content}
                      style={matches ? { color: banner.fontColorMobile || '#333' }
                        : { color: banner.fontColor || '#333' }}
                    >
                      {(language === 'tw' && banner.contentTw)
                        || banner.content}
                    </p>
                  )}
                  {banner.btnText && (banner.btnLink || banner.btnPage) ? (
                    <>
                      {banner.btnPage ? (
                        <ButtonBase
                          className={classes.actionButton}
                          component={Link}
                          to={banner.btnPage}
                          style={matches ? { backgroundColor: banner.btnBgColor || '#DC4535', color: banner.btnFontColorMobile || '#fff' }
                            : { backgroundColor: banner.btnBgColor || '#DC4535', color: banner.btnFontColor || '#fff' }}
                        >
                          {(language === 'tw' && banner.btnTextTw)
                        || banner.btnText}
                        </ButtonBase>
                      ) : (
                        <ButtonBase
                          className={classes.actionButton}
                          component="a"
                          href={banner.btnLink}
                          target="_blank"
                          style={matches ? { backgroundColor: banner.btnBgColor || '#DC4535', color: banner.btnFontColorMobile || '#fff' }
                            : { backgroundColor: banner.btnBgColor || '#DC4535', color: banner.btnFontColor || '#fff' }}
                        >
                          {(language === 'tw' && banner.btnTextTw)
                        || banner.btnText}
                        </ButtonBase>
                      )}
                    </>
                  ) : null}

                  {!banner.btnPage && !banner.btnLink && banner.btnText && (
                    <ButtonBase
                      className={classes.actionButton}
                      onClick={onOpen}
                      style={matches ? { backgroundColor: banner.btnBgColor || '#DC4535', color: banner.btnFontColorMobile || '#fff' }
                        : { backgroundColor: banner.btnBgColor || '#DC4535', color: banner.btnFontColor || '#fff' }}
                    >
                      {(language === 'tw' && banner.btnTextTw)
                        || banner.btnText}
                    </ButtonBase>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className={classes.controller}>
          <ButtonBase onClick={() => setPaused(st => !st)}>
            {paused ? <PlayArrowIcon /> : <PauseIcon />}
          </ButtonBase>
          <div className={classes.page}>
            <span>{index + 1}</span>
            <span className={classes.total}>{` / ${banners.length}`}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default function TopBanners() {
  const { data, isFetching } = useSelector(st => st.banner)
  const [showMore, setShowMore] = useState(false)
  const { t } = useTranslation()

  const showMainBanner = !isFetching && data?.length < 2

  return (
    <div>
      {showMainBanner ? (
        <MainBanner onOpen={() => setShowMore(true)} />
      ) : (
        <SlideBanner banners={data} onOpen={() => setShowMore(true)} />
      )}
      <MoreDialog
        open={showMore}
        onClose={() => setShowMore(false)}
        title={t('What are you looking for')}
        hotPotText={t('Order hot pot kit')}
        addonsText={t('Buy addons')}
        foodmartText={t('Shop food mart')}
        maxWidth={724}
        gap={20}
        hideContent
      />
    </div>
  )
}
