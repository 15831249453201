import React from 'react'
import { makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

const useInputStyles = makeStyles(theme => ({
  root: props => ({
    border: props.size === 'lg' ? `2px solid ${theme.palette.secondary.main}` : `1px solid ${theme.palette.secondary.main}`,
    overflow: 'hidden',
    borderRadius: 0,
    background: 'white',
    '&:hover': {
      background: '#fff',
    },
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
      background: `${theme.palette.error.main}11`,
    },
  }),
  input: props => ({
    padding: props.size === 'lg' ? '40px 50px 15px 14px' : '24px 15px 7px',
    fontSize: 16,
  }),
}))
const useLabelStyles = makeStyles(() => ({
  root: props => ({
    transform: props.size === 'lg' ? 'translate(16px, 30px)' : 'translate(16px, 20px)',
    textTransform: 'uppercase',
    '&.MuiInputLabel-focused': {
      color: '#909090',
      textTransform: 'capitalize',
    },
    '&.MuiInputLabel-shrink': {
      color: '#909090',
      textTransform: 'capitalize',
    },
  }),
  filled: props => ({
    '&.MuiInputLabel-shrink': {
      transform: props.size === 'lg' ? 'translate(17px, 20px) scale(0.75)' : 'translate(17px, 11px) scale(0.75)',
    },
  }),
}))

function CustomInput({
  size = 'lg', InputProps = {}, ...rest
}) {
  const inputClasses = useInputStyles({ size })
  const labelClasses = useLabelStyles({ size })
  return (
    <TextField
      InputLabelProps={{ classes: labelClasses }}
      InputProps={{ classes: inputClasses, disableUnderline: true, ...InputProps }}
      {...rest}
    />
  )
}

export default CustomInput
