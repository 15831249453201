import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const getItems = itemObj => Object.values(itemObj)
  .filter(item => item.picked > 0)
  .map(item => {
    const price = +item.data.price
    const specialPrice = +item.data.specialPrice

    return {
      code: item.data.code,
      name: item.data.name,
      nameTw: item.data.nameTw,
      picked: item.picked,
      price: specialPrice || price,
      oriPrice: price,
      isSpecialPrice: !!specialPrice && specialPrice < price,
      comboPrice: item.data.comboPrice,
      taxable: item.data.taxable,
      additional: item.additional,
      addPrices: item.addPrices,
      ...(item.data.meat ? {
        meatPrice: +item.data.meat.specialPrice || +item.data.meat.price,
        meatOriPrice: +item.data.meat.price,
        meatName: item.data.meat.name,
        meatNameTw: item.data.meat.nameTw,
      } : {}),
    }
  })

const getTotal = (items, discount) => ({
  total: items.reduce((acc, cur) => acc + cur.picked * (cur.price + cur.addPrices), 0),
  taxTotal: items
    .filter(item => item.taxable)
    .reduce((acc, cur) => acc + cur.picked * (cur.price + cur.addPrices), 0),
  discountTotal: discount ? items
    .filter(item => !discount.exclusiveProducts
      || !discount.exclusiveProducts.some(code => code === item.code))
    .filter(item => discount.specialAvailable || !item.isSpecialPrice)
    .reduce((acc, cur) => acc + cur.picked * (cur.price + cur.addPrices), 0)
    : 0,
})

export default function useOrder(discount = null) {
  const {
    combo, addons, foodmart, shipping, taxRate,
  } = useSelector(st => st.order)

  const area = useSelector(st => st.area.area)

  const comboItems = useMemo(() => getItems(combo), [combo])

  const addonsItems = useMemo(() => getItems(addons), [addons])

  const foodmartItems = useMemo(() => getItems(foodmart), [foodmart])

  const comboTotal = useMemo(() => getTotal(comboItems, discount), [comboItems, discount])

  const addonsTotal = useMemo(() => getTotal(addonsItems, discount), [addonsItems, discount])

  const foodmartTotal = useMemo(() => getTotal(foodmartItems, discount), [foodmartItems, discount])

  const subtotal = comboTotal.total + addonsTotal.total + foodmartTotal.total

  const taxTotal = comboTotal.taxTotal + addonsTotal.taxTotal + foodmartTotal.taxTotal

  const shippingFee = (area && +area.minSubtotal <= subtotal ? 0 : +shipping?.shipping || 0)

  const shippingTaxTotal = area?.warehouse?.taxable ? shippingFee * (taxTotal / subtotal) : 0

  const discountTotal = comboTotal.discountTotal
    + addonsTotal.discountTotal + foodmartTotal.discountTotal

  const tax = taxRate
    ? (
      comboTotal.taxTotal
      + addonsTotal.taxTotal
      + foodmartTotal.taxTotal
      + shippingTaxTotal
    ) * taxRate : 0

  return {
    comboItems,
    addonsItems,
    foodmartItems,
    subtotal,
    taxTotal,
    comboTotal,
    addonsTotal,
    foodmartTotal,
    shipping: shippingFee,
    taxRate: +taxRate || 0,
    shippingTaxTotal,
    tax,
    total: subtotal + shippingFee,
    discountTotal,
  }
}
