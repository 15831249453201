import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: props => ({
    color: theme.palette[props.color].main,
    padding: 0,
    transitionDuration: '0.3s',
    border: 0,
    background: 'transparent',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: theme.palette[props.color].light,
    },
  }),
}))

const TextButton = React.forwardRef(({
  color = 'success', children, ...rest
}, ref) => {
  const classes = useStyles({ color })
  return (
    <button
      ref={ref}
      className={classes.root}
      type="button"
      {...rest}
    >
      {children}
    </button>
  )
})

TextButton.displayName = 'TextButton'
export default TextButton
