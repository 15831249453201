import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { FormattedNumber } from 'react-intl'
import TextButton from '../../../../components/Buttons/TextButton'
import GreenTitle from '../../../../components/Titles/GreenTitle'

function Order({ order, onBack }) {
  const { t } = useTranslation()

  return (
    <div className="account-info">
      <section>
        <TextButton onClick={onBack}>
          &lt;&nbsp;
          {t('Return to account details')}
        </TextButton>
        <GreenTitle>{t('Shipping address')}</GreenTitle>
        <div className="account-content">
          <p>{`${t('Fulfillment status')}: ${order.status === 'complete' ? t('Shipped') : t(`Order - ${order.status}`)}`}</p>
          <p>{`${t('First name')}: ${order.customerFirstName}`}</p>
          <p>{`${t('Last name')}: ${order.customerLastName}`}</p>
          <p>{`${t('Address')}: ${order.deliveryGateCode || ''} ${order.deliveryApartment || ''} ${order.deliveryAddress}`}</p>
          <p>{`${order.deliveryCity}, ${order.deliveryZipCode}`}</p>
        </div>
      </section>
      <section>
        <GreenTitle>{`${t('Order')} #${order.number}`}</GreenTitle>
        <div className="account-content">
          <p>
            {
              t('Order Shipped on {date} at {startAt} {endAt}',
                {
                  date: format(new Date(order.deliveryStartAt), 'LLLL dd, yyyy'),
                  startAt: format(new Date(order.deliveryStartAt), 'mm:ss aa'),
                  endAt: format(new Date(order.deliveryEndAt), 'mm:ss aa'),
                })
            }
          </p>
        </div>
        <table className="order-table">
          <thead>
            <tr>
              <th>{t('Product')}</th>
              <th>{t('Price')}</th>
              <th>{t('Qty')}</th>
              <th>{t('Total')}</th>
            </tr>
          </thead>
          <tbody>
            {
              order.orderItems.map((item, i) => (
                <tr key={item.id} className={clsx({ 'table-border': i === 0, first: i === 0, last: i === order.orderItems.length - 1 })}>
                  <td className="fz-green">{item.productName}</td>
                  <td><FormattedNumber style="currency" value={item.specialPrice || item.price} currency="USD" /></td>
                  <td>{item.quantity}</td>
                  <td><FormattedNumber style="currency" value={item.quantity * +(item.specialPrice || item.price)} currency="USD" /></td>
                </tr>
              ))
            }
            {
              order.orderGifts.map((item, i) => (
                <tr key={item.id} className={clsx({ 'table-border': i === 0, first: i === 0, last: i === order.orderItems.length - 1 })}>
                  <td className="fz-green">{item.giftName}</td>
                  <td><FormattedNumber style="currency" value={0} currency="USD" /></td>
                  <td>{item.quantity}</td>
                  <td><FormattedNumber style="currency" value={0} currency="USD" /></td>
                </tr>
              ))
            }
            <tr className="table-border first">
              <td colSpan="3">{t('Subtotal')}</td>
              <td>
                <FormattedNumber style="currency" value={order.subtotal} currency="USD" />
              </td>
            </tr>
            <tr>
              <td colSpan="3">{t('Delivery fee (shipping)')}</td>
              <td>
                <FormattedNumber style="currency" value={order.shipping} currency="USD" />
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                {t('Tax (State Tax {value}%)', { value: (order.taxRate * 100).toFixed(2) })}
              </td>
              <td>
                <FormattedNumber style="currency" value={order.tax} currency="USD" />
              </td>
            </tr>
            <tr className="last">
              <td colSpan="3">{t('Discount')}</td>
              <td>
                <FormattedNumber style="currency" value={order.discount} currency="USD" />
              </td>
            </tr>
            <tr className="table-border first">
              <td colSpan="3">{t('Total')}</td>
              <td>
                <FormattedNumber style="currency" value={+order.subtotal + +order.shipping + +order.tax - +order.discount} currency="USD" />
              </td>
            </tr>

          </tbody>
        </table>
      </section>
    </div>
  )
}

export default Order
