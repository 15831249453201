import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '../../../../components/Dialog'
import { clearFailed, clearFailedCart } from '../../../../redux/action/orderAction'

const useStyles = makeStyles({
  dialog: {
    padding: '0 20px',
    maxWidth: 400,
    margin: '0 auto',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 28,
    '& button': {
      width: 150,
    },
  },
})

function ErrorProducts() {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [open, setOpen] = useState(false)
  const { failed } = useSelector(st => st.order)
  const classes = useStyles()
  const dp = useDispatch()
  useEffect(() => {
    if (failed.length) {
      setOpen(true)
    }
  }, [failed.length])

  const handleClose = () => {
    setOpen(false)
    dp(clearFailed())
  }

  const handleContinue = () => {
    setOpen(false)
    dp(clearFailedCart())
  }
  return (
    <Dialog
      open={open}
      maxWidth={500}
      fullWidth
      title={t('Item not available')}
      onClose={handleClose}
    >
      <div className={classes.dialog}>
        <div>
          <p>
            {t('Some items in your order cart are not currently available')}
            {t('To continue, unavailable item will be removed')}
          </p>
          {
            failed.map((item, i) => (
              <div key={i}>
                {(language === 'tw' && item.data.nameTw) || item.data.name}
              </div>
            ))
          }
        </div>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            disableElevation
            fullWidth
            onClick={handleClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={handleContinue}
          >
            {t('Continue')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default ErrorProducts
