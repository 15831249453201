import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useLocation, useHistory } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import ProductTitle from '../../../components/Titles/ProductTitle'
import Products from '../../../components/Products'
import ProductAction from '../../../components/Products/ProductAction'
import useCart from '../../../hooks/useCart'
import useOrder from '../../../hooks/useOrder'
import BundlePicker from '../../../components/BundlePicker'

import { PRODUCT_TYPE } from '../../../constant'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'white',
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 67,
    },
  },
  inner: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1330,
      margin: '0 auto',
    },
  },
}))

function AddOns() {
  const { t } = useTranslation()
  const [picked, setPicked] = useState({})
  const {
    addToCart, fetchAndCheck, availableProducts, isFetching,
  } = useCart()
  const [bundleProduct, setBundleProduct] = useState(null)
  const [bundleItems, setBundleItems] = useState(null)
  const { addonsItems } = useOrder()
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const isEdit = useMemo(
    () => location.search.indexOf('edit') !== -1,
    [location.search],
  )

  const handleBundlePlus = (items, product = bundleProduct) => {
    setPicked(st => ({
      ...st,
      [product.code]: {
        picked: 1,
        data: product,
        additional: items,
      },
    }))
    setBundleItems(null)
    setBundleProduct(null)
  }

  const handlePlus = product => {
    if (product.type === 'bundle' && !picked[product.code]?.picked) {
      if (product.isFixedBundle) {
        handleBundlePlus(product.comboItems.reduce((acc, cur) => ({
          ...acc,
          [cur.code]: {
            picked: cur.pivot.quantityMax,
            data: cur,
          },
        }), {}), product)
      } else {
        const cartItem = addonsItems.find(item => item.code === product.code)
        setBundleItems(cartItem?.additional || null)
        setBundleProduct(product)
      }
    } else {
      setPicked(st => ({
        ...st,
        [product.code]: {
          ...(st[product.code] || {}),
          picked: st[product.code] ? st[product.code].picked + 1 : 1,
          data: product,
        },
      }))
    }
  }

  const handleMinus = product => {
    setPicked(st => ({
      ...st,
      [product.code]: {
        ...(st[product.code] || {}),
        picked: st[product.code] ? st[product.code].picked - 1 : 0,
        data: product,
      },
    }))
  }

  const handleAddToCart = () => {
    if (isEdit) history.push('add-ons')
    addToCart(picked, 'addons', isEdit)
    setPicked({})
  }

  const handleUpdate = ev => {
    setBundleItems(picked[ev.code].additional)
    setBundleProduct(ev)
  }

  useEffect(() => {
    fetchAndCheck()
  }, [fetchAndCheck])

  useEffect(() => {
    if (isEdit) {
      const pickedItem = addonsItems.reduce((acc, item) => {
        const current = availableProducts.find(p => p.code === item.code)
        if (current) {
          return {
            ...acc,
            [item.code]: {
              ...item,
              data: current,
            },
          }
        }
        return acc
      }, {})
      setPicked(pickedItem)
    }
  }, [addonsItems, availableProducts, isEdit])

  const products = useMemo(
    () =>
      availableProducts.filter(
        d =>
          (d.type === PRODUCT_TYPE.ADDONS || d.subType === PRODUCT_TYPE.ADDONS)
          && d.available,
      ),
    [availableProducts],
  )

  return (
    <>
      <div className={classes.root}>
        <div className={classes.inner}>
          <ProductTitle title={t('Addons')} subTitle={t('Ready to cook')} />
          <Products
            products={products}
            picked={picked}
            multiple
            onPlus={handlePlus}
            onMinus={handleMinus}
            onUpdate={handleUpdate}
          />
        </div>
      </div>
      <ProductAction
        addText={isEdit ? t('Update') : t('Add to cart')}
        addons={picked}
        addToCart={handleAddToCart}
      />
      <BundlePicker
        open={!!bundleProduct}
        onClose={() => setBundleProduct(null)}
        pickedData={picked?.[bundleProduct?.code]}
        product={bundleProduct}
        defaultValue={bundleItems}
        onPickBundle={handleBundlePlus}
      />
      {isFetching && <LinearProgress />}
    </>
  )
}

export default AddOns
