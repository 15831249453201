import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

function LayoutNav({ className, onClick, user }) {
  const { t } = useTranslation()

  return (
    <nav className={className}>
      <NavLink to="/hot-pot" className="nav-link" onClick={onClick}>{t('Hot pot kit')}</NavLink>
      <NavLink to="/add-ons" className="nav-link" onClick={onClick}>{t('Addons')}</NavLink>
      <NavLink to="/food-mart" className="nav-link" onClick={onClick}>{t('Food mart')}</NavLink>
      <NavLink to="/about" className="nav-link" onClick={onClick}>{t('About')}</NavLink>
      {
        user
          ? <NavLink to="/account" className="nav-link nav-login" onClick={onClick}>{t('Account')}</NavLink>
          : <NavLink to="/login" className="nav-link nav-login" onClick={onClick}>{t('Login')}</NavLink>
      }
    </nav>
  )
}

export default LayoutNav
