import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Dialog from '../../../../components/Dialog'

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: 0,
    maxWidth: 500,
    margin: '0 auto',
    color: theme.palette.error.main,
    textAlign: 'center',
    '& p': {
      color: theme.palette.secondary.main,
    },
  },
}))

function ErrorMessages({ messages, setMessages }) {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (messages) {
      setOpen(true)
    }
  }, [messages])

  const handleClose = () => {
    setOpen(false)
    setMessages(null)
  }

  return (
    <Dialog
      open={open}
      maxWidth={588}
      fullWidth
      title={messages?.type === 'products' ? t('Item not available') : `${t('Sorry')}!`}
      onClose={handleClose}
    >
      <div className={classes.dialog}>
        <div>
          {
            messages?.type ? (
              <>
                {
                  messages?.type === 'validation' && Object.values(messages.errors).map((message, i) => (
                    <div key={i}>{message.join(', ')}</div>
                  ))
                }
                {
                  messages?.type === 'products' && Object.values(messages.errors).map((product, i) => (
                    <div key={i}>
                      <p>
                        {t('Some items in your order cart are not currently available')}
                        <br />
                        {t('To continue, unavailable item will be removed')}
                      </p>
                      {(language === 'tw' && product.nameTw) || product.name}
                    </div>
                  ))
                }
                {
                  messages?.type === 'message' && messages.error
                }
              </>
            ) : (messages?.message || '')
          }
        </div>
      </div>
    </Dialog>
  )
}

export default ErrorMessages
