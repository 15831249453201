/* eslint-disable react/no-danger */
import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import './SubBanner.scss'

function SubBanner({ data, device = 'mobile', classes }) {
  const { i18n } = useTranslation()
  const { language } = i18n

  const banner = {
    ...data,
    ...data[device],
  }
  return (
    <a
      href={banner.link}
      className={clsx(
        classes.banner,
        'prview-container',
        `type-${banner.type}`,
        banner.textHorizontal,
        banner.textVertical,
      )}
      style={{
        backgroundImage: `url(${banner.imageFullurl})`,
        backgroundPositionX: `url(${banner.bgPositionX})`,
        backgroundPositionY: `url(${banner.bgPositionY})`,
        backgroundSize: 'cover',
      }}
    >
      {
        !!banner.overlay && (
          <div
            className={classes.overlay}
            style={{
              background: banner.overlay,
            }}
          />
        )
      }
      <div className={classes.bannerInner}>
        {
          !!banner.title && (
            <div dangerouslySetInnerHTML={{ __html: (language === 'tw' && banner.titleTw) || banner.title }} />
          )
        }
        {
          !!banner.content && (
            <div dangerouslySetInnerHTML={{ __html: (language === 'tw' && banner.contentTw) || banner.content }} />
          )
        }
      </div>
    </a>
  )
}

export default SubBanner
