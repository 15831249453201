import React from 'react'

function LocationSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" {...props}>
      <path id="Icon_open-location" data-name="Icon open-location" d="M144.264,156.044l3-9,9-3-24-12Z" transform="translate(-131.264 -131.044)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>

  )
}

export default LocationSvg
