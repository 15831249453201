import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import DeliverySvg from '../../svg/DeliverySvg'
import Dialog from '../Dialog'
import Button from '../Buttons/Button'
import ZipcodeStep from './ZipcodeStep'
import ShippingStep from './ShippingStep'
import ShippingResult from './ShippingResult'
import { fetchAreas, selectArea } from '../../redux/action/areaAction'
import { selectShipMethod, checkCart } from '../../redux/action/orderAction'
import { checkProducts } from '../../redux/action/productAction'

const useStyles = makeStyles(theme => ({
  btn: {
    position: 'fixed',
    top: 215,
    left: 0,
    zIndex: 105,
    paddingLeft: 15,
    border: `1px solid ${theme.palette.primary.main}`,
    background: 'rgba(0,0,0,.4)',
    padding: 4,
    width: 180,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transform: 'translateX(-126px)',
    '&:hover': {
      transform: 'translateX(-1px)',
    },
  },
  text: {
    lineHeight: 1,
    color: 'white',
    height: '100%',
    textAlign: 'left',
    '& h6': {
      fontSize: 14,
      margin: 0,
      marginBottom: 3,
    },
    '& span': {
      fontSize: 16,
      fontFamily: 'FuturaStd-CondensedBold',
      textDecoration: 'underline',
    },

  },
  dialog: {
    padding: '0 20px',
    maxWidth: 583,
    margin: '0 auto',
    '& h6': {
      marginTop: 18,
      marginBottom: 10,
      fontSize: 16,
    },
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& > h6, & > span': {
      textAlign: 'center',
      display: 'block',
    },
  },
  note: {
    lineHeight: 1,
    color: theme.palette.error.main,
    marginBottom: 24,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 28,
    '& button': {
      width: 150,
      [theme.breakpoints.up('sm')]: {
        width: 200,
      },
      [theme.breakpoints.up('md')]: {
        width: 250,
      },
    },
  },
  mainBtn: {
    '&.MuiButton-root': {
      maxWidth: 368,
      width: '100%',
      margin: '0 auto',
    },
  },
  cancelBtn: {
    background: theme.palette.info.light,
    color: 'white',
    '&:hover': {
      background: theme.palette.info.main,
    },
  },
  shippingStep: {
    '& h5': {
      fontFamily: 'FuturaStd-CondensedBold',
      marginTop: 30,
      marginBottom: 20,
      fontSize: 16,
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
    '& .MuiFormControl-root': {
      marginTop: -10,
    },
  },
  zipCode: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #CCCCCC',
    paddingBottom: 30,
  },
  zipCodeEdit: {
    '&.error': {
      '& input': {
        border: `2px solid ${theme.palette.error.main}`,
        color: theme.palette.error.main,
      },
      '& > button': {
        color: theme.palette.error.main,
      },
    },
    '& $errorMsg': {
      fontSize: 16,
      margin: 0,
    },
  },
  zipCodeEditInput: {
    display: 'flex',
    alignItems: 'flex-start',
    '& input': {
      border: `2px solid ${theme.palette.secondary.main}`,
      padding: '17px 16px',
      height: 50,
      maxWidth: 430,
      marginRight: 15,
      flex: 1,
      fontSize: 16,
    },
    '& button': {
      border: 0,
      background: 'transparent',
      padding: 0,
      color: theme.palette.success.main,
      fontFamily: 'FuturaStd-bold',
      textDecoration: 'underline',
      fontSize: 14,
    },
  },
  deliveryDate: {
    fontSize: 13,
    '&:not(:last-child)': {
      marginBottom: 20,
    },
  },
  editBtn: {
    border: 0,
    background: 'transparent',
    color: theme.palette.success.main,
    marginLeft: 10,
  },
  shippingResult: {
    '& p': {
      margin: 0,
    },
    '& h5': {
      fontFamily: 'FuturaStd-CondensedBold',
      marginTop: 0,
      marginBottom: 20,
      fontSize: 16,
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
  },
  shippingResultGroup: {
    marginTop: 30,
    paddingBottom: 30,
    borderBottom: '1px solid #ccc',
  },
  resultEdit: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginRight: 15,
    },
    '& button': {
      background: 'transparent',
      border: 0,
      fontSize: 14,
      padding: 0,
      textDecoration: 'underline',
      fontFamily: 'FuturaStd-bold',
    },
  },
  errorMsg: {
    color: theme.palette.error.main,
    lineHeight: 1.33,
  },
}))

const METHOD_MAP = {
  ZIP_CODE: 'ZIP_CODE',
  SHIPPING_METHOD: 'SHIPPING_METHOD',
  SHIPPING_RESULT: 'SHIPPING_RESULT',
}
const SHOW_BTNS_PATHNAMES = ['/']
const PRODUCT_PAGES = ['/add-ons', '/hot-pot', '/food-mart']

function ZipcodeSelector() {
  const { t } = useTranslation()
  const classes = useStyles()
  const dp = useDispatch()
  const areas = useSelector(st => st.area.data)
  const { area } = useSelector(st => st.area)
  const { zipCode } = useSelector(st => st.order)
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)
  const [areaData, setAreaData] = useState(null)
  const [data, setData] = useState({})
  const [error, setError] = useState({})
  const [step, setStep] = useState(METHOD_MAP.ZIP_CODE)
  const cacheData = useRef(null)
  const history = useHistory()
  const [inComingZipCode, setInComingZipCode] = useState(false)

  const { search } = useLocation()
  const defaultZipcode = parseInt(queryString.parse(search).zipCode, 10)

  const handleChange = ev => {
    if (ev.target.name === 'shippingId') {
      setData(state => ({
        ...state,
        shippingId: +ev.target.value,
      }))
    } else {
      const currentArea = areas.find(d => d.zipCodes.some(code => {
        const value = +ev.target.value
        if (Array.isArray(code)) {
          return value >= code[0] && value <= code[1]
        }
        return value === code
      }))

      setAreaData(currentArea || null)
      setError(state => ({
        ...state,
        [ev.target.name]: currentArea || !ev.target.value
          ? null
          : true,
      }))

      setData(state => ({
        ...state,
        [ev.target.name]: +ev.target.value,
        shippingId: currentArea?.shippings[0]?.id || null,
      }))
    }
  }

  const handleCancel = () => {
    const currentArea = areas.find(d => d.zipCodes.some(code => {
      const value = +data.zipCode
      if (Array.isArray(code)) {
        return value >= code[0] && value <= code[1]
      }
      return value === code
    }))
    setAreaData(currentArea || null)

    setError(state => ({
      ...state,
      zipEditCode: null,
    }))
    setData(state => ({
      ...state,
      zipEditCode: '',
    }))
  }

  const goNext = () => {
    if (data.zipCodeEdit) {
      setData(state => ({
        ...state,
        zipCode: state.zipEditCode,
        zipEditCode: '',
      }))
    }
    if (step === METHOD_MAP.ZIP_CODE) setStep(METHOD_MAP.SHIPPING_METHOD)
    else if (step === METHOD_MAP.SHIPPING_METHOD) setStep(METHOD_MAP.SHIPPING_RESULT)
  }

  const startOrder = () => {
    if (areaData) {
      dp(selectArea({
        id: areaData.id,
        code: areaData.code,
        name: areaData.name,
        nameTw: areaData.nameTw,
        minSubtotal: areaData.minSubtotal,
        shippings: areaData.shippings,
      }))
      dp(selectShipMethod(
        areaData.shippings.find(shipping => shipping.id === data.shippingId) || null,
        data.zipEditCode || data.zipCode,
      ))
      dp(checkProducts())
      dp(checkCart())
      setData({
        ...data,
        zipCode: data.zipEditCode || data.zipCode,
        zipEditCode: '',
      })
      cacheData.current = {
        areaData,
      }
      setOpen(false)
    }

    if (SHOW_BTNS_PATHNAMES.includes(pathname)) {
      history.push('/hot-pot')
    }
  }

  useEffect(() => {
    const currentArea = areas.find(d => d.zipCodes.some(code => {
      const value = +defaultZipcode
      if (Array.isArray(code)) {
        return value >= code[0] && value <= code[1]
      }
      return value === code
    }))
    if (currentArea) {
      setInComingZipCode(true)
      setAreaData(currentArea || null)
      setData(state => ({
        ...state,
        zipCode: +defaultZipcode,
        shippingId: currentArea?.shippings[0]?.id || null,
      }))
      if (step === METHOD_MAP.ZIP_CODE && defaultZipcode) setStep(METHOD_MAP.SHIPPING_METHOD)
    }
  }, [areas, step, defaultZipcode])

  useEffect(() => {
    if (inComingZipCode) {
      dp(selectShipMethod(
        areaData.shippings.find(shipping => shipping.id === data.shippingId) || null,
        data.zipEditCode || data.zipCode,
      ))
      dp(checkProducts())
      dp(checkCart())
      cacheData.current = {
        areaData,
      }
    }
  }, [areaData, data.shippingId, data.zipCode, data.zipEditCode, dp, inComingZipCode])

  useEffect(() => {
    function fetchData() {
      dp(fetchAreas())
    }

    fetchData()
  }, [dp])

  useEffect(() => {
    if (PRODUCT_PAGES.includes(pathname) && !area) {
      setOpen(true)
    }
  }, [area, pathname])

  useEffect(() => {
    if (open && cacheData.current) {
      setAreaData(cacheData.current.areaData)
    }
  }, [open])

  useEffect(() => {
    if (zipCode) {
      setData(st => ({
        ...st,
        zipCode,
        zipCodeEdit: '',
      }))
    }
  }, [zipCode])

  return pathname.includes('checkout') || pathname.includes('payment') ? null : (
    <>
      <button id="location-btn" className={classes.btn} type="button" onClick={() => setOpen(true)}>
        <div className={classes.text}>
          <h6 className="text-uppercase">{t('Delivery city')}</h6>
          <span>{defaultZipcode || data?.zipCode || t('Enter zip code')}</span>
        </div>
        <DeliverySvg />
      </button>
      <Dialog
        open={open}
        maxWidth={754}
        fullWidth
        title={step === METHOD_MAP.SHIPPING_RESULT ? t('Did we miss anything') : t('Enter delivery zip code')}
        onClose={() => setOpen(false)}
      >
        <div className={classes.dialog}>
          {step === METHOD_MAP.ZIP_CODE && (
            <ZipcodeStep
              classes={classes}
              error={error.zipCode}
              value={data.zipCode}
              onChange={handleChange}
            />
          )}
          {step === METHOD_MAP.SHIPPING_METHOD && (
            <ShippingStep
              classes={classes}
              zipCode={defaultZipcode || data.zipCode}
              zipEditCode={data.zipEditCode}
              shippings={areaData?.shippings}
              shippingId={data.shippingId}
              error={error.zipEditCode}
              onChange={handleChange}
              onCancel={handleCancel}
            />
          )}
          {step === METHOD_MAP.SHIPPING_RESULT && (
            <ShippingResult
              classes={classes}
              zipCode={defaultZipcode || data.zipCode}
              zipEditCode={data.zipEditCode}
              shippings={areaData?.shippings}
              shippingId={data.shippingId}
              onEdit={() => setStep(METHOD_MAP.SHIPPING_METHOD)}
            />
          )}
          <div className={classes.buttons}>
            {step === METHOD_MAP.ZIP_CODE && (
              <Button className={classes.cancelBtn} variant="contained" disableElevation onClick={() => setOpen(false)}>{t('No thanks')}</Button>
            )}
            {step === METHOD_MAP.SHIPPING_RESULT ? (
              <Button
                className={classes.mainBtn}
                variant="contained"
                color="secondary"
                disableElevation
                disabled={!data.shippingId}
                onClick={startOrder}
              >
                {t('Start your order')}
              </Button>
            ) : (
              <Button
                className={clsx({ [classes.mainBtn]: step !== METHOD_MAP.ZIP_CODE })}
                variant="contained"
                color="secondary"
                disableElevation
                onClick={goNext}
                disabled={(defaultZipcode) ? false : !!error.zipCode
                || !data.zipCode || !data.shippingId}
              >
                {t('Continue')}
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default ZipcodeSelector
