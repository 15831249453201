import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LogoSvg from '../../svg/LogoSvg'
import Drawer from '../Drawer'
import Cart from '../Cart'
import HamburgerSvg from '../../svg/HamburgerSvg'
import LogoColorSvg from '../../svg/LogoColorSvg'
import UserSvg from '../../svg/UserSvg'
import useIsDesktop from '../../utils/reactHooks/useIsDesktop'
import LayoutNav from './LayoutNav'

function LayoutHeader() {
  const [open, setOpen] = useState(false)
  const isDesktop = useIsDesktop()
  const { i18n } = useTranslation()
  const user = useSelector(st => st.user.data)
  const handleLangClick = lang => {
    i18n.changeLanguage(lang)
  }
  return (
    <>
      <header id="main-header" className="header">
        <div className="header-link">
          <a
            href="https://www.bpgroupusa.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            Boiling Point USA
          </a>
          <div className="header-lang">
            <button type="button" onClick={() => handleLangClick('en')}>
              EN
            </button>
            <button type="button" onClick={() => handleLangClick('tw')}>
              中文
            </button>
          </div>
        </div>
        <div className="header-inner">
          <button
            className="header-menu"
            type="button"
            onClick={() => setOpen(true)}
          >
            <HamburgerSvg />
          </button>
          <div className="header-func">
            {user ? (
              <Link to="/account" className="header-login">
                <UserSvg />
              </Link>
            ) : (
              <Link to="/login" className="header-login">
                <UserSvg />
              </Link>
            )}
            <Cart />
          </div>
          <Link to="/" className="header-logo">
            <LogoSvg />
          </Link>
          {isDesktop && (
            <LayoutNav
              className="desktop-nav"
              onClick={() => setOpen(false)}
              user={user}
            />
          )}
        </div>
      </header>
      {!isDesktop && (
        <Drawer
          className="header-drawer"
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
        >
          <LayoutNav
            className="header-nav"
            onClick={() => setOpen(false)}
            user={user}
          />
          <LogoColorSvg className="header-color-logo" />
        </Drawer>
      )}
    </>
  )
}

export default LayoutHeader
