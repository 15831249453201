import Button from '@material-ui/core/Button'
import { withTheme } from '@material-ui/core/styles'
import styled from 'styled-components'

const CustomButton = withTheme(styled(Button)`
  ${({ theme }) => `
  &.MuiButton-root {
    height: 50px;
    font-weight: bold;
    border-radius: 0;
    font-family: FuturaStd-Bold;
  },
  &.MuiButton-outlinedPrimary {
    border-color: ${theme.palette.primary.main}
  }
  &.MuiButton-outlinedSecondary {
    border-color: ${theme.palette.secondary.main}
  }
  `}
`)

export default CustomButton
