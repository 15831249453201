import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import IconButton from '@material-ui/core/IconButton'
import ProductPrice from '../ProductPrice'

const useStyles = makeStyles(theme => ({
  root: {
    userSelect: 'none',
  },
  btn: {
    width: '100%',
    background: 'transparent',
    border: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.info.main,
    height: '100%',
    '&:not(:disabled):hover': {
      '& img': {
        opacity: 0.6,
      },
    },
    '&:not(.is-picked):not(.is-view):disabled': {
      opacity: 0.3,
    },
    '& h2': {
      fontSize: 24,
      lineHeight: '34px',
      margin: 0,
      marginTop: 30,
      fontFamily: 'FuturaStd-Medium',
    },
    '& p': {
      fontSize: 16,
      lineHeight: '28px',
      margin: '0 auto 10px',
      marginTop: 14,
      maxWidth: 280,
    },
  },
  imageWrap: {
    flex: '0 0 366px',
    width: '100%',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  circle: {
    position: 'absolute',
    width: 50,
    height: 50,
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.error.main,
    color: 'white',
    borderRadius: 100,
    border: '2px solid white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 1,
    '& svg': {
      width: 30,
      height: 30,
    },
  },
  func: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& $circle': {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      transform: 'unset',
      fontFamily: 'FuturaStd-Bold',
      fontSize: 24,
      lineHeight: 1,
    },
    '& .MuiButtonBase-root': {
      width: 28,
      height: 28,
      border: '1px solid #ccc',
      margin: '0 7px',
      boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
      color: theme.palette.secondary.main,
      background: '#FFFFFF',
      '&:hover': {
        background: '#FFFFFF',
      },
    },
  },
  price: {
    marginTop: 'auto',
    '& span': {
      fontFamily: 'FuturaStd-Bold',
      fontSize: 24,
      lineHeight: '32px',
      '&:not(:last-child)': {
        color: theme.palette.error.main,
        marginRight: 16,
      },
      '& + span': {
        fontWeight: 400,
        textDecoration: 'line-through',
        fontFamily: 'FuturaStd-Book',
      },
    },
  },
  addPrice: {
    color: theme.palette.error.main,
  },
  restock: {
    marginTop: 10,
    fontTransform: 'uppercase',
    fontSize: 24,
    lineHeight: '32px',
  },
  soldout: {
    marginTop: 'auto',
    color: theme.palette.error.main,
    fontFamily: 'FuturaStd-Bold',
    fontSize: 24,
    lineHeight: '32px',
  },
  notAvailable: {
    fontSize: 14,
  },
}))
function ProductCard({
  image,
  name,
  desc,
  comboPrice,
  price,
  type,
  specialPrice,
  quantity,
  areaActive,
  restockAt,
  picked,
  multiple = false,
  onlyView = false,
  disabled,
  additional,
  isFixedBundle,
  onPlus = () => {},
  onMinus = () => {},
  onUpdate = () => {},
}) {
  const classes = useStyles()
  const addPrices = additional
    ? Object.values(additional)
      .filter(add => add.picked)
      .reduce((acc, cur) => acc + +cur.data.pivot.addPrice * cur.picked, 0) : 0
  return (
    <div>
      <button
        type="button"
        className={clsx(classes.btn, { 'is-picked': !!picked, 'is-view': onlyView })}
        disabled={
          !quantity || (!!picked && !additional) || !+price || !areaActive || disabled
        }
        onClick={ev => (additional && !isFixedBundle ? onUpdate() : onPlus(ev))}
      >
        <div className={classes.imageWrap}>
          <div className={classes.image} style={{ backgroundImage: `url(${image})` }} />
          {
            !!picked && (
              <>
                {
                  multiple ? (
                    <div className={classes.func}>
                      <IconButton component="div" onClick={onMinus}>
                        <RemoveOutlinedIcon />
                      </IconButton>
                      <div className={classes.circle}>
                        {picked}
                      </div>
                      <IconButton component="div" onClick={onPlus}>
                        <AddOutlinedIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <div className={classes.circle}>
                      <CheckOutlinedIcon />
                    </div>
                  )
                }
              </>
            )
          }
        </div>
        <h2>{name}</h2>
        <p>{desc}</p>
        <ProductPrice
          areaActive={areaActive}
          type={type}
          classes={classes}
          price={price}
          specialPrice={specialPrice}
          comboPrice={comboPrice}
          addPrices={addPrices}
          restockAt={restockAt}
          quantity={quantity}
        />
      </button>
    </div>
  )
}

export default ProductCard
