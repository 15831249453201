import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import useIsBreakpoint from '../../../utils/reactHooks/useIsBreakpoint'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      maxWidth: 1300,
      margin: '0 auto',
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '50px 0',
    },
  },
  banner: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: 'calc(50% - 10px)',
      flex: '0 0 calc(50% - 10px)',
    },
    '& + &': {
      marginTop: 20,
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: 20,
      },
    },
  },
  bannerInner: {
    position: 'relative',
  },
  overlay: {
    zIndex: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}))

function SubBannerWrap({ children }) {
  const classes = useStyles()
  const breakpoints = useIsBreakpoint()

  let device = 'desktop'
  if (breakpoints === 'xs') device = 'mobile'
  else if (breakpoints === 'md' || breakpoints === 'sm') device = 'tablet'

  return (
    <div className={clsx(classes.root)}>
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return null
        }
        return React.cloneElement(child, {
          ...child.props,
          classes,
          device,
        })
      })}
    </div>
  )
}

export default SubBannerWrap
