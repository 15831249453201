import types from '../actionTypes/areaTypes'
import apis from '../../apis'

const fetchAreaRequest = () => ({
  type: types.FETCH_REQUEST,
})

const fetchAreaFailure = error => ({
  type: types.FETCH_FAILURE,
  error,
})

const fetchAreaSuccess = areas => ({
  type: types.FETCH_SUCCESS,
  areas,
})

// 取得全部地區資料
export const fetchAreas = () => async dispatch => {
  dispatch(fetchAreaRequest())
  try {
    const res = await apis.area.getAreas()
    dispatch(fetchAreaSuccess(res.data.data))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    dispatch(fetchAreaFailure(err))
  }
}

export const selectArea = area => ({
  type: types.SELECT_AREA,
  area,
})
