import { combineReducers } from 'redux'
import area from './area'
import common from './common'
import order from './order'
import page from './page'
import product from './product'
import user from './user'
import banner from './banner'

export default combineReducers({
  area,
  common,
  order,
  product,
  page,
  user,
  banner,
})
