import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1200,
      xl: 1920,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
        body: {
          fontFamily: [
            '"FuturaStd-Book"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: '#ccc',
        },
      },
    },
    MuiFormControl: {
      root: {
        '& + &': {
          marginTop: 40,
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        fontFamily: 'FuturaStd-bold',
      },
    },
  },
  palette: {
    primary: {
      light: '#e56b5e',
      main: '#DF4636',
      dark: '#9c3125',
    },
    secondary: {
      light: '#786051',
      main: '#573926',
      dark: '#3c271a',
    },
    info: {
      light: '#707070',
      main: '#333333',
      dark: '#5b5b5b',
    },
    error: {
      light: '#e36a5d',
      main: '#DC4535',
      dark: '#9a3025',
    },
    success: {
      light: '#9cb65e',
      main: '#84A436',
      dark: '#5c7225',
    },
    warning: {
      light: '#ffc933',
      main: '#ffbc01',
      dark: '#b28300',
    },
  },
  typography: {
    fontFamily: [
      '"FuturaStd-Book"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})

export default theme
