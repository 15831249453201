import React, { useEffect, useState, useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import GreenTitle from '../../../components/Titles/GreenTitle'
import { fetchAreas, selectArea } from '../../../redux/action/areaAction'
import { updateZipCode, checkCart, updateTaxRate } from '../../../redux/action/orderAction'
import { checkProducts } from '../../../redux/action/productAction'
import Dialog from '../../../components/Dialog'
import apis from '../../../apis'

const AddressBookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path id="Address_Book" data-name="Address Book" d="M158.264,91.284h-15a3,3,0,0,0-3,3v3h-2.249a.75.75,0,1,0,0,1.5h2.249v3.749h-2.249a.75.75,0,1,0,0,1.5h2.249v3.751h-2.249a.75.75,0,1,0,0,1.5h2.249v3a3,3,0,0,0,3,3h15a3,3,0,0,0,3-3v-18A3,3,0,0,0,158.264,91.284Zm1.5,21a1.5,1.5,0,0,1-1.5,1.5h-15a1.5,1.5,0,0,1-1.5-1.5v-18a1.5,1.5,0,0,1,1.5-1.5h15a1.5,1.5,0,0,1,1.5,1.5Zm-9-9a3,3,0,1,0-3-3A3,3,0,0,0,150.764,103.284Zm0-4.5a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,150.764,98.784Zm3,6h-6a3,3,0,0,0-3,3v1.5a1.5,1.5,0,0,0,1.5,1.5h9a1.5,1.5,0,0,0,1.5-1.5v-1.5A3,3,0,0,0,153.764,104.784Zm1.5,4.5h-9v-1.5a1.5,1.5,0,0,1,1.5-1.5h6a1.5,1.5,0,0,1,1.5,1.5Z" transform="translate(-137.264 -91.284)" fill="#84a436" />
  </svg>

)
function DeliveryInfo({
  register,
  setError,
  clearErrors,
  setValue,
  control,
  errors,
  address,
  apartment,
  city,
  state,
  company,
  user,
  areas,
  zipCode: zipCodeInput,
}) {
  const { t } = useTranslation()
  const { stateOptions } = useSelector(st => st.common)
  const { zipCode } = useSelector(st => st.order)
  const [addressBooks, setAddressBooks] = useState([])
  const [shrinkData, setShrinkData] = useState({})
  const [open, setOpen] = useState(false)
  const dp = useDispatch()
  const checkZipCodes = useCallback(value => {
    if (value) {
      const currentArea = areas.find(d => d.zipCodes.some(code => {
        if (Array.isArray(code)) {
          return value >= code[0] && value <= code[1]
        }
        return value === code
      }))
      dp(selectArea(currentArea || null))
      dp(updateZipCode(value))
      dp(checkProducts())
      dp(checkCart())
    }
  }, [areas, dp])

  useEffect(() => {
    function fetchData() {
      dp(fetchAreas())
    }

    fetchData()
  }, [dp])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await apis.address.getAddressBooks()
        setAddressBooks(res.data.data)
      } catch (err) {
        setAddressBooks([])
      }
    }
    if (user) {
      fetchData()
    }
  }, [dp, setError, user])

  useEffect(() => {
    if (zipCodeInput) {
      checkZipCodes(+zipCodeInput)
    }
  }, [checkZipCodes, zipCodeInput])

  useEffect(() => {
    if (state && zipCode) {
      dp(updateTaxRate(state, zipCode))
    }
  }, [dp, state, zipCode])

  const updateDelivery = ev => {
    if (ev.firstName) setValue('firstName', ev.firstName)
    if (ev.lastName) setValue('lastName', ev.lastName)
    if (ev.state) setValue('state', ev.state)
    if (ev.city) setValue('city', ev.city)
    if (ev.apartment) setValue('apartment', ev.apartment)
    if (ev.address) setValue('address', ev.address)
    if (ev.company) setValue('company', ev.company)
    if (ev.zipCode) setValue('zipCode', ev.zipCode)
    if (ev.phone) setValue('phone', ev.phone)
    setShrinkData(ev)
    setOpen(false)
    checkZipCodes(zipCode ? +ev.zipCode : 0)
    clearErrors()
  }

  return (
    <section>
      <GreenTitle>
        {t('Delivery info')}
      </GreenTitle>
      <div className="checkout-content">
        <div>
          <div className="address-book-input">
            <TextField
              label={t('Address')}
              placeholder={t('Address')}
              name="address"
              fullWidth
              defaultValue=""
              error={!!errors.address}
              helperText={errors.address?.message}
              InputLabelProps={{ shrink: !!shrinkData.address || !!address }}
              inputRef={register}
            />
            {
              addressBooks.length > 0 && (
                <IconButton onClick={() => setOpen(true)}>
                  <AddressBookIcon />
                </IconButton>
              )
            }
          </div>
          <TextField
            label={t('Apartment, suite, etc (optional)')}
            placeholder={t('Apartment, suite, etc (optional)')}
            name="apartment"
            fullWidth
            inputRef={register}
            InputLabelProps={{ shrink: !!shrinkData.apartment || !!apartment }}
          />
          <Controller
            name="city"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={props => (
              <TextField
                label={t('City')}
                placeholder={t('City')}
                name="city"
                fullWidth
                error={!!errors.city}
                helperText={errors.city?.message}
                inputRef={props.ref}
                InputLabelProps={{ shrink: !!shrinkData.city || !!city }}
                onChange={ev => props.onChange(ev.target.value)}
                value={props.value}
              />
            )}
          />
          <Controller
            name="state"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={props => (
              <TextField
                label={t('State')}
                placeholder={t('State')}
                name="state"
                fullWidth
                error={!!errors.state}
                helperText={errors.state?.message}
                inputRef={props.ref}
                select
                onChange={ev => props.onChange(ev.target.value)}
                value={props.value}
              >
                <MenuItem value="" disabled>
                  {t('State')}
                </MenuItem>
                {stateOptions?.map(option => (
                  <MenuItem key={option.state} value={option.state}>
                    {option.state}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="zipCode"
            control={control}
            defaultValue={zipCode || ''}
            render={({ onChange, value }) => (
              <TextField
                label={t('Zip code')}
                placeholder={t('Zip code')}
                name="zipCode"
                fullWidth
                value={value || ''}
                error={!!errors.zipCode}
                helperText={errors.zipCode?.message ? t(errors.zipCode?.message) : ''}
                onChange={ev => onChange(ev.target.value)}
              />
            )}
          />
          <TextField
            label={t('Company (optional)')}
            placeholder={t('Company (optional)')}
            name="company"
            fullWidth
            inputRef={register}
            InputLabelProps={{ shrink: !!shrinkData.company || !!company }}
          />
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={props => (
              <TextField
                label={t('Phone number')}
                placeholder={t('Phone Number 999-999-9999')}
                name="phone"
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone?.message}
                inputRef={props.ref}
                onChange={e => {
                  const { value } = e.target
                  if (value) {
                    const v = value.replace(/[^0-9]/g, '')
                    if (v.length < 4) {
                      props.onChange(value)
                    } else if (v.length < 7) {
                      props.onChange(v.replace(/(\d{3})(\d{0,3})/, '$1-$2'))
                    } else {
                      props.onChange(v.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3'))
                    }
                  } else {
                    props.onChange(value)
                  }
                }}
                value={props.value}
              />
            )}
          />
        </div>
      </div>
      <Dialog
        open={open}
        maxWidth={588}
        fullWidth
        title={t('Address book')}
        onClose={() => setOpen(false)}
      >
        <div className="address-edit-dialog address-select-dialog">
          <div className="account-form account-container">
            <span className="text-center account-note">{t('Select the address you would like to use for this order')}</span>
            <div>
              {addressBooks.map((addressBook, i) => (
                <div key={addressBook.id} className="address-select-card">
                  <div>
                    <p>{`${t('Address')}: ${i + 1}`}</p>
                    <p>{`${t('Name')}: ${addressBook.firstName || ''} ${addressBook.lastName || ''}`}</p>
                    <p>{`${t('Email')}: ${addressBook.email || ''}`}</p>
                    <p>{`${t('Address')}: ${addressBook.address || ''}`}</p>
                    <p>{`${t('Apartment')}: ${addressBook.apartment || ''}`}</p>
                    <p>{`${addressBook.city || ''}, ${addressBook.state || ''} ${addressBook.zipCode || ''}`}</p>
                  </div>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => updateDelivery(addressBook)}
                  >
                    {t('Use address')}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Dialog>
    </section>
  )
}

export default DeliveryInfo
