import React, { useState } from 'react'
import clsx from 'clsx'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Radio from '../Inputs/Radio'
import EditSvg from '../../svg/EditSvg'
import LocationSelector from '../LocationSelector'

function ShippingStep({
  classes, error, shippings = [], zipCode, shippingId, zipEditCode, onChange, onCancel,
}) {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [showZipcode, setShowZipcode] = useState(false)

  const handleCancel = () => {
    setShowZipcode(false)
    if (onCancel) onCancel()
  }

  const handleLocationClick = ev => {
    if (ev) {
      onChange({
        target: {
          value: ev,
          name: 'zipEditCode',
        },
      })
    }
  }

  return (
    <div className={classes.shippingStep}>
      <h5 className="text-uppercase">
        {t('Delivery to')}
        :
      </h5>
      {
        showZipcode ? (
          <div className={clsx(classes.zipCodeEdit, { error: !!error })}>
            <div className={classes.zipCodeEditInput}>
              <input placeholder={t('Enter your zip code')} name="zipEditCode" value={zipEditCode || ''} onChange={onChange} type="number" />
              <button type="button" onClick={handleCancel}>{t('Cancel')}</button>
            </div>
            <LocationSelector onClick={handleLocationClick} />
            {
              !!error && (
                <p className={classes.errorMsg}>
                  {t('We are sorry we currently do not deliver to this area')}
                  <br />
                  {t('Please enter valid zip code and try again')}
                </p>
              )
            }
          </div>
        ) : (
          <div className={classes.zipCode}>
            <span>{zipCode}</span>
            <button type="button" className={classes.editBtn} onClick={() => setShowZipcode(true)}>
              <EditSvg />
            </button>
          </div>
        )
      }
      <h5>{t('Arrival date')}</h5>
      {
        !!shippings.length && (
          <div className={classes.methodSelect}>
            <FormControl component="fieldset">
              <RadioGroup value={shippingId || ''} name="shippingId" onChange={onChange}>
                {
                  shippings.map(shipping => (
                    <React.Fragment key={shipping.id}>
                      <FormControlLabel
                        value={shipping.id}
                        control={<Radio />}
                        label={(language === 'tw' && shipping.nameTw) || shipping.name}
                      />
                      <span className={classes.deliveryDate}>
                        {`Delivery Date: ${format(new Date(shipping.arrivalStartDateTime), 'h:mm a')} - ${format(new Date(shipping.endDateTime), 'h:mm a')} ${format(new Date(shipping.arrivalStartDateTime), 'MM/dd/yy')}`}
                      </span>
                    </React.Fragment>
                  ))
                }
              </RadioGroup>
            </FormControl>
          </div>
        )
      }
    </div>
  )
}

export default ShippingStep
