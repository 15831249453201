import React from 'react'

function YoutubeSvg(props) {
  return (
    <svg id="YouTube" xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" {...props}>
      <g id="YouTube_2" data-name="YouTube 2">
        <path id="Rectangle" d="M32.5,0h0A32.5,32.5,0,0,1,65,32.5h0A32.5,32.5,0,0,1,32.5,65h0A32.5,32.5,0,0,1,0,32.5H0A32.5,32.5,0,0,1,32.5,0Z" fill="red" />
        <path id="Exclude" d="M45.208,14.367a5.09,5.09,0,0,1,3.58,3.58c.871,3.18.837,9.806.837,9.806s0,6.592-.837,9.77a5.091,5.091,0,0,1-3.58,3.582c-3.18.836-15.9.836-15.9.836s-12.683,0-15.9-.869a5.09,5.09,0,0,1-3.58-3.58C9,34.345,9,27.719,9,27.719s0-6.592.837-9.772a5.2,5.2,0,0,1,3.58-3.614c3.178-.836,15.9-.836,15.9-.836S42.028,13.5,45.208,14.367ZM35.838,27.719l-10.575,6.09V21.628Z" transform="translate(3.187 4.781)" fill="#fff" fillRule="evenodd" />
      </g>
    </svg>
  )
}

export default YoutubeSvg
