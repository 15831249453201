import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Youtube from '../../Youtube'
import PlaySvg from '../../../svg/PlaySvg'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
    marginTop: -20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 1300,
      margin: '-20px auto 0',
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0,
      paddingBottom: 50,
    },
  },
  banner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 320,
    color: 'white',
    textDecoration: 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    [theme.breakpoints.up('md')]: {
      width: 'calc(50% - 10px)',
      '&:last-child': {
        width: '100%',
      },
    },
    [theme.breakpoints.up('lg')]: {
      height: 420,
      width: 'calc(33% - 10px)',
      '&:last-child': {
        width: 'calc(33% - 10px)',
      },
    },
    '& h5': {
      fontFamily: 'FuturaStd-CondensedBold',
      fontSize: 36,
      lineHeight: '42px',
      margin: 0,
      marginBottom: 18,
      [theme.breakpoints.up('md')]: {
        fontSize: 48,
        lineHeight: '60px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 80,
        lineHeight: 1,
        marginBottom: 16,
      },
    },
    '& p': {
      fontSize: 14,
      lineHeight: '20px',
      maxWidth: 280,
      margin: '0 auto',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        fontSize: 18,
        lineHeight: '30px',
      },
    },
    '& button': {
      border: 0,
      background: 'transparent',
    },
    '& + &': {
      marginTop: 20,
      [theme.breakpoints.up('md')]: {
        '&:nth-child(2)': {
          marginLeft: 20,
        },
        '&:not(:last-child)': {
          marginTop: 0,
        },
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 20,
        marginTop: 0,
      },
    },
  },
}))

function StaticBanner() {
  const { t } = useTranslation()
  const [openVideo, setOpenVideo] = useState(false)
  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        <Link
          to="/add-ons"
          className={classes.banner}
          style={{ backgroundImage: `url(${`${process.env.REACT_APP_URL}/img/BannerSub_1.png`})` }}
        >
          <h5 className="text-uppercase">{t('Addons')}</h5>
          <p>
            {t('addons_desc')}
          </p>
        </Link>
        <Link
          to="/food-mart"
          className={classes.banner}
          style={{ backgroundImage: `url(${`${process.env.REACT_APP_URL}/img/BannerSub_2.png`})` }}
        >
          <h5 className="text-uppercase">{t('Food mart')}</h5>
          <p>
            {t('food_mart_desc')}
          </p>
        </Link>
        <div
          to="/"
          className={classes.banner}
          style={{ backgroundImage: `url(${`${process.env.REACT_APP_URL}/img/BannerSub_3.png`})` }}
        >
          <button type="button" onClick={() => setOpenVideo(true)}>
            <PlaySvg />
          </button>
        </div>
      </div>
      <Youtube videoId="CWV1UM_lhUc" show={openVideo} onClose={() => setOpenVideo(false)} />
    </>
  )
}

export default StaticBanner
