import React from 'react'

function HamburgerSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" aria-labelledby="menu" role="presentation" {...props}>
      <title id="menu" lang="en">menu icon</title>
      <g id="Hamburger_Menu" transform="translate(1 1)">
        <line id="Line_2" data-name="Line 2" x2="22" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" />
        <line id="Line_3" data-name="Line 3" x2="22" transform="translate(0 7)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" />
        <line id="Line_4" data-name="Line 4" x2="11" transform="translate(0 14)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" />
      </g>
    </svg>
  )
}

export default HamburgerSvg
