import React from 'react'
import { useTranslation } from 'react-i18next'
import SearchInput from '../Inputs/SearchInput'
import LocationSelector from '../LocationSelector'

function ZipcodeStep({
  classes, value, onChange, error,
}) {
  const { t } = useTranslation()

  const handleLocationClick = ev => {
    if (ev) {
      onChange({
        target: {
          value: ev,
          name: 'zipCode',
        },
      })
    }
  }
  return (
    <>
      <span className={classes.note}>
        {t('Products and prices are subject to change based on zip code')}
      </span>
      <SearchInput
        label={t('Enter your zip code')}
        variant="filled"
        name="zipCode"
        type="number"
        value={value || ''}
        error={!!error}
        onChange={onChange}
      />
      {
        error ? (
          <p className={classes.errorMsg}>
            {t('We are sorry we currently do not deliver to this area')}
            <br />
            {t('Please enter valid zip code and try again')}
          </p>
        ) : <LocationSelector onClick={handleLocationClick} />
      }
    </>
  )
}

export default ZipcodeStep
