import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import TextButton from '../Buttons/TextButton'

const useStyles = makeStyles(theme => ({
  confirmBtn: {
    '& button': {
      textDecoration: 'underline',
      display: 'inline',
      fontSize: 16,
    },
  },
  term: {
    padding: 20,
    paddingBottom: 0,
    color: theme.palette.secondary.main,
    '& button': {
      maxWidth: 360,
      height: 48,
      margin: '0 auto',
      marginTop: 20,
      display: 'flex',
    },
  },
}))

const ConfirmTerms = ({ classes, onClick, t }) => (
  <div className={classes.confirmBtn}>
    <span>
      {t('I have read and agree to the')}
      &nbsp;
    </span>
    <TextButton onClick={() => onClick('/terms')}>{t('Terms of use')}</TextButton>
    <span>
      &nbsp;
      {t('And')}
      &nbsp;
    </span>
    <TextButton onClick={() => onClick('/policy')}>{t('Privacy policy')}</TextButton>
  </div>
)

function TermsCheck({ register, error }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const goPage = path => {
    window.open(`${window.location.protocol}//${window.location.host}${path}`)
  }
  return (
    <>
      <FormControlLabel
        style={{ marginTop: 10 }}
        control={<Checkbox name="termCheck" inputRef={register} />}
        label={<ConfirmTerms t={t} classes={classes} onClick={goPage} />}
      />
      { !!error && (
        <FormHelperText error style={{ marginTop: -5 }}>
          {error}
        </FormHelperText>
      )}
    </>
  )
}

export default TermsCheck
