import React from 'react'

function LogoColorSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="184.313" height="36.002" viewBox="0 0 184.313 36.002" data-livestyle-extension="available" {...props}>
      <g id="Group_139" data-name="Group 139" transform="translate(-22.379 -65.188)">
        <path id="Path_59" data-name="Path 59" d="M36.77,82.251c2.977-1.191,4.268-4.416,4.268-7.493,0-6.1-2.928-9.477-9.528-9.477H22.379v35.829H32.1c7,0,9.628-4.465,9.628-10.372C41.733,86.122,39.9,83.641,36.77,82.251Zm-8.237-11.91h2.331c2.73,0,4.021,1.737,4.021,5.063,0,2.977-1.786,4.565-4.119,4.565H28.533Zm2.579,25.705H28.533V85.03h1.984c3.672,0,5.063,1.837,5.063,5.509C35.579,94.162,34.389,96.046,31.112,96.046Z" transform="translate(0 0.081)" fill="currentColor" />
        <path id="Path_60" data-name="Path 60" d="M43,65.28H34.168v35.829h6.154V86.42H43c7.591,0,11.115-2.928,11.115-10.57S50.594,65.28,43,65.28Zm-.3,16.077H40.322V70.341H42.7c4.021,0,5.261,1.242,5.261,5.509S46.724,81.357,42.7,81.357Z" transform="translate(10.423 0.081)" fill="currentColor" />
        <path id="Path_61" data-name="Path 61" d="M61.988,86.222h-.1L57.522,65.28h-8.09v35.83h5.36V73.12h.1l5.559,27.989h2.978L68.985,73.12h.1v27.989h5.359V65.28H66.355Z" transform="translate(23.919 0.081)" fill="currentColor" />
        <path id="Path_62" data-name="Path 62" d="M95.955,75.85c0-7.642-3.521-10.57-10.421-10.57H76.9v35.829h6.155V86.42a29.5,29.5,0,0,0,3.175-.149L90.6,101.109h6.252l-5.21-16.226C93.724,83.939,95.955,81.606,95.955,75.85ZM85.238,81.357H83.054V70.341h2.184c3.324,0,4.565,1.242,4.565,5.509S88.562,81.357,85.238,81.357Z" transform="translate(48.204 0.081)" fill="currentColor" />
        <path id="Path_63" data-name="Path 63" d="M108.243,65.28h-6.155L95.637,78.53h-.1V65.28H89.385v35.83h6.155v-12.7l2.183-3.971h.1l5.013,16.675h6.253l-7.494-22.481Z" transform="translate(59.243 0.081)" fill="currentColor" />
        <path id="Path_64" data-name="Path 64" d="M106.865,85.229H114.8V79.87h-7.939V70.639h10.322V65.28H100.71v35.83h17.172V95.75H106.865Z" transform="translate(69.256 0.081)" fill="currentColor" />
        <path id="Path_65" data-name="Path 65" d="M110.615,70.639h5.955v30.471h6.153V70.639h5.955V65.28H110.615Z" transform="translate(78.014 0.081)" fill="currentColor" />
        <path id="Path_66" data-name="Path 66" d="M72.018,85.835l.322,2.983h5.975l-1.722-9.688A11.968,11.968,0,0,1,72.018,85.835Z" transform="translate(43.888 12.326)" fill="currentColor" />
        <path id="Path_67" data-name="Path 67" d="M82.445,84.34,78.916,65.188H71.248l-3.194,17.34A14.024,14.024,0,0,1,73.7,80.842l1.332-8.482h.1L76.625,81.8a18.16,18.16,0,0,1-3.231,1.4v0l-.045.013-.036.013,0,0c-4.844,1.291-6.664,2.8-6.664,2.8-.072.058-.145.115-.215.175-.047.043-.09.089-.138.132-.077.075-.156.147-.232.224-.041.045-.079.092-.121.138-.074.083-.151.166-.222.254-.109.136-.213.279-.313.428s-.187.294-.273.443c-.03.049-.055.1-.083.149-.057.1-.111.2-.162.3-.032.06-.058.121-.087.181-.045.09-.09.181-.13.273-.03.066-.055.13-.083.2-.036.087-.073.175-.105.264-.026.068-.051.136-.075.205-.03.085-.06.171-.087.258-.023.07-.043.141-.064.213s-.051.17-.073.254-.036.145-.055.219-.04.168-.057.252-.03.149-.045.224-.03.166-.043.251-.024.151-.034.228-.023.166-.032.249-.017.155-.024.232-.015.164-.021.245-.011.158-.015.237-.008.162-.009.241,0,.162-.006.241v.237c0,.083,0,.166,0,.247s.006.155.009.23.008.17.013.254.011.147.017.22c.006.089.013.175.021.264l.023.2c.011.094.021.187.032.281.008.055.017.109.024.166.077.55.183,1.1.309,1.639l.015.066c.028.121.06.241.09.362l.026.1c.03.115.062.23.094.343l.032.119c.032.111.064.222.1.332l.036.124.1.326.04.124c.036.107.158.835.194.94l.041.122c.038.107.073.213.111.318l.041.119c.04.107.077.213.117.317l.041.113q.059.161.119.317l.04.1.124.318.038.094c.023.057.036-.616.058-.56a18.836,18.836,0,0,1,.132-2.817,12.991,12.991,0,0,1,.846-3.088,11.408,11.408,0,0,1,1.635-2.745,9.2,9.2,0,0,1,2.363-2.131,16.3,16.3,0,0,1,2.834-1.338,17.335,17.335,0,0,0,2.8-1.255,6.033,6.033,0,0,0,.987-.78l.021.126a6.249,6.249,0,0,1-.908.806,17.7,17.7,0,0,1-2.757,1.441,15.66,15.66,0,0,0-2.67,1.458,8.7,8.7,0,0,0-2.09,2.114,9.028,9.028,0,0,0-1.643,5.048,22.066,22.066,0,0,0,.079,3.61c2.668-4.1,7.086-3.312,10.455-5.042C82.745,92.889,82.53,85.669,82.445,84.34Z" transform="translate(36.664 0)" fill="currentColor" />
      </g>
    </svg>
  )
}

export default LogoColorSvg
