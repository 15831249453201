import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import CardPage from '../../../components/Pages/CardPage'
import Input from '../../../components/Inputs/Input'
import Button from '../../../components/Buttons/Button'
import Loading from '../../../components/Loading'
import apis from '../../../apis'
import axios from '../../../apis/axios'
import { setUser } from '../../../redux/action/userAction'

import '../Account.scss'

const BottomComp = ({ onSignIn, disabled, t }) => (
  <div className="account-bottom">
    <div className="account-bottom-links">
      <Link to="/forgot-password">{t('Forgot your password')}</Link>
      <span className="link-sep" />
      <Link to="/create-account">{t('Create account')}</Link>
    </div>
    <Button
      className="account-bottom-btn"
      variant="contained"
      color="primary"
      disableElevation
      onClick={onSignIn}
      disabled={disabled}
    >
      {t('Sign in')}
    </Button>
  </div>
)

function Login() {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)

  const history = useHistory()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const user = useSelector(st => st.user.data)
  const dp = useDispatch()
  const handleSignIn = async () => {
    setLoading(true)
    try {
      const res = await apis.user.login(data.email, data.password)
      localStorage.setItem('bpmarket_token', res.data.token)
      axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`
      dp(setUser(res.data.user))
      history.push('/account')
    } catch (err) {
      if (err.response && err.response.data?.code === 401) {
        setError('Invalid email or password, please try again')
      } else {
        setError('Internal server error')
      }
    }
    setLoading(false)
  }

  const handleChange = ev => {
    setData(st => ({
      ...st,
      [ev.target.name]: ev.target.value,
    }))
    setError(null)
  }

  useEffect(() => {
    if (user && user.id) {
      history.push('/')
    }
  }, [user, history])

  return (
    <CardPage title={t('Login')} bottomComp={<BottomComp onSignIn={handleSignIn} disabled={!data.password || !data.email} t={t} />}>
      <div className="account-inputs account-container">
        <Input
          label={t('Email')}
          variant="filled"
          name="email"
          error={!!error}
          onChange={handleChange}
        />
        <Input
          label={t('Password')}
          variant="filled"
          name="password"
          type={showPassword ? 'text' : 'password'}
          error={!!error}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(s => !s)}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {
          !!error && (
            <FormHelperText error>{t(error)}</FormHelperText>
          )
        }
      </div>
      { loading && <Loading /> }
    </CardPage>
  )
}

export default Login
