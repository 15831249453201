import React from 'react'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import GreenTitle from '../../../components/Titles/GreenTitle'

function DeliveryNote({ register }) {
  const { t } = useTranslation()
  return (
    <section>
      <GreenTitle>
        {t('Delivery note')}
      </GreenTitle>
      <div className="checkout-content">
        <div>
          <TextField label={t('Gate code')} placeholder={t('Gate code / note (if any)')} name="gateCode" fullWidth inputRef={register} />
        </div>
      </div>
    </section>
  )
}

export default DeliveryNote
