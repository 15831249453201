import React, { useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogCloseSvg from './DialogCloseSvg'

const useStyles = makeStyles(theme => ({
  root: {
  },
  container: {
    overflow: 'auto',
    paddingBottom: 60,
  },
  btn: {
    border: 0,
    background: 'transparent',
    padding: 0,
    position: 'absolute',
    right: 20,
    top: 20,
  },
  title: {
    color: theme.palette.success.main,
    fontSize: 24,
    lineHeight: 1,
    fontFamily: 'FuturaStd-CondensedBold',
    textAlign: 'center',
    margin: 0,
    marginBottom: 18,
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
  },
  paper: props => ({
    maxWidth: props.maxWidth,
    minHeight: props.minHeight,
    paddingTop: 60,
    position: 'relative',
    margin: 20,
    maxHeight: 'calc(100% - 40px)',
    width: 'calc(100% - 40px)',
  }),
}))

function CustomDialog({
  title = '',
  children,
  onClose,
  className = '',
  maxWidth = '100%',
  minHeight = 200,
  open,
  ...rest
}) {
  const classes = useStyles({ maxWidth, minHeight })

  useEffect(() => {
    if (open) {
      document.getElementById('root').classList.add('is-blur')
    } else {
      document.getElementById('root').classList.remove('is-blur')
    }
    return () => {
      document.getElementById('root').classList.remove('is-blur')
    }
  }, [open])

  return (
    <Dialog
      className={clsx(classes.root, className)}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
      {...rest}
    >
      <div className={clsx(classes.container, 'dialog-container')}>
        {!!title && <h3 className={classes.title}>{title}</h3>}
        <button className={classes.btn} type="button" onClick={onClose}>
          <DialogCloseSvg />
        </button>
        {children}
      </div>
    </Dialog>
  )
}

export default CustomDialog
