import React, { useState } from 'react'
import {
  useHistory,
} from 'react-router-dom'
import useSWR from 'swr'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import { FormattedNumber } from 'react-intl'
import format from 'date-fns/format'
import { useTranslation } from 'react-i18next'
import ReOrderSvg from '../../../../svg/ReOrderSvg'
import GreenTitle from '../../../../components/Titles/GreenTitle'
import axios from '../../../../apis/axios'
import { setOrder } from '../../../../redux/action/orderAction'
import ReorderDialog from '../../../../components/Dialog/ReorderDialog'

const fetcher = url => axios.get(url).then(res => res.data)

const columns = [
  { id: 'reorder', label: 'Reorder', minWidth: 100 },
  { id: 'number', label: 'Order', minWidth: 90 },
  {
    id: 'createdAt', label: 'Order date', minWidth: 130, type: 'date',
  },
  {
    id: 'deliveryStartAt', label: 'Delivery date', minWidth: 150, type: 'date',
  },
  { id: 'status', label: 'Status', minWidth: 85 },
  { id: 'total', label: 'Total', minWidth: 85 },
]

const chipColor = {
  incomplete: 'primary',
  processing: 'secondary',
  complete: 'primary',
  cancel: '',
}

function Orders({ onClick }) {
  const { t } = useTranslation()

  const [page, setPage] = useState(0)
  const [orderItems, setOrderItems] = useState(null)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const { data } = useSWR('/api/orders', fetcher)
  const dp = useDispatch()
  const history = useHistory()
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handlePay = order => {
    dp(setOrder(order))
    history.push('/payment')
  }

  const reorder = order => {
    setOrderItems(order.orderItems)
  }
  return (
    <>
      <GreenTitle>{t('Order history')}</GreenTitle>
      <div className="account-content account-content-lg">
        <TableContainer>
          <Table className="account-table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, textTransform: 'uppercase' }}
                  >
                    {t(column.label)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!data && data.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    {columns.map(column => {
                      if (column.id === 'reorder') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <button type="button" onClick={() => reorder(row)}>
                              <ReOrderSvg />
                            </button>
                          </TableCell>
                        )
                      }
                      const value = row[column.id]

                      if (column.id === 'number') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Button onClick={() => onClick(row)} size="small" className="btn-text">
                              {`#${value}`}
                            </Button>
                          </TableCell>
                        )
                      }
                      if (column.id === 'total') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <FormattedNumber style="currency" value={+row.subtotal + +row.tax + +row.shipping - +row.discount} currency="USD" />
                          </TableCell>
                        )
                      }
                      if (column.id === 'status') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row.status === 'incomplete' ? <Button onClick={() => handlePay(row)}>Pay</Button> : <Chip size="small" label={t(`Order - ${value}`)} color={chipColor[value] || 'default'} variant={value === 'complete' ? 'outlined' : 'default'} />}
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.type === 'date' && value ? format(new Date(value), 'MM/dd/yyyy') : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={data?.data.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <ReorderDialog
        open={!!orderItems}
        onClose={() => setOrderItems(null)}
        orderItems={orderItems}
      />
    </>
  )
}

export default Orders
