import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import GreenTitle from '../../../components/Titles/GreenTitle'
import apis from '../../../apis'
import useOrder from '../../../hooks/useOrder'

const useStyles = makeStyles(theme => ({
  inputWrap: {
    border: `1px solid ${theme.palette.secondary.main}`,
    height: 52,
    display: 'flex',
  },
  input: {
    border: 0,
    flex: 1,
    padding: 15,
    fontSize: 16,
    fontFamily: 'FuturaStd-book',
  },
  btn: {
    width: 85,
    height: '100%',
    borderRadius: 0,
    fontFamily: 'FuturaStd-bold',
    marginLeft: 'auto',
  },
  discount: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
    '& button': {
      padding: 0,
      border: 0,
      background: 'transparent',
    },
  },
}))

function PromoCode({
  register,
  setError,
  setValue,
  clearErrors,
  discount,
  setDiscount,
  errors,
  discountCode,
  createAccount,
  user,
  itemsCode,
}) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [availableDiscounts, setAvailableDiscounts] = useState([])
  const [loading, setLoading] = useState(false)
  const area = useSelector(st => st.area.area)
  const { subtotal } = useOrder()
  const areaId = area?.id || ''
  const checkDiscountCode = async () => {
    if (discountCode) {
      setLoading(true)
      clearErrors(['discountCode'])
      try {
        const res = await apis.discount.check(
          discountCode, (area?.id || null), subtotal.toFixed(2), itemsCode,
        )
        setDiscount(res.data.data)
      } catch (err) {
        if (err.response) {
          if ((err.response.data.type === 'user' && !createAccount) || err.response.data.type !== 'user') {
            setError('discountCode', {
              type: 'discountCode',
              message: err.response.data.error,
            })
          }
        }
        setDiscount(null)
      }
      setLoading(false)
    }
  }

  const removeDiscount = useCallback(() => {
    setDiscount(null)
    setValue('discountCode', '')
  }, [setDiscount, setValue])
  useEffect(() => {
    if (!discountCode && errors.discountCode) {
      clearErrors(['discountCode'])
    }
  }, [discountCode, clearErrors, errors.discountCode])

  useEffect(() => {
    const load = async () => {
      try {
        const res = await apis.discount.my()
        setAvailableDiscounts(res.data.data.filter(d => d.minSubtotal <= subtotal))
      } catch (err) {
        setAvailableDiscounts([])
      }
    }

    if (subtotal) {
      if (user || createAccount) {
        load()
      } else {
        setAvailableDiscounts([])
        removeDiscount()
      }
    }
  }, [user, createAccount, removeDiscount, subtotal])

  useEffect(() => {
    const areaDiscount = availableDiscounts.filter(a => !a.areas.length || a.areas.includes(areaId))
    if (
      (
        !discount
        || (discount?.areas && discount.areas.length && !discount.areas.includes(areaId)))
      && areaDiscount[0]) {
      setDiscount(areaDiscount[0])
      setValue('discountCode', areaDiscount[0].code)
    } else if (discount && discount.areas.length && !discount.areas.includes(areaId)) {
      setDiscount(null)
      setValue('discountCode', '')
    }
  }, [availableDiscounts, discount, setDiscount, setValue, areaId])

  return (
    <section>
      <GreenTitle>
        {t('Promo code')}
      </GreenTitle>
      <div className="checkout-content">
        <div className={classes.inputWrap}>
          <input
            ref={register}
            name="discountCode"
            className={classes.input}
            type="text"
            placeholder={t('Discount code')}
          />
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            disableElevation
            onClick={checkDiscountCode}
            disabled={loading}
          >
            {t('Apply')}
          </Button>
        </div>
        { !!errors.discountCode && (
          <FormHelperText error>
            {errors.discountCode.message}
          </FormHelperText>
        )}
        {
          !!discount && (
            <div className={classes.discount}>
              {
                !!discount.description && (
                  <span>{discount.description}</span>
                )
              }
              {!discount.autofill && (
                <button type="button" onClick={removeDiscount}>{t('Remove')}</button>
              )}
            </div>
          )
        }
      </div>
    </section>
  )
}

export default PromoCode
