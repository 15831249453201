import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles'

const CustomRadio = withStyles(theme => ({
  root: {
    '&$checked': {
      color: theme.palette.primary.main,
      '& + .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
    },
  },
  checked: {},
}))(props => <Radio color="default" {...props} />)

export default CustomRadio
