import React from 'react'

function DialogCloseSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.414" height="25.414" viewBox="0 0 25.414 25.414" {...props}>
      <g id="Group_133" transform="translate(-36.318 -11.293)">
        <line id="Line_13" x2="24" y2="24" transform="translate(37.025 12)" fill="none" stroke="#030504" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_14" x1="24" y2="24" transform="translate(37.025 12)" fill="none" stroke="#030504" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
      </g>
    </svg>

  )
}

export default DialogCloseSvg
