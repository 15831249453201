import axios from './axios'

const orderApi = {
  create: params => axios.post('/api/order', params),
  payment: number => axios.post(`/api/order/${number}`),
  cancel: number => axios.put(`/api/order/${number}/cancel`),
  complete: (number, orderId) => axios.post(`/api/order/${number}/${orderId}`),
}

export default orderApi
