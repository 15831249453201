import React from 'react'
import {
  Switch,
  Route,
} from 'react-router-dom'
import Home from './views/Home'
import AddOns from './views/Products/AddOns'
import FoodMart from './views/Products/FoodMart'
import HotPot from './views/Products/HotPot'
import About from './views/Static/About'
import Accessibility from './views/Static/Accessibility'
import Policy from './views/Static/Policy'
import Terms from './views/Static/Terms'
import Login from './views/Account/Login'
import Account from './views/Account/Account'
import Checkout from './views/Checkout'
import Payment from './views/Payment'
import Invoice from './views/Invoice'
import ForgotPassword from './views/Account/ForgotPassword'
import ResetPassword from './views/Account/ResetPassword'
import CreateAccount from './views/Account/CreateAccount'
import PrivateRoute from './components/Router/PrivateRoute'
import Error404 from './views/Static/Errors/Error404'

function RouterView() {
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <PrivateRoute path="/account" component={Account} />
      <Route path="/checkout">
        <Checkout />
      </Route>
      <Route path="/payment">
        <Payment />
      </Route>
      <Route path="/invoice">
        <Invoice />
      </Route>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/create-account">
        <CreateAccount />
      </Route>
      <Route path="/add-ons">
        <AddOns />
      </Route>
      <Route path="/hot-pot">
        <HotPot />
      </Route>
      <Route path="/food-mart">
        <FoodMart />
      </Route>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/accessibility">
        <Accessibility />
      </Route>
      <Route path="/policy">
        <Policy />
      </Route>
      <Route path="/terms">
        <Terms />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route component={Error404} />
    </Switch>
  )
}

export default RouterView
