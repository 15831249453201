/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import LogoText from '../../../components/LogoText'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 30,
    backgroundColor: '#FFFFFF',
  },
  container: {
    maxWidth: 700,
    margin: '0 auto',
  },
  content: {
    padding: '30px 20px 30px',
    color: theme.palette.secondary.main,
    '& + &': {
      borderTop: '1px solid #ccc',
    },
    '& p, & li': {
      fontSize: 18,
      lineHeight: '30px',
      margin: 0,
    },
    '& ul': {
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& h4': {
      fontSize: 24,
      lineHeight: '33px',
      margin: 0,
      marginBottom: 15,
      fontFamily: 'FuturaStd-Bold',
    },
    '& h5': {
      fontSize: 18,
      lineHeight: '30px',
      margin: 0,
      fontFamily: 'FuturaStd-Bold',
    },
  },
  questionGroup: {
    fontSize: 18,
    lineHeight: '30px',
    '& + &': {
      marginTop: 20,
    },
  },
}))

function About() {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const { about } = useSelector(st => st.common)

  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <LogoText>{t('About BP market')}</LogoText>
      </div>
      <div className={classes.container}>
        <div className={classes.content}>
          <div dangerouslySetInnerHTML={{ __html: (language === 'tw' && about.contentTw) || about.content }} />
        </div>
        <div className={classes.content}>
          <h4>Q & A</h4>
          {
            about.qa?.map((qa, i) => (
              <div key={i} className={classes.questionGroup}>
                <h5>
                  {i + 1}
                  .
                  {' '}
                  {(language === 'tw' && qa.titleTw) || qa.title}
                </h5>
                <div>
                  {(language === 'tw' && qa.contentTw) || qa.content}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default About
