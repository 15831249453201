import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Button from '../../../components/Buttons/Button'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '26px 20px 0',
    backgroundColor: '#EFDFA2',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    flex: 1,
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 44,
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: 26,
    },
  },
  wrap: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    lineHeight: '30px',
    margin: 0,
    marginBottom: 13,
    fontFamily: 'FuturaStd-CondensedBold',
    [theme.breakpoints.up('sm')]: {
      fontSize: 48,
      lineHeight: '60px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 80,
      marginBottom: 0,
      lineHeight: '100px',
    },
  },
  content: {
    fontSize: 12,
    lineHeight: '20px',
    margin: 0,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      lineHeight: '28px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
      lineHeight: '30px',
    },
  },
  imageContainer: {
    flex: 1,
    width: '100%',
    position: 'relative',
  },
  image: {
    backgroundImage: 'url("/404.jpg")',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: -50,
    left: -10,
    right: -10,
    bottom: -50,
    [theme.breakpoints.up('sm')]: {
      top: -100,
      bottom: -100,
    },
    [theme.breakpoints.up('lg')]: {
      top: -120,
      bottom: -120,
    },
    '@media (orientation: landscape) and (max-height: 800px)': {
      top: -50,
      bottom: -50,
    },
  },
  btns: {
    marginTop: 'auto',
    paddingBottom: '20%',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    '& button': {
      margin: '0 10px',
      whiteSpace: 'nowrap',
      flex: 1,
      maxWidth: 150,
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 155,
      '& button': {
        maxWidth: 206,
      },
    },
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 110,
    },
    '@media (orientation: landscape) and (max-height: 800px)': {
      paddingBottom: '5%',
    },
  },
}))

export default function Error404() {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className={classes.root}>
      <div className={classes.wrap}>
        <h5 className={classes.title}>{t('Error404 - title')}</h5>
        <p className={classes.content}>
          {t('Error404 - content1')}
          <br />
          {t('Error404 - content2')}
        </p>
      </div>
      <div className={classes.imageContainer}>
        <div className={classes.image} />
      </div>
      <div className={classes.btns}>
        <Button color="secondary" variant="outlined" onClick={() => history.push('/')}>{t('Error404 - go home')}</Button>
        <Button color="secondary" variant="contained" onClick={() => history.push('/hot-pot')}>{t('Error404 - order now')}</Button>
      </div>
    </div>
  )
}
