import types from '../actionTypes/pageTypes'

export const setError = error => ({
  type: types.SET_ERROR,
  error,
})

export const clearError = () => ({
  type: types.CLEAR_ERROR,
})

export const setDataCache = data => ({
  type: types.SAT_DATA_CACHE,
  data,
})
