import React from 'react'

function CloseSvg(props) {
  return (
    <svg data-v-2ba07d5a="" xmlns="http://www.w3.org/2000/svg" width="24.828" height="24.828" viewBox="0 0 24.828 24.828" aria-labelledby="close" role="presentation" {...props}>
      <title id="close" lang="en">close icon</title>
      <g id="close" transform="translate(1.414 1.414)">
        <line id="Line_3" data-name="Line 3" x2="22" y2="22" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" />
        <line id="Line_6" data-name="Line 6" x2="22" y2="22" transform="translate(0 22) rotate(-90)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" />
      </g>
    </svg>
  )
}

export default CloseSvg
