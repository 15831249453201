import React from 'react'

function PlaySvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="136" height="136" viewBox="0 0 136 136" {...props}>
      <g id="Group_129" data-name="Group 129" transform="translate(-140.665 -154.163)">
        <g id="Group_128" data-name="Group 128" transform="translate(140.665 154.163)">
          <path id="Path_57" data-name="Path 57" d="M208.664,290.163a68,68,0,1,1,68-68A68.077,68.077,0,0,1,208.664,290.163Zm0-131.019a63.019,63.019,0,1,0,63.019,63.02A63.09,63.09,0,0,0,208.664,159.144Z" transform="translate(-140.665 -154.163)" fill="#fff" />
        </g>
        <path id="Path_58" data-name="Path 58" d="M189.853,222.42V199.5a5.607,5.607,0,0,1,8.41-4.856l19.85,11.461,19.85,11.46a5.607,5.607,0,0,1,0,9.711l-19.85,11.46-19.85,11.46a5.607,5.607,0,0,1-8.41-4.856Z" transform="translate(-0.296 -0.257)" fill="#fff" />
      </g>
    </svg>
  )
}

export default PlaySvg
