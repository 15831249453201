import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import CardPage from '../../../components/Pages/CardPage'
import Button from '../../../components/Buttons/Button'
import TermsCheck from '../../../components/TermsCheck'
import Loading from '../../../components/Loading'
import apis from '../../../apis'
import { setError } from '../../../redux/action/pageAction'
import { setUser } from '../../../redux/action/userAction'
import axios from '../../../apis/axios'
import '../Account.scss'
import {
  SYMBOL_REGEX, LOWER_REGET, UPPER_REGEX,
} from '../../../constant'

const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required').test(
    'email-check',
    'The email is already in use',
    async value => {
      try {
        await apis.user.checkEmail(value)
        return true
      } catch (e) {
        return false
      }
    },
  ),
  password: yup.string().required('Password is required')
    .matches(SYMBOL_REGEX, 'One number, symbol, no whitespace')
    .matches(LOWER_REGET, 'One lowercase character')
    .matches(UPPER_REGEX, 'One uppercase character')
    .test('len', 'At least 8 characters long', val => val.length > 7),
  passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords dont match').required('Confirm password is required'),
  termCheck: yup.bool().oneOf([true], 'You need to agree to the terms and conditions'),
})

const InitBottomComp = ({ onSignUp, t }) => (
  <div className="account-bottom">
    <Button
      className="account-bottom-btn"
      variant="contained"
      color="primary"
      disableElevation
      onClick={onSignUp}
    >
      {t('Sign up')}
    </Button>
    <div className="account-aleary-account">
      {t('Already have an account')}
      &nbsp;
      <Link to="/login">
        {t('Login')}
      </Link>
    </div>
  </div>
)

const SuccessBottomComp = ({ t }) => (
  <div className="account-bottom">
    <Button
      className="account-bottom-btn"
      variant="contained"
      color="primary"
      disableElevation
      component={Link}
      to="/"
    >
      {t('Start an order')}
    </Button>
  </div>
)

const PAGE_TYPE = {
  INIT: 'INIT',
  SUCCESS: 'SUCCESS',
}
function CreateAccount() {
  const { t } = useTranslation()
  const [show, setShow] = useState({
    password: false,
    confirmPassword: false,
  })
  const [page, setPage] = useState(PAGE_TYPE.INIT)
  const [loading, setLoading] = useState(false)
  const user = useSelector(st => st.user.data)
  const history = useHistory()
  const dp = useDispatch()
  const {
    register, handleSubmit, errors,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async data => {
    setLoading(true)
    try {
      await apis.user.create(data)
      const res = await apis.user.login(data.email, data.password)
      axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`
      localStorage.setItem('bpmarket_token', res.data.token)
      dp(setUser(res.data.user))
      setPage(PAGE_TYPE.SUCCESS)
    } catch (err) {
      if (err.response) {
        dp(setError(err.response.data))
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (user && user.id) {
      history.push('/account')
    }
  }, [user, history])

  return page === PAGE_TYPE.INIT ? (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <CardPage title={t('Create an account')} bottomComp={<InitBottomComp onSignUp={handleSubmit(onSubmit)} t={t} />}>
        <div className="account-form signup-form account-container">
          <span className="text-center account-note">{t('Required fields are marked with an asterisk')}</span>
          <TextField
            label={`${t('First name')}*`}
            placeholder={t('First name')}
            name="firstName"
            fullWidth
            error={!!errors.firstName}
            helperText={t(errors.firstName?.message)}
            inputRef={register}
          />
          <TextField
            label={`${t('Last name')}*`}
            placeholder={t('Last name')}
            name="lastName"
            fullWidth
            error={!!errors.lastName}
            helperText={t(errors.lastName?.message)}
            inputRef={register}
          />
          <TextField
            label={`${t('Email')}*`}
            placeholder={t('Email')}
            name="email"
            type="email"
            fullWidth
            error={!!errors.email}
            helperText={t(errors.email?.message)}
            inputRef={register}
          />
          <TextField
            label={`${t('Password')}*`}
            placeholder={t('Password')}
            name="password"
            type={show.password ? 'text' : 'password'}
            fullWidth
            error={!!errors.password}
            helperText={t(errors.password?.message)}
            inputRef={register}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={() => setShow(s => ({ ...s, password: !s.password }))}
                  >
                    {show.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label={`${t('Confirm password')}*`}
            placeholder={t('Confirm password')}
            name="passwordConfirm"
            type={show.confirmPassword ? 'text' : 'password'}
            fullWidth
            error={!!errors.passwordConfirm}
            helperText={t(errors.passwordConfirm?.message)}
            inputRef={register}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={
                      () => setShow(s => ({ ...s, confirmPassword: !s.confirmPassword }))
                    }
                  >
                    {show.confirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TermsCheck register={register} error={t(errors.termCheck?.message)} />
        </div>
      </CardPage>
      { loading && <Loading /> }
    </form>
  ) : (
    <CardPage title={`${t('Welcome')}!`} headerType="success" bottomComp={<SuccessBottomComp t={t} />}>
      <div className="account-container">
        <div className="text-center">{t('Thanks for creating an account')}</div>
      </div>
    </CardPage>
  )
}

export default CreateAccount
