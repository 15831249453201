/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import CardPage from '../../components/Pages/CardPage'
import FacebookSvg from '../../svg/FacebookSvg'
import InstagramSvg from '../../svg/InstagramSvg'
import YoutubeSvg from '../../svg/YoutubeSvg'
import { clearOrder, clearCart } from '../../redux/action/orderAction'

const useStyles = makeStyles(theme => ({
  info: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  invoiceContent: {
    '& strong': {
      color: theme.palette.success.main,
      fontFamily: 'FuturaStd-bold',
    },
  },
  green: {
    color: theme.palette.success.main,
    fontFamily: 'FuturaStd-bold',
  },
  section: {
    padding: '40px 20px',
    borderBottom: '1px solid #ccc',
  },
  qrcode: {},
  social: {
    margin: '0 auto',
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    maxWidth: 340,
    marginBottom: 134,
    '& li': {
      width: '33.333%',
      textAlign: 'center',
    },
  },
  nextBtn: {
    height: 60,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      position: 'static',
      maxWidth: 370,
      margin: '0 auto 80px',
    },
  },
  h5: {
    fontSize: 14,
    lineHeight: '24px',
    fontFamily: 'FuturaStd-Bold',
    margin: '40px 0 20px',
    padding: '0 20px',
    textAlign: 'center',
  },
  h3: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
    marginBottom: 15,
    fontWeight: 'bold',
    fontFamily: 'FuturaStd-Bold',
  },
}))

function Invoice() {
  const { t } = useTranslation()
  const history = useHistory()
  const { order } = useSelector(st => st.order)
  const { wechatFullurl } = useSelector(st => st.common)
  const classes = useStyles()
  const dp = useDispatch()

  useEffect(() => {
    if (!order || order.status !== 'processing') {
      dp(clearOrder())
      history.push('/')
    }
  }, [dp, history, order])

  useEffect(() => () => {
    dp(clearOrder())
    dp(clearCart())
  }, [dp])

  return (
    <>
      <CardPage title={t('Thank you for your order')} showLogo>
        {!!order && (
          <>
            <div className={classes.section}>
              <ul className={classes.info}>
                <li>
                  {t('Invoice number')}
                  :&nbsp;
                  <strong>{order.number}</strong>
                </li>
                <li>
                  {t('Delivery city')}
                  :&nbsp;
                  <strong>{order.deliveryCity}</strong>
                </li>
                <li>
                  {t('Delivery date')}
                  :&nbsp;
                  <strong>
                    {t('Delivery date {startTime} {endTime} {date}', {
                      startTime: format(
                        new Date(order.arrivalStartAt),
                        'h:mm a',
                      ),
                      endTime: format(new Date(order.arrivalEndAt), 'h:mm a'),
                      date: format(new Date(order.arrivalStartAt), 'MM/dd/yy'),
                    })}
                  </strong>
                </li>
              </ul>
              <p
                className={classes.invoiceContent}
                dangerouslySetInnerHTML={{
                  __html: t('Invoice - content', {
                    value: order.paypalPay || 0,
                  }),
                }}
              />
            </div>
            {!!wechatFullurl && (
              <div className={classes.section}>
                <h3 className={classes.green}>{`${t('Need help')}?`}</h3>
                <p>{t('Invoice - help')}</p>
                <div className={classes.qrcode}>
                  <h4>Wechat</h4>
                  <img src={wechatFullurl} alt="wechat" />
                </div>
              </div>
            )}
            <h5 className={classes.h5}>
              {t('Share your group order experience with your friends')}
            </h5>
            <ul className={classes.social}>
              <li>
                <a
                  href="https://www.facebook.com/BoilingPointUSA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookSvg />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/boilingpointgroup"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramSvg />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCCyGFGnjDc299eZqmTWCCdQ/featured"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YoutubeSvg />
                </a>
              </li>
            </ul>
          </>
        )}
      </CardPage>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        fullWidth
        className={classes.nextBtn}
        onClick={() => history.push('/')}
      >
        {t('Okay')}
      </Button>
    </>
  )
}

export default Invoice
