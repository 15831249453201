import { makeStyles } from '@material-ui/core'
import Dialog from './Dialog'

const useStyles = makeStyles({
  dialog: {
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

function QRCodeDialog({ open, onClose, type = 'line' }) {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      maxWidth={300}
      fullWidth
      onClose={onClose}
    >
      <div className={classes.dialog}>
        {type === 'line' ? <img src="/QRCode-line.png" alt="Line" /> : <img src="/QRCode-weChat.png" alt="more" />}
      </div>
    </Dialog>
  )
}
export default QRCodeDialog
