import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      // maxWidth: 1300,
      margin: '0 auto',
      width: '100%',
    },
  },
  box: {
    height: 250,
    backgroundSize: 'auto 100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('sm')]: {
      height: 500,
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      flex: '0 0 50%',
      height: 400,
      marginRight: 40,
    },
    [theme.breakpoints.up('lg')]: {
      height: 500,
      width: '55%',
      flex: '0 0 55%',
      marginRight: 0,
    },
    [theme.breakpoints.up('xl')]: {
      height: 750,
    },
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      background: '#eddea7',
      padding: '30px 52px 44px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px 44px',
    },
    [theme.breakpoints.up('md')]: {
      flex: 1,
      padding: '34px 0 80px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '34px 103px 80px',
    },
    '& h2': {
      margin: 0,
      fontFamily: 'FuturaStd-CondensedBold',
      color: theme.palette.secondary.main,
      fontSize: 36,
      textTransform: 'uppercase',
      lineHeight: '42px',
      [theme.breakpoints.up('md')]: {
        fontSize: 44,
        lineHeight: '54px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 80,
        lineHeight: 1,
      },
    },
    '& small': {
      fontSize: 14,
      lineHeight: '20px',
      margin: 0,
      color: theme.palette.secondary.main,
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        fontSize: 18,
        lineHeight: '30px',
      },
    },
    '& p': {
      fontSize: 14,
      lineHeight: '20px',
      margin: 0,
      color: theme.palette.info.main,
      [theme.breakpoints.up('md')]: {
        fontSize: 18,
        lineHeight: '30px',
        maxWidth: 409,
      },
    },
  },
  buttons: {
    marginTop: 16,
    [theme.breakpoints.up('md')]: {
      marginTop: 30,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 50,
    },
  },
  btn: {
    minWidth: 150,
    height: 50,
    fontWeight: 700,
    fontFamily: 'FuturaStd-Bold',
    fontSize: 14,
    [theme.breakpoints.up('xl')]: {
      minWidth: 200,
    },
    '& + &': {
      marginLeft: 20,
    },
  },
}))

function MainBanner({ onOpen }) {
  const { t, i18n } = useTranslation()
  const { home } = useSelector(st => st.common)

  const { language } = i18n
  const classes = useStyles()

  return (
    <div className="home-main-banner">
      <div className={classes.root}>
        <div
          className={classes.box}
          style={{ backgroundImage: `url(${home.banner})` }}
        />
        <div className={classes.content}>
          <h2>{(language === 'tw' && home.titleTw) || home.title}</h2>
          {/* <small>{desc}</small> */}
          <p>{(language === 'tw' && home.contentTw) || home.content}</p>
          <div className={classes.buttons}>
            {/* <Button
              className={classes.btn}
              variant="contained"
              color="secondary"
              href="https://www.bpgroupusa.com/groupbuy/index_dev.html"
              target="_blank"
              disableElevation
            >
              {t('View menu')}
            </Button> */}
            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              disableElevation
              onClick={onOpen}
            >
              {t('Order now')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainBanner
