import React, { cloneElement, useEffect, useMemo } from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import LayoutHeader from './LayoutHeader'
import LayoutFooter from './LayoutFooter'
import ZipcodeSelector from '../ZipcodeSelector'
import ErrorMessageDialog from '../Dialog/ErrorMessageDialog'
import { fetchConfig } from '../../redux/action/commonAction'
import { fetchBanners } from '../../redux/action/bannerAction'
import LayoutState from './LayoutState'
import ScrollToTop from './ScrollToTop'
import './Layout.scss'

const FULL_PAGES = ['/checkout', '/invoice', '/payment']
function Layout({ children }) {
  const Children = React.Children.map(children, child => cloneElement(child))
  const dp = useDispatch()
  const location = useLocation()

  useEffect(() => {
    dp(fetchConfig())
    dp(fetchBanners())
  }, [dp])

  const isFullPage = useMemo(() => FULL_PAGES.includes(location.pathname), [location.pathname])

  useEffect(() => {
    if (isFullPage) {
      document.getElementById('root').classList.add('full-page')
    } else {
      document.getElementById('root').classList.remove('full-page')
    }
  }, [isFullPage])

  return (
    <>
      <ScrollToTop />
      {!isFullPage && <LayoutHeader />}
      {Children}
      {!isFullPage && <LayoutFooter />}
      <ZipcodeSelector />
      <ErrorMessageDialog />
      <LayoutState />
      <CookieConsent
        location="bottom"
        buttonText="I AGREE"
        cookieName="bpmarket"
        style={{
          font: '12px/1.5 \'FuturaStd-Bold\', Helvetica, sans-serif', background: '#2c262b', justifyContent: 'center', alignItems: 'center', padding: 15,
        }}
        contentStyle={{ maxWidth: 690, marginRight: 20 }}
        buttonStyle={{
          backgroundColor: '#dc4535', fontSize: 12, width: 130, height: 40, color: '#fff',
        }}
        expires={150}
      >
        <div>
          <p style={{ color: '#999', margin: 0 }}>
            By continuing to use our site,
            you understand that we use cookies to improve your experience
            and collect analytics data.
            To find out more,
            read our privacy policy
            {' '}
            <Link to="/policy">
              <span style={{ textDecoration: 'underline', color: '#999' }}>here</span>
            </Link>
          </p>
        </div>
      </CookieConsent>
    </>
  )
}

export default Layout
