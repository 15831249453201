import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import Input from '../Input'
import SearchSvg from '../../../svg/SearchSvg'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '& svg': {
      position: 'absolute',
      right: 16,
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&.error': {
      '& .Mui-error': {
        borderColor: theme.palette.error.main,
        '& input': {
          color: theme.palette.error.main,
        },
      },
      '& svg': {
        color: theme.palette.error.main,
      },
      '& + p': {
        textAlign: 'center',
      },
    },
  },
}))

function SearchInput({ error, ...rest }) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, { error: !!error })}>
      <Input error={error} {...rest} />
      <SearchSvg />
    </div>
  )
}

export default SearchInput
