/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Link } from 'react-router-dom'
import CardPage from '../../../components/Pages/CardPage'
import Input from '../../../components/Inputs/Input'
import Button from '../../../components/Buttons/Button'
import apis from '../../../apis'
import Loading from '../../../components/Loading'

import '../Account.scss'

const InitBottomComp = ({ onSubmit, disabled, t }) => (
  <div className="account-bottom">
    <Button
      className="account-bottom-btn"
      variant="contained"
      color="primary"
      disableElevation
      onClick={onSubmit}
      disabled={disabled}
    >
      {t('Submit')}
    </Button>
    <Link to="/login" className="account-cancel-btn">{t('Cancel')}</Link>
  </div>
)

const SuccessBottomComp = ({ t }) => (
  <div className="account-bottom">
    <Button
      className="account-bottom-btn"
      variant="contained"
      color="primary"
      disableElevation
      component={Link}
      to="/login"
    >
      {t('Done')}
    </Button>
  </div>
)

const PAGE_MAP = {
  INIT: 'INIT',
  SUCCESS: 'SUCCESS',
}

function ForgotPassword() {
  const { t } = useTranslation()

  const [page, setPage] = useState(PAGE_MAP.INIT)
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(null)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await apis.user.forgotPassword(email)
      setPage(PAGE_MAP.SUCCESS)
    } catch (err) {
      if (err.response) {
        setError(err.response.data.error || err.response.data.message)
      }
    }
    setLoading(false)
  }

  const handleChange = ev => {
    setEmail(ev.target.value)
    setError(null)
  }

  const getHiddenEmail = value => {
    const emailArr = value.split('@')
    const [first, ...rest] = emailArr[0]
    return `${first}${[...rest].map(() => '*').join('')}@${emailArr[1]}`
  }
  return page === PAGE_MAP.INIT ? (
    <CardPage title={t('Reset you password')} bottomComp={<InitBottomComp onSubmit={handleSubmit} disabled={!email} t={t} />}>
      <div className="account-inputs account-container">
        <span className="text-center">{t('We will send you an email to reset your password')}</span>
        <Input
          label={t('Email')}
          variant="filled"
          value={email}
          error={!!error}
          onChange={handleChange}
        />
        { !!error && <FormHelperText error>{error}</FormHelperText> }
      </div>
      { loading && <Loading /> }
    </CardPage>
  ) : (
    <CardPage title={t('Reset you password')} bottomComp={<SuccessBottomComp t={t} />}>
      <div className="account-inputs account-container">
        <span className="text-center" dangerouslySetInnerHTML={{ __html: t('An email has been sent to your email address, {email} Follow the directions in the email to reset your password', { email: getHiddenEmail(email) }) }} />
      </div>
    </CardPage>
  )
}

export default ForgotPassword
