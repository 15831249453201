/* eslint-disable max-len */
import React, { useMemo, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { FormattedNumber } from 'react-intl'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import useOrder from '../../../hooks/useOrder'
import GreenTitle from '../../../components/Titles/GreenTitle'
import TermsCheck from '../../../components/TermsCheck'

const useStyles = makeStyles(theme => ({
  detail: {
    '& > div': {
      padding: '5px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& h6, & span': {
      margin: 0,
      fontFamily: 'FuturaStd-Medium',
      fontSize: 24,
      color: theme.palette.secondary.main,
      fontWeight: 500,
    },
  },
  strong: {
    marginTop: 30,
    '& h6, & span': {
      fontFamily: 'FuturaStd-Bold',
      fontSize: 24,
    },
  },
}))

function OrderDetail({ register, errors, discount }) {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    subtotal,
    shipping,
    taxTotal,
    tax,
    taxRate,
    total,
    shippingTaxTotal,
    discountTotal,
  } = useOrder(discount)
  const classes = useStyles()

  const discountRate = useMemo(() => {
    if (discount && discount.value) {
      if (discount.type === 'percentage') return +discount.value / 100
      if (discount.type === 'price') { return Math.min(+discount.value / discountTotal, 1) }
    }
    return 0
  }, [discount, discountTotal])

  let discountValue = 0
  if (discount) {
    switch (discount.type) {
    case 'percentage':
      discountValue = discountTotal * discountRate
      break
    case 'price':
      discountValue = discount.value
      break
    case 'free_shipping':
      discountValue = shipping
      break
    default:
      break
    }
  }

  useEffect(() => {
    if (!subtotal) {
      history.push('/')
    }
  }, [history, subtotal])

  const taxResult = tax * (1 - discountRate)
  // subtotal + shippingFee
  // const all = total + +tip + taxResult
  const all = total + taxResult
  const result = Math.max(all - discountValue, 0)
  const discountResult = discountValue >= all ? all : discountValue

  return (
    <>
      <section>
        <GreenTitle>{t('Order detail')}</GreenTitle>
        <div className="checkout-content">
          <div className={classes.detail}>
            <div>
              <h6>{t('Subtotal')}</h6>
              <span>
                <FormattedNumber
                  style="currency"
                  value={subtotal}
                  currency="USD"
                />
              </span>
            </div>
            <div>
              <h6>{t('Delivery fee')}</h6>
              <span>
                <FormattedNumber
                  style="currency"
                  value={shipping}
                  currency="USD"
                />
              </span>
            </div>
            <div>
              <h6>
                {t('Tax {pct}% [{value}]', {
                  pct: (taxRate * 100).toFixed(2),
                  value: (
                    taxTotal * (1 - discountRate)
                    + shippingTaxTotal
                  ).toFixed(2),
                })}
              </h6>
              <span>
                <FormattedNumber
                  style="currency"
                  value={tax * (1 - discountRate)}
                  currency="USD"
                />
              </span>
            </div>
            {!!discountResult && (
              <div>
                <h6>{t('Discount')}</h6>
                <span>
                  <FormattedNumber
                    style="currency"
                    value={-discountResult}
                    currency="USD"
                  />
                </span>
              </div>
            )}
            <div className={classes.strong}>
              <h6 className="text-uppercase">{t('Total')}</h6>
              <span>
                <FormattedNumber
                  style="currency"
                  value={result}
                  currency="USD"
                />
              </span>
            </div>
          </div>
          <TermsCheck register={register} error={errors.termCheck?.message} />
        </div>
      </section>
      <Button
        variant="contained"
        color="primary"
        className="paymentBtn"
        disableElevation
        type="submit"
      >
        {result > 0 ? t('Continue to payment') : t('Submit order')}
      </Button>
    </>
  )
}

export default OrderDetail
