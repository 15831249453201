/* eslint-disable max-len */
import React from 'react'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import EditSvg from '../../svg/EditSvg'

function ShippingResult({
  classes, zipCode, zipEditCode, onEdit, shippings = [], shippingId,
}) {
  const { t } = useTranslation()
  const current = shippings.find(shipping => shipping.id === shippingId) || null

  return (
    <div className={classes.shippingResult}>
      <p>
        {t('Please review and ensure the information below is correct Don’t worry you can always go back and edit')}
      </p>
      <div className={classes.shippingResultGroup}>
        <h5 className="text-uppercase">
          {t('Delivery to')}
          :
        </h5>
        <div className={classes.resultEdit}>
          <span>{ zipEditCode || zipCode || '' }</span>
          <button className={classes.editBtn} type="button" onClick={onEdit}>
            <EditSvg />
          </button>
        </div>
      </div>
      <div className={classes.shippingResultGroup}>
        <h5>
          {t('Arrival date')}
          :
        </h5>
        <div className={classes.resultEdit}>
          {
            !!current && (
              <span>
                {`${format(new Date(current.arrivalStartDateTime), 'h:mm a')} - ${format(new Date(current.arrivalEndDateTime), 'h:mm a')} ${format(new Date(current.arrivalStartDateTime), 'MM/dd/yy')}`}
              </span>
            )
          }
          <button className={classes.editBtn} type="button" onClick={onEdit}>
            <EditSvg />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ShippingResult
