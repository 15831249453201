import React from 'react'

function InstagramSvg(props) {
  return (
    <svg id="Instagram" xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" {...props}>
      <g id="Instagram_2" data-name="Instagram 2">
        <path id="Rectangle" d="M32.5,0h0A32.5,32.5,0,0,1,65,32.5h0A32.5,32.5,0,0,1,32.5,65h0A32.5,32.5,0,0,1,0,32.5H0A32.5,32.5,0,0,1,32.5,0Z" fill="#262626" />
        <path id="Vector" d="M22.543,29.313a6.771,6.771,0,1,1,6.771,6.771A6.771,6.771,0,0,1,22.543,29.313Zm-3.66,0a10.43,10.43,0,1,0,10.431-10.43A10.43,10.43,0,0,0,18.882,29.313ZM37.719,18.468a2.436,2.436,0,1,0,2.439-2.436h0a2.439,2.439,0,0,0-2.437,2.436ZM21.107,45.847a11.267,11.267,0,0,1-3.773-.7A6.328,6.328,0,0,1,15,43.629a6.25,6.25,0,0,1-1.519-2.336,11.194,11.194,0,0,1-.7-3.771c-.1-2.141-.119-2.784-.119-8.209s.022-6.065.119-8.209a11.273,11.273,0,0,1,.7-3.771,6.721,6.721,0,0,1,3.855-3.857,11.232,11.232,0,0,1,3.773-.7c2.141-.1,2.784-.118,8.206-.118s6.065.022,8.208.118a11.319,11.319,0,0,1,3.773.7,6.721,6.721,0,0,1,3.855,3.857,11.194,11.194,0,0,1,.7,3.771c.1,2.144.119,2.784.119,8.209s-.02,6.065-.119,8.209a11.229,11.229,0,0,1-.7,3.771,6.724,6.724,0,0,1-3.855,3.855,11.231,11.231,0,0,1-3.773.7c-2.141.1-2.784.118-8.208.118S23.247,45.944,21.107,45.847ZM20.938,9.123a14.9,14.9,0,0,0-4.931.944,10.384,10.384,0,0,0-5.941,5.941,14.9,14.9,0,0,0-.944,4.931C9.024,23.1,9,23.8,9,29.313s.023,6.209.122,8.374a14.9,14.9,0,0,0,.944,4.931,10.384,10.384,0,0,0,5.941,5.941,14.918,14.918,0,0,0,4.931.944c2.168.1,2.859.123,8.376.123s6.207-.023,8.374-.123a14.9,14.9,0,0,0,4.931-.944,10.384,10.384,0,0,0,5.941-5.941,14.862,14.862,0,0,0,.944-4.931c.1-2.167.121-2.859.121-8.374S49.6,23.1,49.5,20.938a14.9,14.9,0,0,0-.944-4.931,10.387,10.387,0,0,0-5.939-5.941,14.849,14.849,0,0,0-4.931-.944C35.524,9.024,34.83,9,29.315,9S23.106,9.023,20.938,9.123Z" transform="translate(3.188 3.188)" fill="#fff" />
      </g>
    </svg>
  )
}

export default InstagramSvg
