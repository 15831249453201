import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Buttons/Button'
import Dialog from '../../../../components/Dialog'
import AddressCreateForm from './AddressCreateForm'
import AddressEditDialog from './AddressEditDialog'
import { setError } from '../../../../redux/action/pageAction'
import '../../Account.scss'
import './Address.scss'
import apis from '../../../../apis'
import Loading from '../../../../components/Loading'

function Address() {
  const { t } = useTranslation()

  const [addressBooks, setAddressBooks] = useState([])
  const [addressBook, setAddressBook] = useState({})
  const [editOpen, setEditOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const dp = useDispatch()
  const history = useHistory()

  const handleEdit = ev => {
    setAddressBook(ev)
    setEditOpen(true)
  }
  const handleDelete = ev => {
    setAddressBook(ev)
    setDeleteOpen(true)
  }
  const handleMakeDefault = async ev => {
    setLoading(true)
    try {
      const res = await apis.address.setDefaultAddressBook(ev.id)
      setAddressBooks(res.data.data)
    } catch (err) {
      if (err.response) {
        dp(setError(err.response.data))
      }
    }
    setLoading(false)
  }
  const handleDestroy = async () => {
    setLoading(true)
    try {
      const res = await apis.address.deleteAddressBooks(addressBook.id)
      setAddressBooks(res.data.data)
    } catch (err) {
      if (err.response) {
        dp(setError(err.response.data))
      }
    }
    setLoading(false)
    setDeleteOpen(false)
  }
  const handleSave = async value => {
    setLoading(true)
    try {
      const api = addressBook && editOpen
        ? apis.address.updateAddressBook(addressBook.id, value)
        : apis.address.createAddressBook(value)
      const res = await api
      setAddressBooks(res.data.data)
    } catch (err) {
      if (err.response) {
        dp(setError(err.response.data))
      }
    }
    if (addressBook && editOpen) {
      setEditOpen(false)
      setAddressBook({})
    }
    setLoading(false)
    history.push('/account')
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await apis.address.getAddressBooks()
        setAddressBooks(res.data.data)
      } catch (err) {
        if (err.response) {
          dp(setError(err.response.data))
        }
      }
    }
    fetchData()
  }, [dp])

  return (
    <>
      <AddressCreateForm
        addressBooks={addressBooks}
        onSave={handleSave}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onMakeDefault={handleMakeDefault}
      />
      <AddressEditDialog
        addressBook={addressBook}
        open={editOpen}
        onClose={() => setEditOpen(false)}
        onSave={handleSave}
      />
      <Dialog
        open={deleteOpen}
        maxWidth={588}
        fullWidth
        title={t('Are you sure you want to delete')}
        onClose={() => setDeleteOpen(false)}
      >
        <div className="address-edit-dialog">
          <div className="address-edit-dialog-buttons">
            <Button className="address-edit-cancel-btn" disableElevation onClick={() => setDeleteOpen(false)}>{t('No thanks')}</Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleDestroy}
            >
              {t('Delete')}
            </Button>
          </div>
        </div>
      </Dialog>
      { loading && <Loading />}
    </>
  )
}

export default Address
