/* eslint-disable max-len */
import React from 'react'
import { useTranslation } from 'react-i18next'
import StaticPage from '../../../components/Pages/StaticPage'

function Accessibility() {
  const { t } = useTranslation()
  return (
    <StaticPage title={t('Accessibility statement')}>
      <p>
        Boiling Point Group, Inc. is committed to providing excellent service to all of our guests, including those with disabilities.
        <br />
        <br />
        To accomplish this, Boiling Point Group, Inc. has based its web accessibility standards on the W3C Web Content Accessibility Guidelines (WCAG) 2.0 Level A and AA success and conformance criteria. Here, at Boiling Point Group, Inc., we believe our continued efforts toward conforming to this standard will provide our guests with accessible content and an enjoyable experience.
        <br />
        <br />
        To use the accessibility features: press TAB key once to open the Accessibility Tool Bar. If, at any time, you need any support, please call us at (888) 383 8325
        <br />
        <br />
        Just some of the steps that are being taken toward providing more accessible web content include, but is not limited to, dedicating resources to digital accessibility, performing multi-layer accessibility testing and analysis of new and updated web content.
      </p>
    </StaticPage>
  )
}

export default Accessibility
