import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import IconButton from '@material-ui/core/IconButton'
import ProductPrice from '../ProductPrice'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    borderBottom: '1px solid #ccc',
  },
  main: {
    position: 'relative',
  },
  btn: {
    '& .MuiButton-label': {
      textTransform: 'none',
    },
    '&.MuiButtonBase-root': {
      padding: '5px 10px',
      border: 0,
      minHeight: 100,
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      textAlign: 'left',
      color: theme.palette.info.main,
      '&:not(.is-picked):disabled': {
        opacity: 0.3,
      },
    },
    '& h2': {
      fontSize: 16,
      lineHeight: 1.25,
      margin: 0,
      marginBottom: 3,
    },
    '&:hover': {
      '& $image': {
        opacity: 0.6,
      },
    },
  },
  image: {
    width: 90,
    height: 90,
    flex: '0 0 90px',
    marginRight: 20,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  },
  price: {
    '& span': {
      fontFamily: 'FuturaStd-Bold',
      fontSize: 16,
      lineHeight: 1.75,
      '&:not(:last-child)': {
        color: theme.palette.error.main,
        marginRight: 16,
      },
      '& + span': {
        fontWeight: 400,
        textDecoration: 'line-through',
        fontFamily: 'FuturaStd-Book',
      },
    },
  },
  addPrice: {
    color: theme.palette.error.main,
  },
  restock: {
    fontTransform: 'uppercase',
    fontSize: 16,
    lineHeight: 1.75,
  },
  soldout: {
    color: theme.palette.error.main,
    fontFamily: 'FuturaStd-Bold',
    fontSize: 16,
    lineHeight: 1.75,
  },
  notAvailable: {
    fontSize: 14,
  },
  infoBtn: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 5,
    color: '#CCCCCC',
  },
  circle: {
    position: 'absolute',
    width: 50,
    height: 50,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.error.main,
    color: 'white',
    borderRadius: 100,
    border: '2px solid white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'FuturaStd-Bold',
    fontSize: 24,
    lineHeight: 1,
    paddingTop: 1,
    '& svg': {
      width: 30,
      height: 30,
    },
  },
  func: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 48,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      width: 28,
      height: 28,
      border: '1px solid #ccc',
      marginLeft: 25,
      boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
      color: theme.palette.secondary.main,
      backgroundColor: '#FFFFFF',
      '&:hover': {
        background: '#FFFFFF',
      },
    },
  },
}))
function ProductBundleList({
  product,
  picked,
  type,
  disabled,
  onPlus = () => {},
  onMinus = () => {},
  onView = () => {},
}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Button
          className={clsx(classes.btn, { 'is-picked': !!picked })}
          disabled={disabled}
          onClick={onPlus}
        >
          <div className={classes.image} style={{ backgroundImage: `url(${product.imageFullurl})` }}>
            {
              !!picked && (
                <div className={classes.circle}>
                  <span>{ picked }</span>
                </div>
              )
            }
          </div>
          <div>
            <h2>{product.name}</h2>
            <ProductPrice
              areaActive
              type={type}
              classes={classes}
              price={product.pivot.addPrice}
              quantity={Math.min(product.quantity, product.pivot.quantityMax)}
            />
          </div>
        </Button>
        {
          !!picked && (
            <div className={classes.func}>
              <IconButton onClick={onMinus}>
                <RemoveOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={onPlus}
                disabled={picked >= product.pivot.quantityMax || disabled}
              >
                <AddOutlinedIcon />
              </IconButton>
            </div>
          )
        }
      </div>
      <IconButton className={classes.infoBtn} onClick={onView}>
        <InfoOutlinedIcon />
      </IconButton>
    </div>
  )
}

export default ProductBundleList
