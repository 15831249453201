import React from 'react'

function TrashSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.399" height="18" viewBox="0 0 14.399 18" {...props}>
      <g id="Delete" transform="translate(-9 -4.998)">
        <path id="Path_88" data-name="Path 88" d="M23.4,7.7h-3.15V6.686A1.746,1.746,0,0,0,18.45,5h-4.5a1.746,1.746,0,0,0-1.8,1.687V7.7H9v.9h.9V19.848A3.15,3.15,0,0,0,13.05,23h6.3a3.15,3.15,0,0,0,3.15-3.15V8.6h.9ZM13.05,6.686a.851.851,0,0,1,.9-.787h4.5a.851.851,0,0,1,.9.787V7.7h-6.3ZM21.6,19.848a2.25,2.25,0,0,1-2.25,2.25h-6.3a2.25,2.25,0,0,1-2.25-2.25V8.6H21.6Z" transform="translate(0 0)" fill="currentColor" />
        <rect id="Rectangle_95" data-name="Rectangle 95" width="1" height="10" transform="translate(14.133 9.362)" fill="currentColor" />
        <rect id="Rectangle_96" data-name="Rectangle 96" width="1" height="10" transform="translate(18.266 9.362)" fill="currentColor" />
      </g>
    </svg>
  )
}

export default TrashSvg
