import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import LocationSvg from '../../svg/LocationSvg'

const useStyles = makeStyles({
  locationBtn: {
    border: 0,
    background: 'transparent',
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: 10,
    '& svg': {
      marginRight: 15,
    },
  },
})

function LocationSelector({ onClick }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const handleClick = async () => {
    const res = await axios.get('https://ipapi.co/json/?key=fMom4ftJycQLHy6CKxLud5KPo0lpUsKKBuKJ8XJHP1FjsqOW47')
    if (res.data) {
      if (onClick) onClick(res.data.postal || '')
    }
  }
  return (
    <button type="button" className={classes.locationBtn} onClick={handleClick}>
      <LocationSvg />
      {t('Use my location')}
    </button>
  )
}

export default LocationSelector
