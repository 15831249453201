import axios from './axios'

const addressApi = {
  getAddressBooks: () => axios.get('/api/address-books'),
  createAddressBook: params => axios.post('/api/address-book', params),
  updateAddressBook: (id, params) => axios.put(`/api/address-book/${id}`, params),
  setDefaultAddressBook: id => axios.put(`/api/address-book/default/${id}`),
  deleteAddressBooks: id => axios.delete(`/api/address-book/${id}`),
}

export default addressApi
