export default {
  SET_ORDER: 'ORDER/SET_ORDER',
  CLEAR_ORDER: 'ORDER/CLEAR_ORDER',
  SELECT_SHIP_METHOD: 'ORDER/SELECT_SHIP_METHOD',
  UPDATE_SHIP_METHOD: 'ORDER/UPDATE_SHIP_METHOD',
  UPDATE_ZIPCODE: 'ORDER/UPDATE_ZIPCODE',
  UPDATE_TAXRATE: 'ORDER/UPDATE_TAXRATE',
  CLEAR_FAILD: 'ORDER/CLEAR_FAILD',
  CLEAR_FAILD_CART: 'ORDER/CLEAR_FAILD_CART',
  CHECK_CART: 'ORDER/CHECK_CART',
  ADD_TO_CART: 'ORDER/ADD_TO_CART',
  DUPLICATE_CART: 'ORDER/DUPLICATE_CART',
  REMOVE_CART: 'ORDER/REMOVE_CART',
  REMOVE_ALL_CART: 'ORDER/REMOVE_ALL_CART',
  CLEAR_CART: 'ORDER/CLEAR_CART',
}
