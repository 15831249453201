import React from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@material-ui/core/Button'
import Dialog from '../../../../components/Dialog'
import AddressFields from './AddressFields'
import { PHONE_NO_REGEX } from '../../../../constant'

const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  address: yup.string().required('Address is required'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
  zipCode: yup.string().required('Zip code is required'),
  phone: yup.string().test('format', 'Phone number is not valid', val => (val ? val.match(PHONE_NO_REGEX) : true)),
})

function AddressEditDialog({
  addressBook, open, onClose, onSave,
}) {
  const { t } = useTranslation()

  const {
    register, handleSubmit, control, errors,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async data => {
    onSave(data)
  }

  return (
    <Dialog
      open={open}
      maxWidth={588}
      fullWidth
      title="EDIT ADDRESS"
      onClose={onClose}
    >
      <div className="address-edit-dialog">
        <div className="account-form account-container">
          <span className="text-center account-note">{t('Select the address you would like to use for this order')}</span>
          <AddressFields
            addressBook={addressBook}
            register={register}
            errors={errors}
            control={control}
          />
          <div className="address-edit-dialog-buttons">
            <Button className="address-edit-cancel-btn" disableElevation onClick={onClose}>{t('Cancel')}</Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleSubmit(onSubmit)}
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AddressEditDialog
