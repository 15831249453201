import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CloseSvg from '../../svg/CloseSvg'

const useStyles = makeStyles(theme => ({
  root: {
  },
  drawer: props => ({
    width: props.width,
    flexShrink: 0,
  }),
  drawerPaper: props => ({
    width: props.width,
  }),
  drawerContainer: {
    overflow: 'auto',
    flex: 1,
    '& > div': {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  drawerHeader: {
    height: 74,
    borderBottom: '1px solid #ccc',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h3': {
      color: theme.palette.secondary.main,
      fontFamily: 'FuturaStd-Bold',
      textAlign: 'center',
      fontSize: 24,
      lineHeight: '32px',
      margin: 0,
      textTransform: 'uppercase',
    },
    '& button': {
      position: 'absolute',
      width: 64,
      right: 0,
      top: 0,
      height: '100%',
      background: 'transparent',
      border: 0,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}))

function CustomDrawer({
  title = '',
  children,
  onClose,
  width = '100%',
  className = '',
  ...rest
}) {
  const classes = useStyles({ width })
  return (
    <Drawer
      className={clsx(classes.drawer, className)}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={onClose}
      {...rest}
    >
      <div className={clsx(classes.drawerHeader, 'drawer-header')}>
        {!!title && <h3>{title}</h3>}
        <button type="button" onClick={onClose}>
          <CloseSvg />
        </button>
      </div>
      <div className={clsx(classes.drawerContainer, 'drawer-container')}>
        {children}
      </div>
    </Drawer>
  )
}

export default CustomDrawer
