import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: props => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 50,
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
      marginTop: 40,
      marginBottom: 40,
    },
    '& h2': {
      textTransform: 'uppercase',
      fontFamily: 'FuturaStd-CondensedBold',
      color: theme.palette[props.color].main,
      fontSize: 24,
      lineHeight: 1,
      margin: 0,
    },
    '& button + button': {
      marginLeft: 14,
    },
  }),
}))

function GreenTitle({ children, color = 'success', rightComp }) {
  const classes = useStyles({ color })
  return (
    <div className={classes.root}>
      <h2>{children}</h2>
      {!!rightComp && rightComp}
    </div>
  )
}

export default GreenTitle
