import React, { useState, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useTranslation } from 'react-i18next'
import ProductTitle from '../../../components/Titles/ProductTitle'
import Products from '../../../components/Products'
import ProductAction from '../../../components/Products/ProductAction'
import FilterIcon from '../../../svg/FilterIcon'
import useCart from '../../../hooks/useCart'
import useOrder from '../../../hooks/useOrder'
import { PRODUCT_TYPE } from '../../../constant'
import BundlePicker from '../../../components/BundlePicker'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'white',
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 67,
    },
  },
  inner: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1330,
      margin: '0 auto',
    },
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: '0 20px',
    borderBottom: '1px solid #ccc',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: '1px solid #ccc',
      marginTop: 30,
      margin: '30px 40px 20px',
      overflow: 'visible',
    },
    '& h5': {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        margin: 0,
        left: 0,
        fontFamily: 'FuturaStd-Book',
        '& svg': {
          marginRight: 10,
        },
      },
    },
    '& button': {
      background: 'transparent',
      border: 0,
      whiteSpace: 'nowrap',
      padding: '20px 0',
      fontSize: 20,
      lineHeight: 1,
      fontFamily: 'FuturaStd-CondensedBold',
      marginRight: 20,
      borderBottom: '2px solid transparent',
      transitionDuration: '0.3s',
      marginBottom: -1,
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        fontSize: 22,
        margin: '0 15px -1px',
      },
      '&.active, &:hover': {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
      },
    },
  },
  viewAll: {
    [theme.breakpoints.up('lg')]: {
      position: 'absolute !important',
      right: 0,
      marginRight: '0 !important',
    },
  },
}))

function FoodMart() {
  const { categories = [] } = useSelector(st => st.common)
  const [type, setType] = useState(0)
  const [picked, setPicked] = useState({})
  const { foodmartItems } = useOrder()
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const {
    addToCart, fetchAndCheck, availableProducts, isFetching,
  } = useCart()
  const [bundleProduct, setBundleProduct] = useState(null)
  const [bundleItems, setBundleItems] = useState(null)

  const isEdit = useMemo(() => location.search.indexOf('edit') !== -1, [location.search])

  const handleBundlePlus = (items, product = bundleProduct) => {
    setPicked(st => ({
      ...st,
      [product.code]: {
        picked: 1,
        data: product,
        additional: items,
      },
    }))
    setBundleItems(null)
    setBundleProduct(null)
  }

  const handlePlus = product => {
    if (product.type === 'bundle' && !picked[product.code]?.picked) {
      if (product.isFixedBundle) {
        handleBundlePlus(product.comboItems.reduce((acc, cur) => ({
          ...acc,
          [cur.code]: {
            picked: cur.pivot.quantityMax,
            data: cur,
          },
        }), {}), product)
      } else {
        const cartItem = foodmartItems.find(item => item.code === product.code)
        setBundleItems(cartItem?.additional || null)
        setBundleProduct(product)
      }
    } else {
      setPicked(st => ({
        ...st,
        [product.code]: {
          ...(st[product.code] || {}),
          picked: st[product.code] ? st[product.code].picked + 1 : 1,
          data: product,
        },
      }))
    }
  }
  const handleMinus = product => {
    setPicked(st => ({
      ...st,
      [product.code]: {
        ...(st[product.code] || {}),
        picked: st[product.code] ? st[product.code].picked - 1 : 0,
        data: product,
      },
    }))
  }

  const handleUpdate = ev => {
    setBundleItems(picked[ev.code].additional)
    setBundleProduct(ev)
  }

  const handleAddToCart = () => {
    if (isEdit) history.push('food-mart')
    addToCart(picked, 'foodmart', isEdit)
    setPicked({})
  }

  useEffect(() => {
    fetchAndCheck()
  }, [fetchAndCheck])

  useEffect(() => {
    if (isEdit) {
      const pickedItem = foodmartItems.reduce((acc, item) => {
        const current = availableProducts.find(p => p.code === item.code)
        if (current) {
          return {
            ...acc,
            [item.code]: {
              ...item,
              data: current,
            },
          }
        }
        return acc
      }, {})
      setPicked(pickedItem)
    }
  }, [foodmartItems, availableProducts, isEdit])

  const products = useMemo(() => (
    type === 0
      ? availableProducts.filter(d =>
        (d.type === PRODUCT_TYPE.FOOD_MARKET || d.subType === PRODUCT_TYPE.FOOD_MARKET)
        && d.available)
      : availableProducts.filter(d =>
        (d.type === PRODUCT_TYPE.FOOD_MARKET || d.subType === PRODUCT_TYPE.FOOD_MARKET)
        && d.categoryIds.includes(type)
        && d.available)
  ), [availableProducts, type])

  const { language } = i18n

  const filterCategory = categories
    .filter(
      c => availableProducts.find(
        p => (
          (p.type === PRODUCT_TYPE.FOOD_MARKET || p.subType === PRODUCT_TYPE.FOOD_MARKET)
          && p.categoryIds.includes(c.id)
          && p.areaActive
        ),
      ),
    )

  return (
    <>
      <div className={classes.root}>
        <div className={classes.inner}>
          <ProductTitle
            title={t('Food mart')}
            subTitle={t('Fresh food items')}
          />
          <div className={classes.filter}>
            <h5>
              <FilterIcon />
              {t('Filter')}
            </h5>
            <button
              className={clsx({ active: type === 0 })}
              type="button"
              onClick={() => setType(0)}
            >
              {t('View all')}
            </button>
            {
              filterCategory.map(category => (
                <button
                  key={category.id}
                  className={clsx({ active: type === category.id })}
                  type="button"
                  onClick={() => setType(category.id)}
                >
                  {(language === 'tw' && category.nameTw) || category.name}
                </button>
              ))
            }
          </div>
          <Products
            products={products}
            picked={picked}
            multiple
            onPlus={handlePlus}
            onMinus={handleMinus}
            onUpdate={handleUpdate}
          />
        </div>
      </div>
      <ProductAction addText={isEdit ? t('Update') : t('Add to cart')} addons={picked} addToCart={handleAddToCart} />
      <BundlePicker
        open={!!bundleProduct}
        onClose={() => setBundleProduct(null)}
        pickedData={picked?.[bundleProduct?.code]}
        product={bundleProduct}
        defaultValue={bundleItems}
        onPickBundle={handleBundlePlus}
      />
      { isFetching && <LinearProgress /> }
    </>
  )
}

export default FoodMart
