import axios from 'axios'

const baseURL = process.env.NODE_ENV === 'production' ? 'https://bpmarket.rooster-designs2.com/' : 'http://localhost'

const instance = axios.create({
  baseURL,
  headers: {
    withCredentials: true,
  },
})

const token = localStorage.getItem('bpmarket_token')
if (token) {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`
}

export default instance
