import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FormattedNumber } from 'react-intl'
import Button from '@material-ui/core/Button'
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    '& button': {
      fontFamily: 'FuturaStd-Bold',
      [theme.breakpoints.up('lg')]: {
        maxWidth: 206,
      },
    },
    '& h5': {
      margin: 0,
      fontFamily: 'FuturaStd-Book',
      fontSize: 25,
      lineHeight: '29px',
      marginBottom: 19,
      textTransform: 'uppercase',
    },
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
      flexWrap: 'wrap',
    },
    '& li': {
      fontSize: 16,
      lineHeight: '19px',
      display: 'inline-flex',
      marginRight: 40,
      '& span': {
        marginRight: 3,
      },
    },
    [theme.breakpoints.up('lg')]: {
      background: '#eddea7',
      padding: 20,
    },
  },
  inner: {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    width: '100%',
  },
  picked: {
    flex: 1,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  cartButtons: {
    width: '100%',
    display: 'flex',
    '& button': {
      height: 60,
      // marginLeft: 25,
      [theme.breakpoints.up('lg')]: {
        width: 206,
        flex: '0 0 206',
        '& + button': {
          marginLeft: 25,
        },
      },
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
      marginLeft: 'auto',
      width: 'auto',
    },
  },
  addBtn: {
    flex: 1,
  },
  backBtn: {
    '& svg': {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    '& .text': {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 50%',
    },
  },
}))
function ProductAction({
  addons = {},
  meat = {},
  broth = {},
  showBack = false,
  addText = 'Add to cart',
  goBack,
  addToCart,
}) {
  const { t, i18n } = useTranslation()
  const addonItems = Object.values(addons).filter(v => !!v.picked)
  const classes = useStyles()
  const { language } = i18n
  return (
    (!!addonItems.length || !!meat.data || !!broth.data) && (
      <div className={classes.root}>
        <div className={classes.inner}>
          {!!addonItems.length && (
            <div className={classes.picked}>
              <h5>{t('Your addons')}</h5>
              <ul>
                {addonItems.map(pick => {
                  const addPrices = pick.additional
                    ? Object.values(pick.additional).filter(add => add.picked).reduce(
                      (acc, cur) => acc + +cur.data.pivot.addPrice * cur.picked,
                      0,
                    )
                    : 0
                  return (
                    <li key={pick.data.id}>
                      {pick.picked > 1 && <span>{`${pick.picked}x`}</span>}
                      <span>
                        {(language === 'tw' && pick.data.nameTw)
                          || pick.data.name}
                      </span>
                      <FormattedNumber
                        style="currency"
                        value={
                          pick.picked
                            * (+(pick.data.specialPrice || pick.data.price) + addPrices) || 0
                        }
                        currency="USD"
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {!!meat.data && (
            <div className={classes.picked}>
              <h5>{t('Your combo')}</h5>
              <ul>
                <li>
                  <span>
                    {(language === 'tw' && meat.data.nameTw) || meat.data.name}
                  </span>
                  <FormattedNumber
                    style="currency"
                    value={meat.data.price}
                    currency="USD"
                  />
                </li>
              </ul>
            </div>
          )}
          {!!broth.data && (
            <div className={classes.picked}>
              <h5>{t('Your broth')}</h5>
              <ul>
                <li>
                  <span>
                    {(language === 'tw' && broth.data.nameTw)
                      || broth.data.name}
                  </span>
                  <FormattedNumber
                    style="currency"
                    value={broth.data.comboPrice}
                    currency="USD"
                  />
                </li>
              </ul>
            </div>
          )}
          <div className={classes.cartButtons}>
            {showBack && (
              <Button
                className={classes.backBtn}
                variant="contained"
                color="secondary"
                disableElevation
                onClick={goBack}
              >
                <ArrowBackIosOutlinedIcon />
                <span className="text">{t('Go back')}</span>
              </Button>
            )}
            <Button
              className={classes.addBtn}
              variant="contained"
              color={showBack ? 'primary' : 'secondary'}
              disableElevation
              onClick={addToCart}
            >
              {addText}
            </Button>
          </div>
        </div>
      </div>
    )
  )
}

export default ProductAction
