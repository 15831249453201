import types from '../actionTypes/bannerTypes'
import apis from '../../apis'

const fetchBannerRequest = () => ({
  type: types.FETCH_REQUEST,
})

const fetchBannerFailure = error => ({
  type: types.FETCH_FAILURE,
  error,
})

const fetchBannerSuccess = banners => ({
  type: types.FETCH_SUCCESS,
  banners,
})

// 取得全部地區資料
export const fetchBanners = () => async dispatch => {
  dispatch(fetchBannerRequest())
  try {
    const res = await apis.banner.getBanners()
    dispatch(fetchBannerSuccess(res.data.data))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    dispatch(fetchBannerFailure(err))
  }
}
