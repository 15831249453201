import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PayPalButton } from 'react-paypal-button-v2'
import ReactGA from 'react-ga4'
import CardPage from '../../components/Pages/CardPage'
import apis from '../../apis'
import { setError } from '../../redux/action/pageAction'
import { setOrder } from '../../redux/action/orderAction'
import Loading from '../../components/Loading'

function Payment() {
  const { order } = useSelector(st => st.order)
  const area = useSelector(st => st.area.area)
  const orderNumberRef = useRef()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const dp = useDispatch()

  useEffect(() => {
    if (!order) {
      history.push('/')
    } else {
      orderNumberRef.current = order?.number || ''
    }
  }, [history, order])

  const createOrder = async () => {
    setLoading(true)
    try {
      const res = await apis.order.payment(order.number)
      setLoading(false)
      return res.data.result.id
    } catch (err) {
      if (err.response) {
        dp(setError(err.response.data))
      }
      setLoading(false)
      return null
    }
  }

  const handleApprove = async data => {
    setLoading(true)
    try {
      const res = await apis.order.complete(order.number, data.orderID)
      orderNumberRef.current = null
      const resOrder = res.data.data
      ReactGA.event('purchase', {
        transaction_id: resOrder.paypalTransId,
        affiliation: 'BPMarket',
        value: +resOrder.subtotal - +resOrder.discount,
        currency: 'USD',
        tax: +resOrder.tax,
        shipping: +resOrder.shipping,
        items: resOrder.orderItems.map(item => ({
          id: item.productCode,
          name: item.productName,
          list_name: '',
          category: item.product?.type,
          variant: '',
          list_position: 1,
          quantity: item.quantity,
          price: item.specialPrice ?? item.price,
        })),
        coupon: resOrder.discountCode || '',
      })

      dp(setOrder(resOrder))
      history.push('/invoice')
      setLoading(false)
    } catch (err) {
      if (err.response) {
        dp(setError(err.response.data))
      }
    }
    setLoading(false)
  }

  useEffect(() => () => {
    if (orderNumberRef.current) {
      apis.order.cancel(orderNumberRef.current)
      // dp(setOrder(null))
    }
  }, [dp])
  return (
    <>
      <CardPage title="PAYMENT" showBack>
        {
          !!order && area.paypal && (
            <PayPalButton
              shippingPreference="NO_SHIPPING"
              shippingType="PICKUP"
              createOrder={createOrder}
              onApprove={handleApprove}
              options={{
                disableFunding: 'credit',
                clientId: area.paypal.clientId,
              }}
            />
          )
        }
      </CardPage>
      { loading && <Loading /> }
    </>
  )
}

export default Payment
