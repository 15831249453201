import { useEffect, useState } from 'react'
import throttle from 'lodash/throttle'

export default function useInSize([start = 0, end = 0]) {
  const [inSize, setInSize] = useState(start <= window.innerWidth
    && (!end || window.innerWidth < end))

  useEffect(() => {
    const handleResize = throttle(() => {
      if (start <= window.innerWidth && (!end || window.innerWidth < end)) {
        if (!inSize) setInSize(true)
      } else if (inSize) {
        setInSize(false)
      }
    }, 300)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return inSize
}
