import React, { useState, useEffect } from 'react'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CardPage from '../../../components/Pages/CardPage'
import PasswordTips from '../../../components/PasswordTips'
import Input from '../../../components/Inputs/Input'
import Button from '../../../components/Buttons/Button'
import apis from '../../../apis'
import Loading from '../../../components/Loading'
import getParameterByName from '../../../utils/getParameterByName'
import '../Account.scss'

const InitBottomComp = ({ onSubmit, disabled, t }) => (
  <div className="account-bottom">
    <Button
      className="account-bottom-btn"
      variant="contained"
      color="primary"
      disableElevation
      onClick={onSubmit}
      disabled={disabled}
    >
      {t('Submit')}
    </Button>
  </div>
)

const SuccessBottomComp = ({ t }) => (
  <div className="account-bottom">
    <Button
      className="account-bottom-btn"
      variant="contained"
      color="primary"
      disableElevation
      component={Link}
      to="/login"
    >
      {t('Sign in')}
    </Button>
  </div>
)

const PAGE_MAP = {
  INIT: 'INIT',
  SUCCESS: 'SUCCESS',
}

function ResetPassword() {
  const { t } = useTranslation()

  const location = useLocation()
  const [page, setPage] = useState(PAGE_MAP.INIT)
  const [value, setValue] = useState({ password: '', confirmPassword: '' })
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(null)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await apis.user.resetPassword(value)
      setPage(PAGE_MAP.SUCCESS)
    } catch (err) {
      if (err.response) {
        setError(err.response.data.error || err.response.data.message)
      }
    }
    setLoading(false)
  }

  const handleChange = ev => {
    setValue(st => ({
      ...st,
      [ev.target.name]: ev.target.value,
    }))
    setError(null)
  }

  useEffect(() => {
    const email = getParameterByName('email', location.search)
    const token = getParameterByName('token', location.search)
    setValue(st => ({
      ...st,
      email,
      token,
    }))
  }, [location.search])

  return page === PAGE_MAP.INIT ? (
    <CardPage title={t('Reset password')} bottomComp={<InitBottomComp onSubmit={handleSubmit} disabled={(value.password !== value.confirmPassword) || !value.password} t={t} />}>
      <div className="account-inputs account-container">
        <PasswordTips password={value.password} showSuccess />
        <Input
          label={t('Enter new password')}
          variant="filled"
          name="password"
          value={value.password}
          onChange={handleChange}
          error={!!error}
          inputProps={{
            type: 'password',
            autoComplete: 'new-password',
          }}
        />
        <span>{t('(minimum of 8 characters in length)')}</span>
        <Input
          label={t('Confirm new password')}
          variant="filled"
          name="confirmPassword"
          value={value.confirmPassword}
          onChange={handleChange}
          error={!!error}
          inputProps={{
            type: 'password',
            autoComplete: 'new-password',
          }}
        />
        <span>{t('(minimum of 8 characters in length)')}</span>
        { !!error && <FormHelperText error>{error}</FormHelperText> }
      </div>
      { loading && <Loading /> }
    </CardPage>
  ) : (
    <CardPage title="PASSWORD RESET SUCCESS!" headerType="success" bottomComp={<SuccessBottomComp t={t} />}>
      <div className="account-inputs account-container">
        <span className="text-center">{t('You have successfully reset your password Click the button below to login')}</span>
      </div>
    </CardPage>
  )
}

export default ResetPassword
