import React, { useState, useMemo } from 'react'
import {
  Link, useHistory,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import CardPage from '../../../../components/Pages/CardPage'
import GreenTitle from '../../../../components/Titles/GreenTitle'
import TextButton from '../../../../components/Buttons/TextButton'
import Loading from '../../../../components/Loading'
import EditSvg from '../../../../svg/EditSvg'
import apis from '../../../../apis'
import { setError } from '../../../../redux/action/pageAction'
import { setUser } from '../../../../redux/action/userAction'
import Orders from '../Orders'
import Order from '../Order'
import '../../Account.scss'
import { SYMBOL_REGEX, LOWER_REGET, UPPER_REGEX } from '../../../../constant'

function Info() {
  const { t } = useTranslation()
  const [show, setShow] = useState({
    password: false,
    confirmPassword: false,
  })

  const user = useSelector(st => st.user.data)
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState(null)
  const history = useHistory()
  const [edit, setEdit] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    password: '',
    confirmPassword: '',
  })
  const dp = useDispatch()
  const [showEdit, setShowEdit] = useState(false)

  const handleChange = ev => {
    setEdit(st => ({
      ...st,
      [ev.target.name]: ev.target.value,
    }))
  }

  const handleSave = async () => {
    if (edit.email === user.email
      && edit.firstName === user.firstName && edit.lastName === user.lastName && !edit.password) {
      setShowEdit(false)
    } else {
      setLoading(true)
      try {
        const res = await apis.user.update({
          ...edit,
        })
        dp(setUser(res.data.data))
        setLoading(false)
      } catch (err) {
        if (err.response) {
          dp(setError(err.response.data))
        }
        setLoading(false)
      }
      // dp(setError())
      setShowEdit(false)
    }
  }

  const handleLogout = async () => {
    setLoading(true)
    await apis.user.logout()
    localStorage.removeItem('bpmarket_token')
    dp(setUser(null))
    history.push('/')
    setLoading(false)
  }

  const passwordError = useMemo(() => {
    if (edit.password) {
      if (edit.password.length < 8) return 'At least 8 characters long'
      if (!LOWER_REGET.test(edit.password)) return 'One lowercase character'
      if (!UPPER_REGEX.test(edit.password)) return 'One uppercase character'
      if (!SYMBOL_REGEX.test(edit.password)) return 'One number, symbol, no whitespace'
    }

    return ''
  }, [edit.password])

  return order
    ? (
      <CardPage title={t('My account')}>
        <Order order={order} onBack={() => setOrder(null)} />
      </CardPage>
    )
    : (
      <CardPage title={t('My account')}>
        <div className="account-info">
          {
            showEdit ? (
              <section>
                <GreenTitle rightComp={(
                  <div>
                    <TextButton onClick={() => setShowEdit(false)}>
                      {t('Cancel')}
                    </TextButton>
                    <TextButton
                      onClick={handleSave}
                      disabled={!edit.firstName || !edit.lastName || !edit.email}
                    >
                      {t('Save')}
                    </TextButton>
                  </div>
                )}
                >
                  {t('Customer info')}
                </GreenTitle>
                <div className="account-content">
                  <div className="account-form">
                    <Input placeholder="First Name*" name="firstName" value={edit.firstName} onChange={handleChange} />
                    <Input placeholder="Last Name*" name="lastName" value={edit.lastName} onChange={handleChange} />
                    <Input placeholder="Email*" name="email" value={edit.email} onChange={handleChange} />
                    <TextField
                      placeholder="Password"
                      name="password"
                      value={edit.password || ''}
                      onChange={handleChange}
                      inputProps={{
                        type: show.password ? 'text' : 'password',
                        autoComplete: 'new-password',
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="toggle password visibility"
                              onClick={() => setShow(s => ({ ...s, password: !s.password }))}
                            >
                              {show.password ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={!!passwordError}
                      helperText={passwordError}
                    />
                    <TextField
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      style={{ marginBottom: 0 }}
                      value={edit.confirmPassword || ''}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="toggle password visibility"
                              onClick={
                                () => setShow(s => ({ ...s, confirmPassword: !s.confirmPassword }))
                              }
                            >
                              {show.confirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={edit.password && edit.password !== edit.confirmPassword}
                      helperText={edit.password && edit.password !== edit.confirmPassword ? 'Passwords dont match' : null}
                      inputProps={{
                        type: show.confirmPassword ? 'text' : 'password',
                        autoComplete: 'new-password',
                      }}
                    />
                  </div>
                </div>
              </section>
            ) : (
              <section>
                <GreenTitle rightComp={(
                  <div className="account-right-comp">
                    <TextButton onClick={() => setShowEdit(true)}>
                      <EditSvg />
                    </TextButton>
                    <TextButton
                      onClick={handleLogout}
                    >
                      {t('Logout')}
                    </TextButton>
                  </div>
                )}
                >
                  {t('Customer info')}
                </GreenTitle>
                <div className="account-content">
                  <p>{`First Name: ${user.firstName}`}</p>
                  <p>{`Last Name: ${user.lastName}`}</p>
                  <p>{`Email: ${user.email}`}</p>
                </div>
              </section>
            )
          }
          <section>
            <GreenTitle>{t('Address book')}</GreenTitle>
            <div className="account-content">
              <p>{t('View and manage addresses, edit your contact address and add new addresses')}</p>
              <Link to="/account/address">{t('View address book')}</Link>
            </div>
          </section>
          <section>
            <Orders onClick={setOrder} />
          </section>
        </div>
        {loading && <Loading />}
      </CardPage>
    )
}

export default Info
