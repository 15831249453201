import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { FormHelperText, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FormattedNumber } from 'react-intl'
import { selectArea } from '../../redux/action/areaAction'
import { selectShipMethod, checkCart } from '../../redux/action/orderAction'
import { checkProducts } from '../../redux/action/productAction'
import useCart from '../../hooks/useCart'
import Button from '../Buttons/Button'
import MoreDialog from '../Dialog/MoreDialog'
import EditSvg from '../../svg/EditSvg'
import SearchSvg from '../../svg/SearchSvg'

const useStyles = makeStyles(theme => ({
  inner: {
    background: '#fff',
  },
  title: {
    color: theme.palette.success.main,
    margin: 0,
    fontFamily: 'FuturaStd-CondensedBold',
    padding: '15px 20px 12px',
    fontSize: 24,
    borderBottom: '1px solid #ccc',
    textTransform: 'uppercase',
  },
  btns: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 20px',
    borderBottom: '1px solid #ccc',
    minHeight: 'min-content',
  },
  btn: {
    background: 'white',
    minHeight: 50,
    '& + &': {
      marginTop: 20,
    },
  },
  detail: {
    padding: '30px 20px',
    '& > div': {
      padding: '5px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& h6, & span': {
      margin: 0,
      fontFamily: 'FuturaStd-Medium',
      fontSize: 24,
      color: theme.palette.secondary.main,
      fontWeight: 500,
    },
  },
  strong: {
    marginTop: 30,
    '& h6, & span': {
      fontFamily: 'FuturaStd-Bold',
      fontSize: 24,
    },
    '& h6': {
      textTransform: 'uppercase',
    },
  },
  items: {
    padding: 20,
    paddingBottom: 0,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '28px',
  },
  combo: {
    fontSize: 16,
    lineHeight: '28px',
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& $editBtns': {
      padding: 0,
      paddingBottom: 20,
      marginTop: 10,
    },
  },
  editBtns: {
    padding: 20,
    paddingTop: 10,
    '& button': {
      textTransform: 'uppercase',
      fontSize: 14,
      lineHeight: '19px',
      fontFamily: 'FuturaStd-Bold',
      background: 'transparent',
      padding: 0,
      border: 0,
      textDecoration: 'underline',
      marginRight: 20,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
  checkoutBtn: {
    position: 'sticky',
    bottom: 0,
    marginTop: 'auto',
    height: 60,
    flex: '0 0 60px',
  },
  gifts: {
    borderTop: '1px solid #ccc',
    padding: 20,
  },
  zipCodeArea: {
    padding: 20,
    borderBottom: '1px solid #ccc',
  },
  zipCodeTop: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      color: 'var(--success-main)',
      padding: 0,
      border: 0,
      background: 'transparent',
      marginLeft: 15,
    },
    '& h6': {
      margin: 0,
      fontSize: 16,
    },
  },
  zipCodeEdit: {
    '&.error': {
      '& input': {
        border: `2px solid ${theme.palette.error.main}`,
        color: theme.palette.error.main,
      },
      '& > button': {
        color: theme.palette.error.main,
      },
    },
    '& $errorMsg': {
      fontSize: 16,
      margin: 0,
    },
  },
  zipCodeEditInput: {
    position: 'relative',
    marginTop: 30,
    '& input': {
      border: `2px solid ${theme.palette.secondary.main}`,
      padding: '17px 16px',
      height: 76,
      fontSize: 16,
      width: '100%',
    },
    '& svg': {
      position: 'absolute',
      top: '50%',
      right: 16,
      transform: 'translateY(-50%)',
    },
  },
  additional: {
    paddingLeft: 26,
  },
}))

const CartItems = ({
  classes, title, items, lang, onRemoveAll, onEdit, t,
}) => (
  <>
    <h3 className={classes.title}>{title}</h3>
    <div className={classes.items}>
      {items.map(item => (
        <div key={item.code}>
          <div className={classes.item}>
            <div>
              {item.picked > 1 && <span>{`${item.picked}x `}</span>}
              {(lang === 'tw' && item.nameTw) || item.name}
            </div>
            <FormattedNumber
              style="currency"
              value={(item.price + item.addPrices) * item.picked}
              currency="USD"
            />
          </div>
          {!!item.additional && (
            <div className={classes.additional}>
              {Object.values(item.additional).filter(add => add.picked).map(add => (
                <div key={`${item.code}-${add.data.code}`}>
                  {add.picked > 1 && <span>{`${add.picked}x `}</span>}
                  {(lang === 'tw' && add.data.nameTw) || add.data.name}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
    <div className={classes.editBtns}>
      <button type="button" onClick={onRemoveAll}>
        {t('Remove all')}
      </button>
      <button type="button" onClick={onEdit}>
        {t('Edit')}
      </button>
    </div>
  </>
)
function CartList({
  combo,
  addons,
  foodmart,
  subtotal,
  shipping,
  tax,
  total,
  gifts,
  onPageChange,
}) {
  const { t, i18n } = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const { removeAllCart, removeCart, duplicateCart } = useCart()
  const areas = useSelector(st => st.area.data)
  const orderShipping = useSelector(st => st.order.shipping)
  const dp = useDispatch()
  const [showZipcode, setShowZipcode] = useState(false)
  const [zipEditCode, setZipEditCode] = useState('')
  const [zipEditError, setZipEditError] = useState(false)
  const { language } = i18n
  const { zipCode } = useSelector(st => st.order)
  const history = useHistory()
  const classes = useStyles()

  const handleCancel = () => {
    setShowZipcode(false)
  }
  const handleEdit = path => {
    history.push(`${path}?edit`)
    onPageChange()
  }

  const handleRemoveAll = ev => {
    removeAllCart(ev)
  }

  const handleRemove = code => {
    removeCart(code, 'combo')
  }

  const handleDuplicate = code => {
    duplicateCart(code, 'combo')
  }

  const handleZipCodeChange = ev => {
    setZipEditCode(ev.target.value)
    setZipEditError(false)
  }

  const handleZipCodeSave = () => {
    const value = +zipEditCode
    const currentArea = areas.find(d =>
      d.zipCodes.some(code => {
        if (Array.isArray(code)) {
          return value >= code[0] && value <= code[1]
        }
        return value === code
      }))

    if (currentArea) {
      dp(
        selectArea({
          id: currentArea.id,
          code: currentArea.code,
          name: currentArea.name,
          nameTw: currentArea.nameTw,
          minSubtotal: currentArea.minSubtotal,
          shippings: currentArea.shippings,
        }),
      )
      dp(
        selectShipMethod(
          currentArea.shippings.find(s => s.id === orderShipping?.id) || null,
          value,
        ),
      )
      dp(checkProducts())
      dp(checkCart())
      setZipEditCode('')
      setShowZipcode(false)
    } else {
      setZipEditError(true)
    }
  }

  const handleCheckout = () => {
    setShowMore(true)
  }

  useEffect(() => {
    dp(checkProducts())
  }, [dp])
  return (
    <>
      <div className={classes.root}>
        <div className={classes.inner}>
          {combo.length > 0 && (
            <>
              <h3 className={classes.title}>Hot pot kit</h3>
              <div className={classes.items}>
                {combo.map((item, i) =>
                  [...new Array(item.picked)].map((_, j) => (
                    <div key={`${i}-${j}`} className={classes.combo}>
                      <div>
                        <div>
                          {(language === 'tw' && item.meatNameTw)
                            || item.meatName}
                        </div>
                        <FormattedNumber
                          style="currency"
                          value={item.meatPrice}
                          currency="USD"
                        />
                      </div>
                      <div>
                        <div>
                          {`w/ ${
                            (language === 'tw' && item.nameTw) || item.name
                          }`}
                        </div>
                        <FormattedNumber
                          style="currency"
                          value={item.comboPrice}
                          currency="USD"
                        />
                      </div>
                      <div>
                        <div className={classes.editBtns}>
                          <button
                            type="button"
                            onClick={() => handleRemove(item.code)}
                          >
                            {t('Remove')}
                          </button>
                          <button
                            type="button"
                            onClick={() => handleDuplicate(item.code)}
                          >
                            {t('Duplicate')}
                          </button>
                        </div>
                      </div>
                    </div>
                  )))}
              </div>
            </>
          )}
          {addons.length > 0 && (
            <CartItems
              title="Add-ons"
              classes={classes}
              items={addons}
              lang={language}
              t={t}
              onRemoveAll={() => handleRemoveAll('addons')}
              onEdit={() => handleEdit('add-ons')}
            />
          )}
          {foodmart.length > 0 && (
            <CartItems
              title={t('Food mart')}
              classes={classes}
              items={foodmart}
              lang={language}
              t={t}
              onRemoveAll={() => handleRemoveAll('foodmart')}
              onEdit={() => handleEdit('food-mart')}
            />
          )}
          {gifts.length > 0 && (
            <div className={classes.gifts}>
              {gifts.map(gift => (
                <div key={gift.code} className={classes.item}>
                  <div>{`GIFT: ${gift.name}`}</div>
                  <span>{gift.picked}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={classes.btns}>
          <Button
            className={classes.btn}
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={onPageChange}
            component={Link}
            to="/hot-pot"
          >
            {t('Order hot pot kit')}
          </Button>
          <Button
            className={classes.btn}
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={onPageChange}
            component={Link}
            to="/add-ons"
          >
            {t('Shop addons')}
          </Button>
          <Button
            className={classes.btn}
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={onPageChange}
            component={Link}
            to="/food-mart"
          >
            {t('Shop food mart')}
          </Button>
        </div>
        <div className={classes.zipCodeArea}>
          {showZipcode ? (
            <>
              <div className={classes.zipCodeTop}>
                <h6>
                  {t('Delivery zip')}
                  :
                </h6>
                <div>
                  <button type="button" onClick={handleCancel}>
                    {t('Cancel')}
                  </button>
                  <button type="button" onClick={handleZipCodeSave}>
                    {t('Save')}
                  </button>
                </div>
              </div>
              <div
                className={clsx(classes.zipCodeEdit, { error: zipEditError })}
              >
                <div className={classes.zipCodeEditInput}>
                  <input
                    placeholder={t('Enter new zip code')}
                    name="zipEditCode"
                    value={zipEditCode || ''}
                    onChange={handleZipCodeChange}
                    type="number"
                  />
                  <SearchSvg />
                </div>
                <FormHelperText error>
                  {t(
                    'Prices and products are subject to change based on valid zip code',
                  )}
                </FormHelperText>
              </div>
            </>
          ) : (
            <>
              <div className={classes.zipCodeTop}>
                <h6>
                  {t('Delivery zip')}
                  :
                </h6>
                <button
                  type="button"
                  className={classes.editBtn}
                  onClick={() => setShowZipcode(true)}
                >
                  <EditSvg />
                </button>
              </div>
              <span>{zipCode}</span>
            </>
          )}
        </div>
        <div className={classes.detail}>
          <div>
            <h6>{t('Subtotal')}</h6>
            <FormattedNumber style="currency" value={subtotal} currency="USD" />
          </div>
          <div>
            <h6>{t('Delivery fee')}</h6>
            <FormattedNumber style="currency" value={shipping} currency="USD" />
          </div>
          <div>
            <h6>{t('Tax')}</h6>
            <FormattedNumber style="currency" value={tax} currency="USD" />
          </div>
          <div className={classes.strong}>
            <h6>{t('Total')}</h6>
            <FormattedNumber
              style="currency"
              value={total + tax}
              currency="USD"
            />
          </div>
        </div>
        <Button
          className={classes.checkoutBtn}
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleCheckout}
          fullWidth
        >
          <span>
            {t('Checkout')}
            (
          </span>
          <FormattedNumber style="currency" value={total} currency="USD" />
          <span>)</span>
        </Button>
      </div>
      <MoreDialog
        open={showMore}
        onClose={() => setShowMore(false)}
        onClick={onPageChange}
        gap={50}
      />
    </>
  )
}

export default CartList
