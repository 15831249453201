import types from '../actionTypes/orderTypes'
import axios from '../../apis/axios'

const checkBundle = item => {
  if (!item.additional) return false
  const total = Object.values(item.additional)
    .filter(add => add.picked <= Math.min(add.data.pivot.quantityMax, add.data.quantity))
    .reduce((acc, cur) => acc + cur.picked, 0)
  if (total !== item.data.comboSize) return false
  return true
}
const checkItems = (items, products, areaId) => {
  const success = {}
  const failed = []
  Object.keys(items).forEach(key => {
    const item = items[key]
    const current = products.find(product => product.code === item.data.code)
    // 如果該區域 disable 此產品
    // 如果數量小於選取數量
    // 如果該區域沒設價錢
    if (!current?.areaActive
      || current.quantity < item.picked
      || !+current.price) {
      failed.push(item)
    } else if (!current.available) {
      failed.push(item)
    } else if (current.type === 'bundle' && !checkBundle(item)) {
      failed.push(item)
    } else {
      const productPrice = areaId
        ? +item.data.priceVariants[areaId].specialPrice
          || +item.data.priceVariants[areaId].price : 0
      const meatPrice = areaId && item.data.meat
        ? +item.data.meat.priceVariants[areaId].specialPrice
        || +item.data.meat.priceVariants[areaId].price : 0

      success[key] = {
        ...item,
        ...(!!areaId && {
          data: {
            ...item.data,
            specialPrice: item.data.priceVariants[areaId].specialPrice,
            price: item.data.priceVariants[areaId].price,
            ...(!!item.data.meat && {
              meat: {
                ...item.data.meat,
                specialPrice: item.data.meat.priceVariants[areaId].specialPrice,
                price: item.data.meat.priceVariants[areaId].price,
              },
              comboPrice: (productPrice - meatPrice).toFixed(2),
            }),
          },
        }),
      }
    }
  })

  return {
    failed,
    success,
  }
}

export const selectShipMethod = (shipping, zipCode) => dispatch => {
  dispatch({
    type: types.SELECT_SHIP_METHOD,
    shipping,
    zipCode,
  })
}

export const updateShipMethod = shipping => dispatch => {
  dispatch({
    type: types.UPDATE_SHIP_METHOD,
    shipping,
  })
}

export const updateZipCode = zipCode => dispatch => {
  dispatch({
    type: types.UPDATE_ZIPCODE,
    zipCode,
  })
}

export const updateTaxRate = (stateInput, zipCodeInput) => dispatch => {
  const options = {
    method: 'POST',
    url: 'https://sales-tax-calculator.p.rapidapi.com/rates',
    headers: {
      'content-type': 'application/json',
      'x-rapidapi-host': 'sales-tax-calculator.p.rapidapi.com',
      'x-rapidapi-key': 'dc297fcbbemsh51915f7cb0ffddcp19c06ejsnda9f9b14b517',
    },
    data: { state: stateInput, zip: zipCodeInput.toString() },
  }

  axios.request(options).then(response => {
    dispatch({
      type: types.UPDATE_TAXRATE,
      taxRate: +response.data.tax_rate / 100,
      isCorrectTaxRate: true,
    })
  }).catch(() => {
    dispatch({
      type: types.UPDATE_TAXRATE,
      taxRate: 0,
      isCorrectTaxRate: false,
    })
  })
}

export const addToCart = (items, name, isOverride = false) => ({
  type: types.ADD_TO_CART,
  items,
  name,
  isOverride,
})

export const duplicateCart = (code, name) => ({
  type: types.DUPLICATE_CART,
  name,
  code,
})

export const removeCart = (code, name) => ({
  type: types.REMOVE_CART,
  name,
  code,
})

export const removeAllCart = name => ({
  type: types.REMOVE_ALL_CART,
  name,
})

export const setOrder = order => ({
  type: types.SET_ORDER,
  order,
})

export const clearOrder = () => ({
  type: types.CLEAR_ORDER,
})

export const clearCart = () => ({
  type: types.CLEAR_CART,
})

export const checkCart = () => (dispatch, getState) => {
  const { products } = getState().product
  const { area } = getState().area
  const { combo, addons, foodmart } = getState().order

  const checkCombo = checkItems(combo, products, area?.id)
  const checkAddons = checkItems(addons, products, area?.id)
  const checkFoodmart = checkItems(foodmart, products, area?.id)

  const failed = [...checkCombo.failed, ...checkAddons.failed, ...checkFoodmart.failed]
  dispatch({
    type: types.CHECK_CART,
    combo: checkCombo.success,
    addons: checkAddons.success,
    foodmart: checkFoodmart.success,
    failed,
  })

  return failed
}

export const clearFailed = () => ({
  type: types.CLEAR_FAILD,
})

export const clearFailedCart = () => ({
  type: types.CLEAR_FAILD_CART,
})
