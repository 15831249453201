import React from 'react'
import {
  Switch,
  Route,
} from 'react-router-dom'
import Address from './Address'
import Info from './Info'

function Account() {
  return (
    <Switch>
      <Route path="/account/address">
        <Address />
      </Route>
      <Route path="/account">
        <Info />
      </Route>
    </Switch>
  )
}

export default Account
