import React from 'react'

function ReOrderSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.984" height="24" viewBox="0 0 23.984 24" {...props}>
      <path id="Path_86" data-name="Path 86" d="M-146.453,109.672h-10.433l4.784-4.815a8.715,8.715,0,0,0-6.278-2.723,8.97,8.97,0,0,0-8.97,8.97,8.97,8.97,0,0,0,8.97,8.97,8.846,8.846,0,0,0,8.352-5.713h3.157a12,12,0,0,1-14.775,8.339,12,12,0,0,1-8.339-14.776,12,12,0,0,1,14.776-8.339,12,12,0,0,1,5.264,3.073l3.5-3.513v10.528Z" transform="translate(170.428 -99.142)" fill="currentColor" />
    </svg>

  )
}

export default ReOrderSvg
