import React from 'react'

function LogoSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="255.973" height="50" viewBox="0 0 255.973 50" {...props}>
      <g id="BP_Market" data-name="BP Market" transform="translate(-22.379 -65.188)">
        <path id="Path_61" data-name="Path 61" d="M42.155,88.85c4.091-1.654,5.865-6.134,5.865-10.407C48.02,69.967,44,65.28,34.926,65.28H22.379v49.762H35.744c9.616,0,13.231-6.2,13.231-14.406C48.975,94.227,46.451,90.781,42.155,88.85ZM30.835,72.309h3.2c3.752,0,5.525,2.413,5.525,7.031,0,4.135-2.455,6.341-5.66,6.341H30.835Zm3.545,35.7H30.835V92.71h2.726c5.046,0,6.957,2.551,6.957,7.652C40.519,105.393,38.883,108.01,34.38,108.01Z" transform="translate(0 0.146)" fill="#1a1818" />
        <path id="Path_62" data-name="Path 62" d="M46.309,65.28H34.168v49.762h8.456v-20.4h3.684c10.432,0,15.274-4.067,15.274-14.68S56.741,65.28,46.309,65.28ZM45.9,87.609H42.624v-15.3H45.9c5.525,0,7.229,1.724,7.229,7.652S51.422,87.609,45.9,87.609Z" transform="translate(18.735 0.146)" fill="#1a1818" />
        <path id="Path_63" data-name="Path 63" d="M66.686,94.366h-.136l-6-29.086H49.432v49.763H56.8V76.169h.136l7.639,38.873h4.092L76.3,76.169h.137v38.873H83.8V65.28H72.688Z" transform="translate(43.854 0.146)" fill="#7ab62d" />
        <path id="Path_64" data-name="Path 64" d="M103.086,79.96c0-10.614-4.839-14.68-14.321-14.68H76.9v49.762h8.459v-20.4a40.107,40.107,0,0,0,4.363-.207l6,20.608h8.591L97.154,92.505C100.021,91.194,103.086,87.955,103.086,79.96ZM88.359,87.609h-3v-15.3h3c4.567,0,6.274,1.724,6.274,7.652S92.926,87.609,88.359,87.609Z" transform="translate(88.324 0.146)" fill="#7ab62d" />
        <path id="Path_65" data-name="Path 65" d="M115.3,65.28h-8.458l-8.866,18.4h-.134V65.28H89.385v49.763h8.458V97.4l3-5.515h.134l6.889,23.159h8.592l-10.3-31.223Z" transform="translate(108.548 0.146)" fill="#7ab62d" />
        <path id="Path_66" data-name="Path 66" d="M109.168,92.987h10.91V85.544h-10.91V72.723h14.184V65.28H100.71v49.763h23.6V107.6h-15.14Z" transform="translate(127.174 0.146)" fill="#7ab62d" />
        <path id="Path_67" data-name="Path 67" d="M110.615,72.723H118.8v42.319h8.455V72.723h8.184V65.28H110.615Z" transform="translate(142.914 0.146)" fill="#7ab62d" />
        <path id="Path_68" data-name="Path 68" d="M72.018,88.344l.443,4.1h8.21L78.3,79.129A16.446,16.446,0,0,1,72.018,88.344Z" transform="translate(80.299 22.68)" fill="#7ab62d" />
        <path id="Path_69" data-name="Path 69" d="M89.405,91.787l-4.85-26.6H74.017L69.629,89.27a19.118,19.118,0,0,1,7.76-2.342l1.831-11.781h.137l2.051,13.115a24.82,24.82,0,0,1-4.44,1.942v.005l-.062.018-.049.018,0-.005C70.2,92.033,67.7,94.132,67.7,94.132c-.1.081-.2.16-.3.243-.065.06-.124.123-.189.183-.106.1-.215.2-.318.311-.057.063-.109.128-.166.191-.1.115-.207.23-.306.353-.15.188-.293.387-.43.594s-.256.408-.375.615c-.041.068-.075.139-.114.207-.078.139-.153.275-.223.413-.044.084-.08.167-.119.251-.062.126-.124.251-.179.379-.041.092-.075.181-.114.272-.049.12-.1.243-.145.366-.036.094-.07.188-.1.285-.041.118-.083.238-.119.359-.031.1-.06.2-.088.3q-.054.177-.1.353c-.026.1-.049.2-.075.3-.026.118-.054.233-.078.351-.023.1-.041.207-.062.311s-.041.23-.06.348-.034.209-.047.317-.031.23-.044.345-.023.215-.034.322-.021.228-.028.34-.016.22-.021.33-.01.225-.013.335-.005.225-.008.335v.33c0,.115,0,.23.005.343s.008.215.013.319.01.236.018.353c.005.1.016.2.023.306.008.123.018.243.028.366l.031.28c.016.131.028.259.044.39.01.076.023.152.034.23.106.764.251,1.526.425,2.277l.021.092c.039.167.083.335.124.5l.036.144c.041.16.085.319.129.476l.044.165c.044.154.088.309.135.461l.049.173.14.453.054.173c.049.149.217,1.159.267,1.306l.057.17c.052.149.1.3.153.442l.057.165c.054.149.106.3.161.44l.057.157q.082.224.163.44l.054.144.171.442.052.131c.031.078.049-.856.08-.777a26.436,26.436,0,0,1,.181-3.912,18.193,18.193,0,0,1,1.163-4.289,15.879,15.879,0,0,1,2.247-3.813,12.689,12.689,0,0,1,3.247-2.96A22.3,22.3,0,0,1,77.2,97.5a23.694,23.694,0,0,0,3.848-1.743,8.305,8.305,0,0,0,1.357-1.083l.028.175a8.62,8.62,0,0,1-1.248,1.12,24.225,24.225,0,0,1-3.788,2A21.451,21.451,0,0,0,73.733,100a12.02,12.02,0,0,0-2.871,2.936c-1.629,2.245-2,4.26-2.258,7.01a30.963,30.963,0,0,0,.109,5.014c3.666-5.694,9.738-4.6,14.367-7C89.817,103.659,89.522,93.632,89.405,91.787Z" transform="translate(67.169 0)" fill="#7ab62d" />
      </g>
    </svg>

  )
}

export default LogoSvg
