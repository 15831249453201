import React from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import CardPage from '../../../../components/Pages/CardPage'
import TextButton from '../../../../components/Buttons/TextButton'
import GreenTitle from '../../../../components/Titles/GreenTitle'
import Button from '../../../../components/Buttons/Button'
import EditSvg from '../../../../svg/EditSvg'
import TrashSvg from '../../../../svg/TrashSvg'

import AddressFields from './AddressFields'
import { PHONE_NO_REGEX } from '../../../../constant'

const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  address: yup.string().required('Address is required'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
  zipCode: yup.string().required('Zip Code is required'),
  phone: yup.string().test('format', 'Phone number is not valid', val => (val ? val.match(PHONE_NO_REGEX) : true)),
})

const BottomComp = ({ t }) => (
  <div className="account-bottom">
    <Button
      className="account-bottom-btn"
      variant="contained"
      color="primary"
      disableElevation
      type="submit"
    >
      {t('Save')}
    </Button>
  </div>
)

function AddressCreateForm({
  addressBooks, onSave, onEdit, onDelete, onMakeDefault,
}) {
  const { t } = useTranslation()

  const {
    register, handleSubmit, reset, control, errors,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async data => {
    onSave(data)
    reset()
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <CardPage title={t('Address book')} bottomComp={<BottomComp t={t} />}>
        <div className="account-info">
          {addressBooks.length > 0 && (
            <section>
              <div className="address-cards">
                {
                  addressBooks.map((addressBook, i) => (
                    <div key={addressBook.id} className={`address-card ${addressBook.isDefault ? 'active' : ''}`}>
                      <p>{`${t('Address')}: ${i + 1}`}</p>
                      <p>{`${t('Name')}: ${addressBook.firstName || ''} ${addressBook.lastName || ''}`}</p>
                      <p>{`${t('Email')}: ${addressBook.email || ''}`}</p>
                      <p>{`${t('Address')}: ${addressBook.address || ''}`}</p>
                      <p>{`${t('Apartment')}: ${addressBook.apartment || ''}`}</p>
                      <p>{`${addressBook.city || ''}, ${addressBook.state || ''} ${addressBook.zipCode || ''}`}</p>
                      <div className="address-buttons">
                        <TextButton
                          onClick={() => onMakeDefault(addressBook)}
                        >
                          {t('Make default')}
                        </TextButton>
                        <div className="address-edit-btn">
                          <TextButton onClick={() => onEdit(addressBook)}><EditSvg /></TextButton>
                          <TextButton onClick={() => onDelete(addressBook)}>
                            <TrashSvg />
                          </TextButton>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </section>

          )}
          <section>
            <GreenTitle>{t('Add new address')}</GreenTitle>
            <div className="account-form account-container">
              <span className="text-center account-note">{t('Required fields are marked with an asterisk')}</span>
              <AddressFields register={register} errors={errors} control={control} />
            </div>
          </section>
        </div>
      </CardPage>
    </form>
  )
}

export default AddressCreateForm
