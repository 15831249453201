import { useEffect, Suspense, useRef } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { PersistGate } from 'redux-persist/integration/react'
import debounce from 'lodash/debounce'
import ReactGA from 'react-ga4'
import {
  BrowserRouter as Router,
} from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { IntlProvider } from 'react-intl'
import { store, persistor } from './redux/store'
import Layout from './components/Layout'
import theme from './styles/theme'
import setCSSVariables from './utils/setCSSVariables'
import RouterView from './RouterView'
import apis from './apis'

import './assets/scss/layout.scss'
import './i18n'

ReactGA.initialize(process.env.REACT_APP_GA)

function App() {
  const directRef = useRef(0)

  useEffect(() => {
    setCSSVariables({
      primary: theme.palette.primary,
      secondary: theme.palette.secondary,
      info: theme.palette.info,
      error: theme.palette.error,
      success: theme.palette.success,
      warning: theme.palette.warning,
    })
  }, [])

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()

    return () => {
      window.removeEventListener('resize', appHeight)
    }
  }, [])

  useEffect(() => {
    const loadCsrf = async () => {
      await apis.common.getCookies()
    }

    loadCsrf()
  }, [])

  useEffect(() => {
    const handleScroll = debounce(ev => {
      const locationBtn = document.getElementById('location-btn')
      const header = document.getElementById('main-header')
      const navHeader = document.getElementById('nav-header')

      const offset = window.innerWidth < 1200 ? 97 : 123
      const offsetTop = ev.target?.scrollingElement?.scrollTop || 0
      const inactive = offsetTop > directRef.current && offsetTop > offset
      if (inactive) {
        locationBtn?.classList.add('is-hide')
        header?.classList.add('is-hide')
        navHeader?.classList.add('is-hide')
      } else {
        locationBtn?.classList.remove('is-hide')
        header?.classList.remove('is-hide')
        navHeader?.classList.remove('is-hide')
      }
      directRef.current = offsetTop
    }, 30)

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <IntlProvider locale="en" defaultLocale="en">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Router>
              <Suspense fallback={<LinearProgress />}>
                <Layout>
                  <RouterView />
                </Layout>
              </Suspense>
            </Router>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </IntlProvider>
  )
}
export default App
