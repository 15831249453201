import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ButtonBase from '@material-ui/core/ButtonBase'

import { useTranslation } from 'react-i18next'
import QRCodeDialog from '../Dialog/QRCodeDialog'

const LineIcon = ({ className }) => (
  <svg viewBox="0 0 500 500" className={className}>
    <path
      className="st2"
      d="M248.62,468.34c-118.84,0-215.53-96.69-215.53-215.53S129.78,37.27,248.62,37.27
c118.84,0,215.53,96.69,215.53,215.53S367.46,468.34,248.62,468.34z M248.62,50.69c-111.45,0-202.12,90.67-202.12,202.12
s90.67,202.12,202.12,202.12s202.12-90.67,202.12-202.12S360.07,50.69,248.62,50.69z"
      fill="currentColor"
    />
    <path
      className="st2"
      d="M376.8,245.3c0-57.13-57.28-103.61-127.68-103.61c-70.4,0-127.68,46.48-127.68,103.61
c0,51.22,45.42,94.11,106.78,102.22c4.16,0.9,9.82,2.74,11.25,6.3c1.29,3.23,0.84,8.28,0.41,11.54c0,0-1.5,9.01-1.82,10.93
c-0.56,3.23-2.57,12.62,11.06,6.88c13.63-5.74,73.53-43.3,100.32-74.13h-0.01C367.93,288.76,376.8,268.17,376.8,245.3z
  M204.07,275.85c0,1.35-1.09,2.44-2.44,2.44h-35.82h-0.05c-1.35,0-2.44-1.09-2.44-2.44v-0.04v0v-55.68c0-1.35,1.09-2.44,2.44-2.44
h9.05c1.34,0,2.44,1.1,2.44,2.44v44.23h24.38c1.34,0,2.44,1.1,2.44,2.44V275.85z M225.67,275.85c0,1.35-1.09,2.44-2.44,2.44h-9.05
c-1.35,0-2.44-1.09-2.44-2.44v-55.72c0-1.35,1.09-2.44,2.44-2.44h9.05c1.35,0,2.44,1.09,2.44,2.44V275.85z M287.31,275.85
c0,1.35-1.09,2.44-2.44,2.44h-9.01c-0.22,0-0.43-0.03-0.64-0.09c-0.01,0-0.02-0.01-0.03-0.01c-0.06-0.02-0.11-0.03-0.17-0.05
c-0.03-0.01-0.05-0.02-0.08-0.03c-0.04-0.02-0.08-0.03-0.12-0.05c-0.04-0.02-0.08-0.04-0.12-0.06c-0.02-0.01-0.05-0.03-0.07-0.04
c-0.05-0.03-0.1-0.06-0.15-0.09c-0.01-0.01-0.02-0.01-0.03-0.02c-0.24-0.16-0.45-0.37-0.63-0.61l-25.53-34.47v33.09
c0,1.35-1.09,2.44-2.44,2.44h-9.05c-1.35,0-2.44-1.09-2.44-2.44v-55.72c0-1.35,1.09-2.44,2.44-2.44h9.01c0.03,0,0.06,0,0.08,0
c0.04,0,0.09,0,0.13,0.01c0.04,0,0.09,0.01,0.13,0.02c0.03,0,0.07,0.01,0.1,0.02c0.05,0.01,0.1,0.02,0.14,0.03
c0.03,0.01,0.06,0.01,0.09,0.02c0.05,0.01,0.1,0.03,0.15,0.05c0.03,0.01,0.05,0.02,0.08,0.03c0.05,0.02,0.1,0.04,0.14,0.06
c0.03,0.01,0.05,0.02,0.08,0.04c0.05,0.02,0.09,0.05,0.13,0.07c0.02,0.01,0.05,0.03,0.07,0.05c0.04,0.03,0.09,0.06,0.13,0.09
c0.02,0.02,0.04,0.03,0.07,0.05c0.05,0.04,0.09,0.07,0.13,0.11c0.02,0.01,0.03,0.03,0.05,0.04c0.05,0.05,0.1,0.1,0.15,0.15
c0.01,0.01,0.01,0.01,0.02,0.02c0.07,0.08,0.14,0.16,0.2,0.25l25.49,34.43v-33.1c0-1.35,1.09-2.44,2.44-2.44h9.05
c1.35,0,2.44,1.09,2.44,2.44V275.85z M336.76,229.18c0,1.35-1.09,2.44-2.44,2.44h-24.38v9.4h24.38c1.34,0,2.44,1.1,2.44,2.44v9.05
c0,1.35-1.09,2.44-2.44,2.44h-24.38v9.4h24.38c1.34,0,2.44,1.1,2.44,2.44v9.05c0,1.35-1.09,2.44-2.44,2.44H298.5h-0.05
c-1.35,0-2.44-1.09-2.44-2.44v-0.04v0v-55.62v0v-0.05c0-1.35,1.09-2.44,2.44-2.44h0.05h35.82c1.34,0,2.44,1.1,2.44,2.44V229.18z"
      fill="currentColor"
    />
  </svg>
)

const WeChatIcon = ({ className }) => (
  <svg viewBox="0 0 500 500" className={className}>
    <path
      d="M306.37,209.96c-26.25,1.37-49.08,9.33-67.62,27.31c-18.73,18.17-27.27,40.42-24.94,68.02
      c-10.26-1.27-19.61-2.67-29.01-3.46c-3.25-0.27-7.1,0.12-9.85,1.67c-9.13,5.15-17.88,10.97-28.25,17.45
      c1.9-8.61,3.13-16.14,5.32-23.39c1.6-5.33,0.86-8.29-4.05-11.76c-31.52-22.25-44.8-55.55-34.86-89.83
      c9.2-31.71,31.78-50.95,62.48-60.97c41.89-13.68,88.97,0.27,114.44,33.53C299.24,180.53,304.88,194.01,306.37,209.96z
        M185.55,199.28c0.24-6.27-5.19-11.92-11.64-12.11c-6.61-0.19-12.04,4.86-12.23,11.37c-0.19,6.6,4.85,11.89,11.51,12.06
      C179.77,210.76,185.3,205.7,185.55,199.28z M248.59,187.17c-6.48,0.12-11.95,5.64-11.84,11.93c0.12,6.53,5.49,11.63,12.15,11.55
      c6.68-0.08,11.74-5.24,11.68-11.9C260.52,192.2,255.18,187.05,248.59,187.17z"
      fill="currentColor"
    />
    <path
      d="M365.35,373.63c-8.31-3.7-15.94-9.26-24.06-10.1c-8.09-0.84-16.59,3.82-25.05,4.69
      c-25.77,2.64-48.86-4.55-67.9-22.15c-36.21-33.49-31.04-84.85,10.86-112.29c37.23-24.39,91.84-16.26,118.09,17.59
      c22.91,29.53,20.22,68.74-7.75,93.55c-8.09,7.18-11.01,13.09-5.81,22.56C364.69,369.21,364.8,371.42,365.35,373.63z
        M270.73,282.01c5.29,0.01,9.65-4.14,9.85-9.36c0.21-5.53-4.24-10.18-9.78-10.2c-5.49-0.03-10.08,4.68-9.89,10.13
      C261.08,277.79,265.47,282,270.73,282.01z M331.73,262.47c-5.13-0.04-9.5,4.17-9.71,9.36c-0.22,5.55,4.09,10.1,9.58,10.12
      c5.31,0.02,9.51-4,9.7-9.28C341.51,267.1,337.19,262.51,331.73,262.47z"
      fill="currentColor"
    />
    <path
      d="M248.08,467.91c-118.84,0-215.53-96.69-215.53-215.53c0-118.84,96.69-215.53,215.53-215.53
      c118.84,0,215.53,96.69,215.53,215.53C463.61,371.23,366.92,467.91,248.08,467.91z M248.08,50.26
      c-111.45,0-202.12,90.67-202.12,202.12S136.63,454.5,248.08,454.5S450.2,363.83,450.2,252.38S359.53,50.26,248.08,50.26z"
      fill="currentColor"
    />
  </svg>
)
function LayoutFooter() {
  const { t } = useTranslation()
  const [qrCode, setQrCode] = useState(null)
  return (
    <>
      <footer className="footer">
        <div className="footer-inner">
          <div className="footer-qr">
            <ButtonBase onClick={() => setQrCode('line')}>
              <LineIcon className="footer-icon" />
            </ButtonBase>
            <ButtonBase onClick={() => setQrCode('weChat')}>
              <WeChatIcon className="footer-icon" />
            </ButtonBase>
          </div>
          <nav>
            <Link to="/">Boiling Point USA</Link>
            <Link to="/policy">{t('Privacy policy')}</Link>
            <Link to="/terms">{t('Terms of use')}</Link>
            <a data-acsb-custom-trigger="true">{t('Accessibility')}</a>
          </nav>
          <h6 className="footer-copyright">{`© 2020 - ${new Date().getFullYear()} Boiling Point Group, Inc.`}</h6>
        </div>
      </footer>
      <QRCodeDialog open={!!qrCode} onClose={() => setQrCode(null)} type={qrCode} />
    </>
  )
}

export default LayoutFooter
