import React from 'react'

function SuccessListSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g id="Group_149" data-name="Group 149" transform="translate(-771 -812)">
        <g id="Ellipse_27" data-name="Ellipse 27" transform="translate(771 812)" fill="rgba(132,164,54,0.5)" stroke="#84a436" strokeWidth="1">
          <circle cx="8" cy="8" r="8" stroke="none" />
          <circle cx="8" cy="8" r="7.5" fill="none" />
        </g>
        <path id="Path_92" data-name="Path 92" d="M773.448,820.95l4.339,2.936,4.715-8.3" transform="translate(1)" fill="none" stroke="#84a436" strokeWidth="1" />
      </g>
    </svg>

  )
}

export default SuccessListSvg
