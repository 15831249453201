import orderTypes from '../actionTypes/orderTypes'

const initialState = {
  shipping: null,
  taxRate: 0,
  zipCode: null,
  combo: {},
  addons: {},
  foodmart: {},
  failed: [],
  order: null,
}

const clearItems = (items, failedItems) => {
  const failedCodes = failedItems.map(item => item.data.code)
  const availableProducts = items.filter(item =>
    !failedCodes.includes(item.data.code))

  return availableProducts.reduce((acc, cur) => ({
    ...acc,
    [cur.data.code]: cur,
  }), {})
}
export default (state = initialState, action) => {
  switch (action.type) {
  case orderTypes.CLEAR_ORDER: {
    return {
      ...state,
      order: null,
    }
  }
  case orderTypes.SET_ORDER: {
    return {
      ...state,
      order: action.order,
    }
  }
  case orderTypes.CHECK_CART: {
    return {
      ...state,
      failed: action.failed,
      combo: { ...action.combo },
      addons: { ...action.addons },
      foodmart: { ...action.foodmart },
    }
  }
  case orderTypes.CLEAR_FAILD_CART: {
    return {
      ...state,
      combo: clearItems(Object.values(state.combo), state.failed),
      addons: clearItems(Object.values(state.addons), state.failed),
      foodmart: clearItems(Object.values(state.foodmart), state.failed),
      failed: [],
    }
  }
  case orderTypes.CLEAR_FAILD: {
    return {
      ...state,
      failed: [],
    }
  }
  case orderTypes.SELECT_SHIP_METHOD: {
    return {
      ...state,
      shipping: action.shipping,
      zipCode: action.zipCode,
      taxRate: action.taxRate,
    }
  }
  case orderTypes.UPDATE_SHIP_METHOD: {
    return {
      ...state,
      shipping: action.shipping,
    }
  }
  case orderTypes.UPDATE_ZIPCODE: {
    return {
      ...state,
      zipCode: action.zipCode,
      taxRate: action.taxRate,
    }
  }
  case orderTypes.UPDATE_TAXRATE: {
    return {
      ...state,
      taxRate: action.taxRate,
      isCorrectTaxRate: action.isCorrectTaxRate,
    }
  }
  case orderTypes.ADD_TO_CART: {
    const items = action.isOverride ? action.items : state[action.name]
    Object.keys(action.items).forEach(key => {
      const addPrices = action.items[key].additional
        ? Object.values(action.items[key].additional).filter(add => add.picked).reduce(
          (acc, cur) => acc + +cur.data.pivot.addPrice * cur.picked,
          0,
        )
        : 0
      if (items[key] && !action.isOverride) {
        items[key] = {
          ...action.items[key],
          addPrices,
          picked: items[key].picked += action.items[key].picked,
        }
      } else {
        items[key] = { ...action.items[key], addPrices }
      }
    })
    return {
      ...state,
      [action.name]: { ...items },
    }
  }
  case orderTypes.DUPLICATE_CART: {
    return {
      ...state,
      [action.name]: {
        ...state[action.name],
        [action.code]: {
          ...state[action.name][action.code],
          picked: state[action.name][action.code].picked + 1,
        },
      },
    }
  }
  case orderTypes.REMOVE_CART: {
    const picked = state[action.name][action.code].picked - 1
    return {
      ...state,
      [action.name]: {
        ...state[action.name],
        [action.code]: {
          ...state[action.name][action.code],
          picked: Math.max(0, picked),
        },
      },
    }
  }
  case orderTypes.REMOVE_ALL_CART: {
    return {
      ...state,
      [action.name]: {},
    }
  }
  case orderTypes.CLEAR_CART: {
    return {
      ...state,
      combo: {},
      addons: {},
      foodmart: {},
      failed: [],
    }
  }
  default:
    return state
  }
}

export { initialState as orderInitState }
