import React, { useMemo, useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import useSWR from 'swr'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TopBanners from '../../components/Banner/TopBanners'
import SubBanner from '../../components/Banner/SubBanner'
import SubBannerWrap from '../../components/Banner/SubBanner/SubBannerWrap'
import StaticBanner from '../../components/Banner/StaticBanner'
import axios from '../../apis/axios'
import './Home.scss'

const fetcher = url => axios.get(url).then(res => res.data)

function Home() {
  const { announcement } = useSelector(st => st.common)
  const { i18n } = useTranslation()
  const { language } = i18n
  const { data } = useSWR('/api/banners', fetcher)
  const [device, setDevice] = useState()
  const banners = useMemo(() => {
    if (data) {
      return data.data.map(d => ({
        ...d,
        mobile: d.bannerItems.find(b => b.type === 'mobile'),
        tablet: d.bannerItems.find(b => b.type === 'tablet'),
        desktop: d.bannerItems.find(b => b.type === 'desktop'),
      }))
    }

    return []
  }, [data])

  useEffect(() => {
    const resizeHandler = () => {
      const width = document.body.clientWidth
      let size = 'mobile'
      if (width > 1199) size = 'desktop'
      else if (width > 599) size = 'tablet'

      setDevice(size)
    }
    resizeHandler()
    window.addEventListener('resize', resizeHandler, false)
    return () => window.removeEventListener('resize', resizeHandler, false)
  }, [])

  return (
    <div className="home-page">
      <MetaTags>
        <title>Boiling Point - BP Market</title>
        <meta name="description" content="Boiling Point - BP Market" />
        <meta property="og:title" content="Boiling Point - BP Market" />
      </MetaTags>
      <div>
        {!!announcement && (
          <div
            className="about-container"
            style={{
              backgroundColor: announcement.background,
              paddingTop: announcement.verticalPadding,
              paddingBottom: announcement.verticalPadding,
              paddingLeft: announcement.horizontalPadding,
              paddingRight: announcement.horizontalPadding,
            }}
          >
            {
              !!announcement.content && (
                <div
                  style={{ maxWidth: announcement.width, margin: '0 auto' }}
                  dangerouslySetInnerHTML={{ __html: (language === 'tw' && announcement.contentTw) || announcement.content }}
                />
              )
            }
          </div>
        )}
        <TopBanners />
      </div>
      <SubBannerWrap>
        {
          !!device && banners.map(d => (
            <SubBanner
              key={d.id}
              data={d}
              device={device}
            />
          ))
        }
      </SubBannerWrap>
      <StaticBanner />
    </div>
  )
}

export default Home
