import pageTypes from '../actionTypes/pageTypes'

const initialState = {
  error: null,
  formData: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
  case pageTypes.SET_ERROR: {
    return {
      ...state,
      error: action.error,
    }
  }
  case pageTypes.CLEAR_ERROR: {
    return {
      ...state,
      error: null,
    }
  }
  case pageTypes.SAT_DATA_CACHE: {
    return {
      ...state,
      formData: action.data,
    }
  }
  default:
    return state
  }
}

export { initialState as pageInitState }
