/* eslint-disable max-len */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Divider from '@material-ui/core/Divider'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import ReactGA from 'react-ga4'
import { yupResolver } from '@hookform/resolvers/yup'
import CardPage from '../../components/Pages/CardPage'
import CustomInfo from './Fields/CustomInfo'
import DeliveryInfo from './Fields/DeliveryInfo'
import DeliveryNote from './Fields/DeliveryNote'
import ShippingMethod from './Fields/ShippingMethod'
// import TipOurDrivers from './Fields/TipOurDrivers'
import PromoCode from './Fields/PromoCode'
import OrderDetail from './Fields/OrderDetail'
import './Checkout.scss'
import {
  PHONE_NO_REGEX,
  SYMBOL_REGEX,
  LOWER_REGET,
  UPPER_REGEX,
} from '../../constant'
import ErrorProducts from './Dialogs/ErrorProducts'
import ErrorMessages from './Dialogs/ErrorMessages'
import {
  checkCart,
  setOrder,
} from '../../redux/action/orderAction'
import {
  setDataCache,
} from '../../redux/action/pageAction'
import { setUser } from '../../redux/action/userAction'
import apis from '../../apis'
import axios from '../../apis/axios'
import useCart from '../../hooks/useCart'

const schema = (areas, isCorrectTaxRate) => yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is required')
    .when('createAccount', {
      is: true,
      then: yup
        .string()
        .test('email-check', 'The email is already in use', async value => {
          try {
            await apis.user.checkEmail(value)
            return true
          } catch (e) {
            return false
          }
        }),
    }),

  emailCheck: yup.bool().oneOf([true], 'You need to check your email'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required')
    .test(
      'state-check', 'state does not have have the zip code', (value, testContext) => {
        if (!isCorrectTaxRate) {
          return testContext.createError({
            message: 'Please check if state and zip code are both correct.',
          })
        }

        return isCorrectTaxRate
      },
    ),
  zipCode: yup.string().required('Zip code is required').test('zipCode-check', 'Zip code is invalid', value => {
    const currentArea = areas.find(d => d.zipCodes.some(code => {
      if (Array.isArray(code)) {
        return +value >= code[0] && +value <= code[1]
      }
      return +value === code
    }))

    return !!currentArea
  }),
  phone: yup
    .string()
    .matches(PHONE_NO_REGEX, 'Phone number is not valid')
    .required('Phone Number is required'),
  termCheck: yup
    .bool()
    .oneOf([true], 'You need to agree to the terms and conditions'),
  discountCode: yup.string(),
  shippingId: yup
    .number('Shipping is not valid')
    .moreThan(0, 'Shipping is not valid')
    .required('Shipping is not valid'),
  createAccount: yup.boolean(),
  tip: yup.string().test('tip-check', 'Tips cant be less than 0', value => {
    if (!value) return true
    if (+value < 0) return false
    return true
  }),
  password: yup.string().when('createAccount', {
    is: true,
    then: yup
      .string()
      .required('Password is required')
      .matches(SYMBOL_REGEX, 'One number, symbol, no whitespace')
      .matches(LOWER_REGET, 'One lowercase character')
      .matches(UPPER_REGEX, 'One uppercase character')
      .test('len', 'At least 8 characters long', val => val.length > 7),
  }),
  passwordConfirm: yup.string().when('createAccount', {
    is: true,
    then: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords dont match')
      .required('Confirm password is required'),
  }),
})

function Checkout() {
  const { t } = useTranslation()
  const user = useSelector(st => st.user.data)
  const formData = useSelector(st => st.page.formData)
  const areas = useSelector(st => st.area.data)

  const [discount, setDiscount] = useState(null)
  const [messages, setMessages] = useState(null)
  const { fetchAndCheck } = useCart()
  const history = useHistory()
  const {
    addons, foodmart, combo, isCorrectTaxRate,
  } = useSelector(st => st.order)
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    control,
    errors,
  } = useForm({
    resolver: yupResolver(schema(areas, isCorrectTaxRate)),
    defaultValues: formData ? { ...formData, termCheck: false, createAccount: false } : {},
  })
  const dp = useDispatch()
  const itemsCode = [...Object.values(addons), ...Object.values(foodmart), ...Object.values(combo)].filter(item => {
    if (item.picked) {
      return item
    }

    return false
  }).map(item => item.data.code)

  const onSubmit = async data => {
    const failed = dp(checkCart())
    dp(setDataCache(data))

    if (!failed || !failed.length) {
      try {
        const items = [
          ...Object.values(addons),
          ...Object.values(foodmart),
          ...Object.values(combo),
        ]

        if (items.length) {
          const res = await apis.order.create({
            ...data,
            email: user ? user.email : data.email,
            tip: data.tip || 0,
            items: items
              .filter(item => item.picked)
              .map(item => ({
                picked: item.picked,
                id: item.data.id,
                code: item.data.code,
                name: item.data.name,
                nameTw: item.data.nameTw,
                additional: item.additional ? Object.values(item.additional).map(add => ({
                  picked: add.picked,
                  id: add.data.id,
                  code: add.data.code,
                  name: add.data.name,
                  nameTw: add.data.nameTw,
                })) : [],
              })),
          })

          dp(setOrder(res.data.data))

          if (data.createAccount) {
            const loginRes = await apis.user.login(data.email, data.password)
            localStorage.setItem('bpmarket_token', loginRes.data.token)
            axios.defaults.headers.common.Authorization = `Bearer ${loginRes.data.token}`
            dp(setUser(loginRes.data.user))
          }

          if (res.data.data.status === 'processing') {
            const order = res.data.data
            ReactGA.event('purchase', {
              transaction_id: 'FREE',
              affiliation: 'BPMarket',
              value: +order.subtotal - +order.discount,
              currency: 'USD',
              tax: +order.tax,
              shipping: +order.shipping,
              items: order.orderItems.map(item => ({
                id: item.productCode,
                name: item.productName,
                list_name: '',
                category: item.product?.type,
                variant: '',
                list_position: 1,
                quantity: item.quantity,
                price: item.specialPrice ?? item.price,
              })),
              coupon: order.discountCode || '',
            })
            history.push('/invoice')
          } else {
            history.push('/payment')
          }
        } else {
          setMessages({
            type: 'message',
            error: t('Your cart is empty'),
          })
        }
      } catch (err) {
        if (err.response) {
          setMessages(err.response.data)
        }
      }
    }
  }

  useEffect(() => {
    fetchAndCheck()
  }, [fetchAndCheck])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <CardPage title={t('Checkout')} showBack>
          <CustomInfo
            register={register}
            errors={errors}
            createAccount={watch('createAccount')}
            password={watch('password')}
            setValue={setValue}
            firstName={watch('firstName')}
            lastName={watch('lastName')}
            email={watch('email')}
            clearErrors={clearErrors}
            user={user}
          />
          <Divider />
          <DeliveryInfo
            register={register}
            errors={errors}
            control={control}
            address={watch('address')}
            apartment={watch('apartment')}
            city={watch('city')}
            company={watch('company')}
            zipCode={watch('zipCode')}
            setError={setError}
            clearErrors={clearErrors}
            setValue={setValue}
            user={user}
            areas={areas}
            state={watch('state')}
          />
          <Divider />
          <DeliveryNote register={register} errors={errors} />
          <Divider />
          <ShippingMethod
            register={register}
            errors={errors}
            control={control}
            setValue={setValue}
            shippingId={watch('shippingId')}
          />
          <Divider />
          {/* <TipOurDrivers
            register={register}
            errors={errors}
            tip={watch('tip')}
            setValue={setValue}
          /> */}
          <Divider />
          <PromoCode
            register={register}
            errors={errors}
            itemsCode={itemsCode}
            discount={discount}
            discountCode={watch('discountCode')}
            createAccount={watch('createAccount')}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            setDiscount={setDiscount}
            user={user}
          />
          <Divider />
          <OrderDetail
            register={register}
            tip={watch('tip')}
            errors={errors}
            discount={discount}
          />
        </CardPage>
      </form>
      <ErrorProducts />
      <ErrorMessages messages={messages} setMessages={setMessages} />
    </>
  )
}

export default Checkout
