/* eslint-disable max-len */
import React, { useState, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import ProductTitle from '../../../components/Titles/ProductTitle'
import Products from '../../../components/Products'
import ProductAction from '../../../components/Products/ProductAction'
import useCart from '../../../hooks/useCart'
import { PRODUCT_TYPE } from '../../../constant'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'white',
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 67,
    },
  },
  banner: {
    backgroundImage: 'url("/BP_Hot_Pot_Kit.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 240,
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
    [theme.breakpoints.up('lg')]: {
      height: 500,
    },
  },
  contentInner: {
    padding: '46px 20px 38px',
    maxWidth: 500,
    margin: '0 auto',
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1110,
      display: 'flex',
    },
  },
  contentTitle: {
    [theme.breakpoints.down('md')]: {
      '& h3:last-child': {
        display: 'none',
      },
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0 0 520px',
      textAlign: 'right',
      paddingRight: 60,
    },
  },
  content: {
    '& h3': {
      margin: 0,
      fontSize: 24,
      lineHeight: '36px',
      fontFamily: 'FuturaStd-CondensedBold',
      marginBottom: 15,
      [theme.breakpoints.up('md')]: {
        fontSize: 48,
        lineHeight: '60px',
        marginBottom: 18,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 80,
        lineHeight: 1,
        margin: 0,
      },
    },
    '& h4': {
      margin: 0,
      fontSize: 16,
      lineHeight: '20px',
      fontFamily: 'FuturaStd-CondensedBold',
      marginBottom: 5,
      [theme.breakpoints.up('md')]: {
        fontSize: 22,
        lineHeight: '30px',
        marginBottom: 8,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: '36px',
      },
    },
    '& p': {
      margin: 0,
      fontSize: 12,
      lineHeight: '20px',
    },

    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    '& li': {
      flex: '0 0 50%',
      textAlign: 'left',
    },
    [theme.breakpoints.up('md')]: {
      borderBottom: '1px solid #CCCCCC',
      marginBottom: 20,
    },
  },
  includes: {
    textTransform: 'uppercase',
    '& span': {
      textTransform: 'uppercase',
    },
    '& p': {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    '& ul': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('lg')]: {
      flex: 1,
    },
  },
  inner: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1330,
      margin: '0 auto',
    },
  },
  brothPage: {
    [theme.breakpoints.up('lg')]: {
      paddingTop: 70,
    },
  },
  meatProducts: {
    '& button': {
      '& > div:first-child': {
        flex: '0 0 200px',
      },
    },
  },
}))

const PAGE_MAP = {
  FIRST: 'MEAT',
  SECOND: 'BROTH',
}
function HotPot() {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [page, setPage] = useState(PAGE_MAP.FIRST)
  const [picked, setPicked] = useState({})
  const { meats, includes = [] } = useSelector(st => st.common)
  const { area } = useSelector(st => st.area)
  const {
    addToCart, fetchAndCheck, availableProducts, isFetching,
  } = useCart()
  const classes = useStyles()
  const handlePlus = (key, product) => {
    setPicked(st => ({
      ...st,
      [key]: {
        [product.code]: {
          picked: 1,
        },
        data: product,
      },
    }))
  }

  const handlePageChange = p => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    if (p) { setPage(p) } else if (page === PAGE_MAP.FIRST) {
      setPage(PAGE_MAP.SECOND)
    }

    if (page === PAGE_MAP.SECOND && picked.broth) {
      const item = {
        [picked.broth.data.code]: {
          picked: 1,
          data: {
            ...picked.broth.data,
            meat: picked.meat.data,
          },
        },
      }
      addToCart(item, 'combo')
      setPage(PAGE_MAP.FIRST)
      setPicked({})
    }
  }

  useEffect(() => {
    fetchAndCheck()
  }, [fetchAndCheck])

  const meatProducts = useMemo(() => (
    meats.map(meat => {
      const priceVariants = meat.variants.reduce((acc, cur) => ({
        ...acc,
        [cur.areaId]: cur,
      }), {})

      if (area) {
        return {
          ...meat,
          price: priceVariants[area.id]?.price || 0,
          specialPrice: priceVariants[area.id]?.specialPrice || 0,
          priceVariants,
          quantity: 999,
          code: meat.productCode,
          areaActive: true,
        }
      }
      return {
        ...meat,
        price: meat.variants[0]?.price || 0,
        specialPrice: meat.variants[0]?.specialPrice || 0,
        priceVariants,
        quantity: 999,
        code: meat.productCode,
        areaActive: true,
      }
    })
  ),
  [meats, area])

  const products = useMemo(() => (picked?.meat
    ? availableProducts
      .filter(d => (
        d.type === PRODUCT_TYPE.COMBO_BROTH
        && d.comboItems.some(item => item.code === picked.meat.data.code)
        && d.comboItems.some(item => item.subType === PRODUCT_TYPE.BROTH)
        && d.available
      ))
      .map(d => {
        const broth = d.comboItems.find(item => item.subType === PRODUCT_TYPE.BROTH)
        return {
          ...d,
          name: broth.name,
          nameTw: broth.nameTw,
          comboPrice: (d.price - (+picked.meat.data.specialPrice || +picked.meat.data.price)).toFixed(2),
          quantity: Math.min(picked.meat.data.quantity, broth.quantity),
        }
      }) : []),
  [availableProducts, picked])

  const filterIncludes = useMemo(() => {
    const result = []
    const filtered = area
      ? includes.filter(include => include.areaId === area.id && !!include.description)
      : includes.filter(include => include.areaId === 0 && !!include.description)

    filtered.forEach(o => {
      if (!result.some(include => include.description === o.description)) {
        result.push(o)
      }
    })

    return result
  }, [includes, area])
  return (
    <>
      <div className={classes.root}>
        {
          page === PAGE_MAP.FIRST && (
            <>
              <div className={classes.banner} />
              <div className={classes.content}>
                <div className={classes.contentInner}>
                  <div className={classes.contentTitle}>
                    <h3 className="text-uppercase">{t('Hot pot kit')}</h3>
                    <h3 className="text-uppercase">{t('Includes')}</h3>
                  </div>
                  <div className={classes.includes}>
                    <h4 className="text-uppercase">{t('Each kit serves two people')}</h4>
                    <p>
                      {`1x ${t('Your choice of broth')}, 1x ${t('Your choice of meat')}`}
                      {
                        filterIncludes.map((include, i) => (
                          <React.Fragment key={i}>
                            {`, ${include.quantity}x `}
                            <span>{(language === 'tw' && include.descriptionTw) || include.description}</span>
                          </React.Fragment>
                        ))
                      }
                    </p>
                    <ul>
                      <li>{`1x ${t('Your choice of broth')}`}</li>
                      <li>{`1x ${t('Your choice of meat')}`}</li>
                      {
                        filterIncludes.map((include, i) => (
                          <li key={i}>
                            {`${include.quantity}x `}
                            <span>{(language === 'tw' && include.descriptionTw) || include.description}</span>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )
        }
        <div className={clsx(classes.inner, { [classes.brothPage]: page === PAGE_MAP.SECOND })}>
          {
            page === PAGE_MAP.FIRST ? (
              <>
                <ProductTitle
                  title={t('Step 1')}
                  subTitle={t('Choose your combo')}
                />
                <Products
                  className={classes.meatProducts}
                  products={meatProducts}
                  onPlus={ev => handlePlus('meat', ev)}
                  picked={picked?.meat}
                />
              </>
            ) : (
              <>
                <ProductTitle
                  title={t('Step 2')}
                  subTitle={t('Choose your broth')}
                  desc={t('We recommended adding 4 cups of water to reduce concentration of the broth')}
                />
                <Products
                  products={products}
                  onPlus={ev => handlePlus('broth', ev)}
                  picked={picked?.broth}
                />
              </>
            )
          }
        </div>
      </div>
      <ProductAction
        meat={picked.meat}
        broth={picked.broth}
        showBack={page === PAGE_MAP.SECOND}
        addText={page === PAGE_MAP.FIRST ? t('Next step') : t('Add to cart')}
        addToCart={() => handlePageChange()}
        goBack={() => handlePageChange(PAGE_MAP.FIRST)}
      />
      { isFetching && <LinearProgress /> }
    </>
  )
}

export default HotPot
