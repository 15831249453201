/* eslint-disable react/no-danger */
import { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Dialog from '../Dialog'
import Button from '../../Buttons/Button'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      '& .dialog-container': {
        paddingBottom: 30,
      },
    },
  },
  dialog: {
    padding: '0 20px',
    textAlign: 'center',
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      padding: '0 52px',
    },
    '& h2': {
      fontSize: 55,
      color: theme.palette.primary.main,
      fontFamily: 'FuturaStd-CondensedBold',
      margin: 0,
      lineHeight: 1,
      marginBottom: -5,
      [theme.breakpoints.up('sm')]: {
        fontSize: 100,
      },
    },
    '& h5': {
      fontSize: 16,
      color: theme.palette.secondary.main,
      margin: 0,
      paddingBottom: 20,
      fontWeight: 500,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        paddingBottom: 30,
      },
    },
    '& h6': {
      fontSize: 14,
      color: theme.palette.secondary.main,
      margin: 0,
      fontWeight: 500,
      lineHeight: 1,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
      },
    },
    '& p': {
      fontSize: 12,
      lineHeight: '18px',
      textAlign: 'left',
      margin: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
        lineHeight: '20px',
      },
    },

    '& b': {
      fontFamily: 'FuturaStd-Bold',
      fontWeight: 'bold',
    },
  },
  discount: {
    padding: '8px 0',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    maxWidth: 548,
    whiteSpace: 'nowrap',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      padding: '10px 0 15px',
    },
  },
  btns: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      width: 206,
      margin: 10,
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '27px 0 37px',
      '& .MuiButtonBase-root': {
        width: 206,
        margin: 12.5,
      },
    },
  },
  terms: {
    [theme.breakpoints.up('sm')]: {
      padding: '0 24px',
    },
  },
}))

function DiscountDialog() {
  const user = useSelector(st => st.user.data)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const it = useRef()
  const closeRef = useRef()
  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
    closeRef.current = true
  }

  useEffect(() => {
    it.current = setTimeout(() => {
      if (!closeRef.current) {
        setOpen(true)
      }
    }, 20000)
    return () => clearTimeout(it.current)
  }, [])
  return open && !user ? (
    <Dialog
      className={classes.root}
      open
      maxWidth={724}
      fullWidth
      onClose={handleClose}
    >
      <div className={classes.dialog}>
        <h5 dangerouslySetInnerHTML={{ __html: t('Popup - title') }} />
        <div className={classes.discount}>
          <h2>GET 10% OFF</h2>
          <h6 dangerouslySetInnerHTML={{ __html: t('Popup - subtitle') }} />
        </div>

        <div className={classes.btns}>
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            disableElevation
            onClick={handleClose}
            component={Link}
            to="/create-account"
          >
            {t('Popup - confirm')}
          </Button>
          <Button
            className={classes.btn}
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={handleClose}
          >
            {t('Popup - cancel')}
          </Button>
        </div>

        <p className={classes.terms} dangerouslySetInnerHTML={{ __html: t('Popup - terms') }} />
      </div>
    </Dialog>
  ) : null
}
export default DiscountDialog
