import { useEffect, useState, useRef } from 'react'
import throttle from 'lodash/throttle'

const SIZE_MAP = {
  xs: 0,
  sm: 600,
  md: 1024,
  lg: 1200,
  xl: 1920,
}

function findSize(sizeObj) {
  const sizeKeys = Object.keys(sizeObj)
  const sizeIdx = sizeKeys.findIndex(key => sizeObj[key] > window.innerWidth)
  if (sizeIdx > 0) return sizeKeys[sizeIdx - 1]
  return sizeKeys[sizeKeys.length - 1]
}
export default function useIsBreakpoint(sizeMap = SIZE_MAP) {
  const sizeRef = useRef(sizeMap)
  const [inSize, setInSize] = useState(() => findSize(sizeRef.current))

  useEffect(() => {
    const handleResize = throttle(() => {
      setInSize(findSize(sizeRef.current))
    }, 300)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return inSize
}
