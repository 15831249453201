import types from '../actionTypes/productTypes'
import apis from '../../apis'

const fetchProductRequest = () => ({
  type: types.FETCH_REQUEST,
})

const fetchProductFailure = error => ({
  type: types.FETCH_FAILURE,
  error,
})

const fetchProductSuccess = products => ({
  type: types.FETCH_SUCCESS,
  products,
})

export const checkProducts = () => (dispatch, getState) => {
  const { area } = getState().area
  dispatch({
    type: types.CHECK_PRODUCTS,
    areaId: area ? area.id : null,
  })
}

// 取得全部產品
export const fetchProducts = () => async dispatch => {
  dispatch(fetchProductRequest())
  try {
    const res = await apis.product.getProducts()
    dispatch(fetchProductSuccess(res.data.data))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    dispatch(fetchProductFailure(err))
  }
}
