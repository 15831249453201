import React, { useState, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormattedNumber } from 'react-intl'
import Button from '@material-ui/core/Button'
import TrashSvg from '../../../svg/TrashSvg'
import Dialog from '../Dialog'
import TextButton from '../../Buttons/TextButton'
import useCart from '../../../hooks/useCart'
import { PRODUCT_TYPE } from '../../../constant'

const isEmpty = value =>
  typeof value === 'undefined' || value === null || value === false
const isNumeric = value => !isEmpty(value) && !Number.isNaN(Number(value))

const useStyles = makeStyles(theme => ({
  tableWrap: {
    overflowX: 'auto',
  },
  dialog: {
    padding: '0 20px',
    [theme.breakpoints.up('md')]: {
      padding: '0 40px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 60px',
    },
  },
  table: {
    color: theme.palette.secondary.main,
    width: '100%',
    borderCollapse: 'collapse',
    '& td': {
      borderTop: '1px solid #ccc',
      whiteSpace: 'nowrap',
    },
    '& th, & td': {
      padding: 20,
      '&:first-child': {
        paddingLeft: 0,
        minWidth: 200,
      },
    },
    '& th': {
      paddingTop: 0,
      fontWeight: 400,
      textAlign: 'left',
    },
    '& input[type="number"]': {
      width: 50,
      height: 50,
      border: `1px solid ${theme.palette.secondary.main}`,
      fontSize: 16,
      textAlign: 'center',
      fontFamily: 'FuturaStd-book',
      '-moz-appearance': 'textfield',
      color: theme.palette.secondary.main,
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  itemInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  itemImg: {
    width: 65,
    height: 65,
    flex: '0 0 65px',
    marginRight: 15,
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  action: {
    textAlign: 'center',
    marginTop: 40,
  },
  btn: {
    height: 50,
    maxWidth: 368,
  },
  additional: {
    paddingLeft: 20,
  },
  disabled: {
    opacity: 0.5,
  },
}))

function getCartItems(items, type) {
  return items
    .filter(item => item.product.type === type || item.product.subType === type)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.product.code]: {
          picked: cur.quantity,
          data: cur.product,
          ...(cur.product.additional && { additional: cur.product.additional }),
          addPrices: cur.product.addPrices || 0,
        },
      }),
      {},
    )
}

const ProductDisplay = ({ language, item, classes }) => {
  switch (item.product.type) {
  case PRODUCT_TYPE.BUNDLE:
    return (
      <>
        <div>
          {(language === 'tw'
            && item.product.nameTw)
            || item.product.name}
        </div>
        <div className={classes.additional}>
          {item.product.additional?.map(add => (
            <div key={add.code}>
              {add.picked > 1 && (
                <span>
                  {add.picked}
                  x&nbsp;
                </span>
              )}
              <span>
                {(language === 'tw'
            && add.nameTw)
            || add.name}
              </span>
            </div>
          ))}
        </div>
      </>
    )
  case PRODUCT_TYPE.BROTH:
    return (
      <>
        <div>
          {(language === 'tw'
            && item.product.meatNameTw)
            || item.product.meatName}
        </div>
        <div>
          {`w/ ${
            (language === 'tw'
              && item.product.nameTw)
            || item.product.name
          }`}
        </div>
      </>
    )
  default:
    return (language === 'tw'
    && item.product.nameTw)
  || item.product.name
  }
}
function ReorderDialog({ open, onClose, orderItems }) {
  const { i18n } = useTranslation()
  const { language } = i18n
  const classes = useStyles()
  const [currentItems, setCurrentItems] = useState([])
  const { meats } = useSelector(st => st.common)
  const { area } = useSelector(st => st.area)
  const { addToCart, fetchAndCheck, availableProducts } = useCart()

  const meatProducts = useMemo(
    () =>
      meats.map(meat => {
        const priceVariants = meat.variants.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.areaId]: cur,
          }),
          {},
        )

        if (area) {
          return {
            ...meat,
            price: priceVariants[area.id]?.price || 0,
            specialPrice: priceVariants[area.id]?.specialPrice || 0,
            priceVariants,
            quantity: 999,
            code: meat.productCode,
            areaActive: true,
          }
        }
        return {
          ...meat,
          price: meat.variants[0]?.price || 0,
          specialPrice: meat.variants[0]?.specialPrice || 0,
          priceVariants,
          quantity: 999,
          code: meat.productCode,
          areaActive: true,
        }
      }),
    [meats, area],
  )

  useEffect(() => {
    if (!availableProducts || !availableProducts.length) {
      fetchAndCheck()
    }
  }, [availableProducts, fetchAndCheck])

  useEffect(() => {
    if (availableProducts && availableProducts.length && orderItems) {
      setCurrentItems(
        orderItems.map(orderItem => {
          let product = availableProducts.find(
            p => p.code === orderItem.productCode,
          )
          let meat = null
          if (product?.type === PRODUCT_TYPE.COMBO_BROTH) {
            const brothItem = product.comboItems.find(
              combo => combo.subType === PRODUCT_TYPE.BROTH,
            )
            const meatItem = product.comboItems.find(
              combo => combo.subType === PRODUCT_TYPE.MEAT,
            )
            meat = meatProducts.find(m => m.code === meatItem?.code)
            if (brothItem) {
              const productPrice = +product.specialPrice || +product.price
              const meatPrice = +meat.specialPrice || +meat.price
              product = {
                ...product,
                name: brothItem.name,
                nameTw: brothItem.nameTw,
                comboPrice: (productPrice - meatPrice).toFixed(2),
                meatName: meat.name,
                meatNameTw: meat.nameTw,
              }
            }
          } else if (product?.type === PRODUCT_TYPE.BUNDLE) {
            const allBundles = orderItem.comboCodes.split(',')
            const additional = []
            let total = 0
            let addPrices = 0
            allBundles.forEach(bundle => {
              const [code, qty] = bundle.split(':')
              const item = product.comboItems.find(p => p.code === code)
              if (item) {
                additional.push({
                  picked: +qty,
                  id: item.id,
                  code,
                  name: item.name,
                  nameTw: item.nameTw,
                  data: item,
                })

                total += +qty
                addPrices += +item.pivot.addPrice
              }
            })
            product = {
              ...product,
              addPrices,
              additionalTotal: total,
              additional,
              disabled: total !== product.comboSize,
            }
          }
          return {
            ...orderItem,
            product: product
              ? {
                addPrices: 0,
                ...product,
                ...({ meat } || {}),
              }
              : null,
          }
        }),
      )
    }
  }, [orderItems, availableProducts, meatProducts])

  const handleChange = (id, value) => {
    setCurrentItems(st =>
      st.map(s => (s.id === id ? { ...s, quantity: value } : s)))
  }

  const handleAddCart = () => {
    const validItems = currentItems.filter(
      item =>
        !!item.product
        && item.product.areaActive
        && isNumeric(item.quantity)
        && !item.product.disabled,
    )

    const comboItems = getCartItems(validItems, PRODUCT_TYPE.COMBO_BROTH)
    const addonItems = getCartItems(validItems, PRODUCT_TYPE.ADDONS)
    const foodmartItems = getCartItems(validItems, PRODUCT_TYPE.FOOD_MARKET)
    addToCart(comboItems, 'combo')
    addToCart(addonItems, 'addons')
    addToCart(foodmartItems, 'foodmart')

    onClose()
  }

  const total = useMemo(
    () =>
      currentItems
        .filter(
          item => !!item.product,
        )
        .reduce(
          (acc, cur) =>
            acc
            + +cur.quantity
              * ((+cur.product.specialPrice || +cur.product.price) + cur.product.addPrices),
          0,
        ),
    [currentItems],
  )

  return (
    <Dialog
      open={open}
      maxWidth={754}
      fullWidth
      title="Select Products to reorder"
      onClose={onClose}
    >
      <div className={classes.dialog}>
        <div className={classes.tableWrap}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map(item => (
                <tr key={item.id} className={!item.product || !item.product?.areaActive || item.product?.disabled ? classes.disabled : ''}>
                  <td>
                    <div className={classes.itemInfo}>
                      <div
                        className={classes.itemImg}
                        style={{
                          backgroundImage: `url(${item.product?.imageFullurl})`,
                        }}
                      />
                      <div>
                        {!!item.product && (
                          <ProductDisplay
                            classes={classes}
                            language={language}
                            item={item}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    {!!item.product && (
                      <input
                        value={item.quantity}
                        onChange={ev =>
                          handleChange(item.id, ev.target.value)}
                        type="number"
                      />
                    )}
                  </td>
                  <td>
                    {!!item.product && (
                      <>
                        {item.product.type === PRODUCT_TYPE.COMBO_BROTH && item.product.meat ? (
                          <>
                            <div>
                              <FormattedNumber
                                style="currency"
                                value={
                                  +item.product.meat.specialPrice
                                || +item.product.meat.price
                                }
                                currency="USD"
                              />
                            </div>
                            <div>
                              <FormattedNumber
                                style="currency"
                                value={item.product.comboPrice}
                                currency="USD"
                              />
                            </div>
                          </>
                        ) : (
                          <FormattedNumber
                            style="currency"
                            value={
                              (+item.product.specialPrice || +item.product.price)
                              + item.product.addPrices
                            }
                            currency="USD"
                          />
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    <TextButton>
                      <TrashSvg />
                    </TextButton>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td>Total</td>
                <td colSpan="2">
                  <FormattedNumber
                    style="currency"
                    value={total}
                    currency="USD"
                  />
                </td>
              </tr>
            </tfoot>
          </table>
          <div className={classes.action}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.btn}
              fullWidth
              onClick={handleAddCart}
            >
              Add to cart
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ReorderDialog
