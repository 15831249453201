import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { NODE_MODE } from '../constant'
import checkIsServer from '../utils/checkIsServer'

const MIGRATION_DEBUG = true

const migrations = {
  0(state) {
    return ({
      ...state,
    })
  },
  1(state) {
    return ({
      ...state,
    })
  },
}

const persistConfig = {
  key: `main-${NODE_MODE}-1`,
  storage,
  debug: MIGRATION_DEBUG,
  version: process.env.PERSIST_VERSION,
  whitelist: ['user', 'order', 'area'],
  migrate: (state, currentVersion) => {
    const returnState = (() => {
      if (!checkIsServer()) {
        try {
          const { _persist: { version: preVersion } } = state
          if (currentVersion !== preVersion) {
            return migrations[1](state)
          }
          return state
        } catch (error) {
          return state
        }
      }
      return state
    })()
    return Promise.resolve(returnState)
  },
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))
export const persistor = persistStore(store)
