import React, { useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ErrorListSvg from '../../svg/ErrorListSvg'
import SuccessListSvg from '../../svg/SuccessListSvg'
import { SYMBOL_REGEX, LOWER_REGET, UPPER_REGEX } from '../../constant'

const useStyles = makeStyles(theme => ({
  passwordNote: {
    color: theme.palette.error.main,
    fontSize: 16,
    marginTop: 14,
    '& h6': {
      margin: 0,
      marginBottom: 10,
      fontFamily: 'FuturaStd-book',
      lineHeight: 1,
      fontSize: 16,
      fontWeight: 400,
    },
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      '& li': {
        display: 'flex',
      },
      '& svg': {
        marginRight: 6,
        marginTop: 5,
      },
    },
  },
  success: {
    color: theme.palette.success.main,
  },
}))

function PasswordTips({ password, showSuccess }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const passwordError = useMemo(() => {
    if (!password) {
      return {
        lengthCheck: true,
        symbolCheck: true,
        lowerCheck: true,
        upperCheck: true,
      }
    }

    return {
      lengthCheck: password.length < 8,
      symbolCheck: !SYMBOL_REGEX.test(password),
      lowerCheck: !LOWER_REGET.test(password),
      upperCheck: !UPPER_REGEX.test(password),
    }
  }, [password])
  return (
    (passwordError.lengthCheck
      || passwordError.upperCheck
      || passwordError.lowerCheck
      || passwordError.symbolCheck
      || showSuccess)
      ? (
        <div className={classes.passwordNote}>
          <h6>{`${t('Password must include')}:`}</h6>
          <ul>
            {(!!passwordError.lengthCheck || showSuccess) && (
              <li className={clsx({ [classes.success]: !passwordError.lengthCheck })}>
                {passwordError.lengthCheck ? <ErrorListSvg /> : <SuccessListSvg />}
                {t('At least 8 characters long')}
              </li>
            )}
            {(!!passwordError.upperCheck || showSuccess) && (
              <li className={clsx({ [classes.success]: !passwordError.upperCheck })}>
                {passwordError.upperCheck ? <ErrorListSvg /> : <SuccessListSvg />}
                {t('One uppercase character')}
              </li>
            )}
            {(!!passwordError.lowerCheck || showSuccess) && (
              <li className={clsx({ [classes.success]: !passwordError.lowerCheck })}>
                {passwordError.lowerCheck ? <ErrorListSvg /> : <SuccessListSvg />}
                {t('One lowercase character')}
              </li>
            )}
            {(!!passwordError.symbolCheck || showSuccess) && (
              <li className={clsx({ [classes.success]: !passwordError.symbolCheck })}>

                {passwordError.symbolCheck ? <ErrorListSvg /> : <SuccessListSvg />}
                {t('One number, symbol, no whitespace')}
              </li>
            )}
          </ul>
        </div>
      ) : null
  )
}

export default PasswordTips
