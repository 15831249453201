import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'
import YouTube from 'react-youtube'

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 36,
  },
  closeBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,.5)',
  },
  container: {
    width: '100%',
    position: 'relative',
    maxWidth: 1000,
  },
  video: {
    paddingBottom: '56.25%',
    paddingTop: 30,
    height: 0,
    overflow: 'hidden',
    width: '100%',
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
    },
  },
}))

function Youtube({ show, videoId, onClose }) {
  const classes = useStyles()
  const ref = useRef()

  const handleClose = () => {
    ref.current.internalPlayer.stopVideo()
    if (onClose) onClose()
  }
  return (
    <Fade in={show}>
      <div className={classes.root}>
        <div
          className={classes.closeBg}
          onClick={handleClose}
          onKeyDown={handleClose}
          role="button"
          tabIndex="0"
          aria-label="close video"
        />
        <div className={classes.container}>
          <div className={classes.video}>
            <YouTube ref={ref} videoId={videoId} />
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default Youtube
