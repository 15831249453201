import React from 'react'

function FacebookSvg(props) {
  return (
    <svg id="Facebook" xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" {...props}>
      <g id="Facebook_2" data-name="Facebook 2">
        <path id="Rectangle" d="M32.5,0h0A32.5,32.5,0,0,1,65,32.5h0A32.5,32.5,0,0,1,32.5,65h0A32.5,32.5,0,0,1,0,32.5H0A32.5,32.5,0,0,1,32.5,0Z" fill="#4267b2" />
        <path id="Vector" d="M29.423,51.333V31.567h6.634l.994-7.7H29.423V18.944c0-2.229.619-3.75,3.817-3.75H37.32V8.3A54.67,54.67,0,0,0,31.376,8c-5.882,0-9.908,3.59-9.908,10.182v5.682H14.816v7.7h6.652V51.333Z" transform="translate(5.247 2.833)" fill="#fff" />
      </g>
    </svg>
  )
}

export default FacebookSvg
