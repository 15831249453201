import React from 'react'

function EditSvg(props) {
  return (
    <svg id="Edit" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <path id="Edit-2" d="M17.758,1.928a2.813,2.813,0,0,1,2.25,4.5L18.883,7.553,14.946,3.616l1.125-1.125a2.8,2.8,0,0,1,1.688-.562ZM3.7,14.866,2.571,19.928,7.633,18.8,18.039,8.4,14.1,4.459ZM16.071,8.758,7.825,17.139,5.312,14.527l8.34-8.1Z" transform="translate(-2.571 -1.928)" fill="currentColor" />
    </svg>

  )
}

export default EditSvg
